import React from 'react'
import './style.css'
import Logo from './assets/logo.png'
import { Link } from 'react-router-dom'



function ResetSuccessComponent({setReset}) {

  return (
    <div className='loginpage-box'>
    <div className="loginbox-inside">
      <img src={Logo} alt="" height={'20px'}/>
    </div>
    <div className="loginbox-inside">
    
      <div className="login-main">
      <h3 style={{textAlign:'center'}}>Password Reset Successfully !</h3>
        <p>You can use your new password to login to your account </p>
        <button className='loginContainer-btn' onClick={()=>{setReset(false)}}>Continue</button>
        <div className="optionslogin-main">
             <Link to={'/'} className='login-link'> Go back</Link>
            </div>
      </div>
    </div>
    <div className="loginbox-inside">

    </div>
    <div className="loginbox-inside"></div>
  </div>
  )
}

export default ResetSuccessComponent