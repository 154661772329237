import React from 'react';
import "./DeleteWarnPopup.css";
import warn from "./warn.svg";
import logout from "./logout.svg"
import deleteuser from "./deleteuser.svg"
import downloadpop from "./downloadpop.svg"
import { baseUrl } from '../../Services/apiCall';
import { DownloadRoute } from '../../Services/apiServices';


const DeleteWarnPopup = ({setWarning,deleteSubject,type,logoutSubmit,deleteStudentSubmit,notifyWarnsubmit,dwnldFilename}) => {


  const deleteFun =()=>{
    deleteSubject();
    setWarning(false)
  }
  return (
    <div className='delete-warn-container'>
      
      <div className="delete-warn-box">

        <div className='warn-top-btm'>

        <div className='delete-warn-top'>

        {type==="delete"?
          <img className='warn-svg' 
          // src={baseUrl + "/student/svg_image?Image=warn.svg"}
          src={warn}
          alt="" />
          :
          type==="logout"?
          <img className='warn-svg' 
          // src={baseUrl + "/student/svg_image?Image=logout.svg"}
          src={logout}
          alt="" />
          :
          type==="user"?
          <img className='warn-svg' 
          src={baseUrl + "/student/svg_image?Image=deleteuser.svg"}
          // src={deleteuser}
          alt="" />
          :
          type==="download"?
          <img className='warn-svg' 
          // src={baseUrl + "/student/svg_image?Image=downloadpop.svg"}
          src={downloadpop}
          alt="" />
         
          :""
  }

  
          {type==="delete"?
          <div>Are you sure want to delete?</div>
          :
          type==="logout"?
          <div>Are you sure want to logout?</div>
          :
          type==="user"?
          <div>Are you sure want to delete?</div>
          :
          type==="download"?
          <div>Your file is ready to download</div>
          :
          type==="notify_warn"?
          <div>Do you want to notify students in this list? </div>
          :""
  }
        </div>

        <div className='warn-btns-container'>
          {type==="delete"?        
            <div className='warnpop-btn1' onClick={deleteFun}>Yes</div>
            :
            type==="logout"?
            <div className='warnpop-btn1' onClick={logoutSubmit}>Yes</div>
            :
            type==="user"?
            <div className='warnpop-btn1' onClick={deleteStudentSubmit} >Yes</div>
            :
            type==="notify_warn"?
            <div className='warnpop-btn1' onClick={notifyWarnsubmit} >Yes</div>
            :
            type==="download"?
            // <div className='warnpop-btn1'  >Download</div>
            <a href={baseUrl+"/academy/download_data?FileName="+dwnldFilename} className='warnpop-btn1'  rel="noreferrer" target="_blank">Download</a>
            :""
}
            <div className='warnpop-btn2' onClick={()=>setWarning(false)}>
            {type==="download"? "Cancel":"No"}
            </div>
        </div>

        </div>

      </div>
    </div>
  )
}

export default DeleteWarnPopup
