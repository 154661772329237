import React from "react";
import "./StudListTable.css";
import { useNavigate } from "react-router-dom";
import mark from "./attendance.png";
import { attendanceData } from "../../Arraydata";
import { Skeleton } from "@mui/material";

const StudListTable = ({
  dataList,
  setIndividualMsg,
  msgPopupDataPass,
  setMsgPopupDataPass,
  setManualAttendancePopup,
  searchedList,
  loading
}) => {
  const toStudProfile = useNavigate();

  return (
    <div className="StudListTable-container">
      <div className="StudListTable-wrapper">
        <div className="StudListTable-head">Sl.no</div>
        <div className="StudListTable-head">Admn.Id</div>
        <div className="StudListTable-head">Name</div>
        <div className="StudListTable-head">Class</div>
        {/* <div className='StudListTable-head'  >Division</div> */}
        <div className="StudListTable-head">Phone </div>
        <div className="StudListTable-head">Message</div>
        <div className="StudListTable-head">Attendance</div>
      </div>
      {loading ? (
        <>
          {attendanceData?.map((data, index) => {
            return (
              <div
                className="AttendanceTable-content-wrapper"
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                }}
              >
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />{" "}
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="StudListTable-scroll-container">
          {searchedList?.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                }}
                className="StudListTable-content-wrapper"
              >
                <div className="StudListTable-content">{index + 1}</div>
                <div className="StudListTable-content">
                  {data?.AdmNo.replace(localStorage.getItem("adminKey"), "")}
                </div>
                <div
                  className="StudListTable-content hover-underline"
                  onClick={() => toStudProfile(`/profile/${data?.AdmNo}`)}
                >
                  {data?.Name}
                </div>
                <div className="StudListTable-content">
                  {data?.Class} {data.Division ? ` - ${data?.Division}` : ""}
                </div>
                {/* <div className='StudListTable-content'>{data?.Division}</div> */}
                <div className="StudListTable-content">{data?.ParentPhone}</div>
                <div
                  className="StudListTable-content"
                  style={{ paddingLeft: "25px" }}
                >
                  <svg
                    onClick={() => {
                      setMsgPopupDataPass({
                        ...msgPopupDataPass,
                        admno: data?.AdmNo,
                        name: data?.Name,
                      });
                      setIndividualMsg(true);
                    }}
                    className="msg-svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M8 8H16M8 12H13M3 10C3 4.64706 5.11765 3 12 3C18.8824 3 21 4.64706 21 10C21 15.3529 18.8824 17 12 17C11.6592 17 11.3301 16.996 11.0124 16.9876L7 21V16.4939C4.0328 15.6692 3 13.7383 3 10Z"
                        stroke="#1C2135"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>

                <div
                  className="StudListTable-content"
                  style={{ paddingLeft: "25px" }}
                >
                  <img
                    height="24"
                    width="24"
                    src={mark}
                    onClick={() => {
                      setMsgPopupDataPass({
                        ...msgPopupDataPass,
                        admno: data?.AdmNo,
                        name: data?.Name,
                      });
                      setManualAttendancePopup(true);
                    }}
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StudListTable;
