import React, { useState } from 'react'
import "./AddTeacherForm.css"
import ImportDocumentBtn from '../ImportDocumentBtn/ImportDocumentBtn';
import InputComponent from '../InputComponent/InputComponent';
import SelectBox from '../SelectBox/SelectBox';
import SaveCancelBtn from '../SaveBtn/SaveBtn';
import UploadImgBtn from '../UploadImgBtn/UploadImgBtn';
import apiCall from '../../Services/apiCall';
import { AddTeacherRoute, SubjectList, TeacherImgUploadRoute } from '../../Services/apiServices';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadImgBox from '../UploadImgBox/UploadImgBox';
import ImgUploadPopup from '../ImgUploadPopup/ImgUploadPopup';

const AddTeacherForm = ({setTeacherPopup,setTeacherAddSuccess}) => {
  const navigate = useNavigate()
  const [teacherImgUploadPopup, setTeacherImgUploadPopup] = useState(false)

  const [imgTeacher, setImgTeacher] = useState(null)

  const [teacherAddData, setTeacherAddData] = useState({
    name: "",
    subject:"",
    email:"",
    address:"",
    phone:"",
    image:""
  })
  const [subjectList, setSubjectList] = useState([])

  const SubjectsData=async()=>{
    const route = SubjectList();
    const resp = await apiCall(route,{});

    if(resp.Status==="success"){
      setSubjectList(resp.Data);
    }
  }
  const addteacherImgSubmit=async()=>{
    const route = TeacherImgUploadRoute();
    const resp = await apiCall(route,{
      Image:imgTeacher?imgTeacher:""
    },true);
    if(resp.Status==="success"){    
      setTeacherAddData({...teacherAddData,image:resp.Image});
      setTeacherImgUploadPopup(false)
    }
    else if(resp.status==='token_is_invalid'){
         navigate('/login')
        }
  }

const addTeacherSubmit=async(e)=>{
  e.preventDefault();
  const route = AddTeacherRoute();
  const resp = await apiCall(route,{
    Name : teacherAddData.name?teacherAddData.name:"",
    Image: teacherAddData.image?teacherAddData.image:"",
    PhoneNo : teacherAddData.phone?"+91"+teacherAddData.phone:"",
    Address : teacherAddData.address?teacherAddData.address:"",
    Email : teacherAddData.email?teacherAddData.email:"",
    SubjectId :teacherAddData.subject?teacherAddData.subject:"" 
  });
  if(resp.Status==="success"){
    setTeacherAddSuccess(true);
    setTeacherPopup(false)
  }
  else if(resp.status==='token_is_invalid'){
       navigate('/login')
      }
}


  useEffect(() => {
    SubjectsData();
  }, [])
  

  return (
    <div className="AddTeacherForm-wrapper">

{teacherImgUploadPopup? (
        <div className="uploadpopup-div">
         <ImgUploadPopup 
         setImgToBackend={setImgTeacher} 
         setChangeImgPop={setTeacherImgUploadPopup}
         updateImgSubmit={addteacherImgSubmit}
         />
        </div>
      ) : (
        ""
      )}

    <form className='AddTeacherForm-container' onSubmit={addTeacherSubmit}>

      <div className="AddTeacherForm-title">ADD TEACHER</div>

      <div className="AddTeacherForm-btm-head">
        {/* <div><ImportDocumentBtn/></div> */}
      </div>

      <div className="AddTeacherForm-btm-container">

    
      
      <InputComponent 
       type="text"
       isRequired={true}
       placeHolder="Teacher name *"
       onChange={(e)=>setTeacherAddData({...teacherAddData,name:e.target.value})}
      />



  <select
  className='AddTeacherForm-select'
  onChange={(e)=>setTeacherAddData({...teacherAddData,subject:e.target.value})}
   name="" id="" required>
    <option className='AddTeacherForm-select-deafult' value="">Subject</option>
    {subjectList.map((data,index)=>{
      return(
      <option key={index} value={data.id}>{data.name}</option>
      )
    })}
    
   </select>

<InputComponent 
       type="email"
       isRequired={true}
       placeHolder="Email"
       onChange={(e)=>setTeacherAddData({...teacherAddData,email:e.target.value})}
      />






<InputComponent 
       type="text"
       isRequired={false}
       placeHolder="Address"
       onChange={(e)=>setTeacherAddData({...teacherAddData,address:e.target.value})}
      />




<InputComponent 
       type="tel"
       pattern="[6789]\d{9}"
       isRequired={true}
       placeHolder="Phone number"
       onChange={(e)=>setTeacherAddData({...teacherAddData,phone:e.target.value})}
      />


<div>
  {/* <UploadImgBtn
   imgStud={imgTeacher}
    setImgStud={setImgTeacher}
   image={image}
    setImageStudent={setImageTeacher}
  /> */}
 <div onClick={()=>setTeacherImgUploadPopup(true)}>
 {teacherAddData.image?
 <UploadImgBox type="added"/>
 :
   <UploadImgBox type="upload"/>
  }
   </div>
</div>

</div>

<div className="AddTeacherForm-btns-container">
  <div onClick={()=>setTeacherPopup(false)}><SaveCancelBtn type="cancel" section="addstudent"/></div>
  <button className='btn-outline' type='submit'><SaveCancelBtn type="save" text="Save" section="addstudent"/></button>
</div>


</form>




    </div>
  )
}

export default AddTeacherForm