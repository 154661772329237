import React from 'react'
import "./CurvyBox.css"

function CurvyBox({ title, content, order }) {
    return (
        <div className={
            order === "1" ?
                'CurvyBox-container curvy1'
                :
                order === "2" ?
                    'CurvyBox-container curvy2'
                    :
                    order === "3" ?
                        'CurvyBox-container curvy3'
                        :
                        order === "4" ?
                            'CurvyBox-container curvy4'
                            : ""}
        >
            <div className='CurvyBox-wrap'>
                <p className='curvy-head'>{title}</p>
                <p className='curvy-desc'>{content}</p>
            </div>
        </div>
    )
}

export default CurvyBox
