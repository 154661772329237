import React from "react";
import "./SuccessFailPopup.css";
import verified from "./assets/verified.svg";
import failed from "./assets/failed.svg";

const SuccessFailPopup = ({ type, text, setclosePopup }) => {
  return (
    <div className="SuccessFail-wrapper">
      <div className="SuccessFail-container">
        {type === "success" ? (
          <img src={verified} alt="" />
        ) : type === "fail" ? (
          <img width="38" height="38" src={failed} alt="" />
        ) : (
          ""
        )}
        <p>{text}</p>
        <div className="SuccessFail-btn" onClick={() => setclosePopup(false)}>
          ok
        </div>
      </div>
    </div>
  );
};

export default SuccessFailPopup;
