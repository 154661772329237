import React, { useState } from "react";
import "./ProfileAttendanceHistory.css";
import MonthYearBtn from "../MonthYearBtn/MonthYearBtn";
import SmallDownloadBtn from "../SmallDownloadBtn/SmallDownloadBtn";
import download from "./download.svg";
import SmallBtn from "../SmallBtn/SmallBtn";
import IconButton from "../IconButton/IconButton";
import {
  IndividualStudentAttendance,
  studwebAttendanceHistoryRoute,
} from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DateObject } from "react-multi-date-picker";

const ProfileAttendanceHistory = ({
  userid,
  markAttendPopup,
  setMarkAttendPopup,
  profileAttendMonth,
  setProfileAttendMonth,
  profileAttendMonthfilter,
  setProfileAttendMonthfilter,
  AttendanceHistoryDownload,
  type,
}) => {
  const navigate = useNavigate();

  const [studprofileAttendance, setstudprofileAttendance] = useState([]);

  const AttendanceHistory = async () => {
    const route = IndividualStudentAttendance();
    const response = await apiCall(
      route,
      {
        AdmissionNo: userid ? userid : "",
        Month: profileAttendMonthfilter ? profileAttendMonthfilter : "",
      },
      false,
      "PUT"
    );
    if (response.Status === "success") {
      setstudprofileAttendance(response.Data);
    } else if (response.Status === "no_data") {
      setstudprofileAttendance([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const StudWebAttendanceHistory = async () => {
    const route = studwebAttendanceHistoryRoute();
    const response = await apiCall(route, {
      Month: profileAttendMonthfilter ? profileAttendMonthfilter : "",
    });
    if (response.Status === "success") {
      setstudprofileAttendance(response.Data);
    } else if (response.Status === "no_data") {
      setstudprofileAttendance([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  };

  useEffect(() => {
    if (type === "studentWeb") {
      StudWebAttendanceHistory();
    } else {
      AttendanceHistory();
    }
  }, [profileAttendMonthfilter, markAttendPopup]);

  return (
    <div className="ProfileAttendance-container">
      <div>
        <div className="ProfileAttendance-markatttend">
          {type !== "studentWeb" ? (
            <div onClick={() => setMarkAttendPopup(true)}>Mark Attendance</div>
          ) : (
            ""
          )}
        </div>
        <div className="ProfileAttendance-top">
          <div className="ProfileAttendance-left">Attendance</div>
          <div className="ProfileAttendance-right">
            <div onClick={AttendanceHistoryDownload}>
              <SmallBtn icon={download} />
            </div>
            <div>
              <MonthYearBtn
                date={profileAttendMonth}
                setDate={setProfileAttendMonth}
                setFilterDateatten={setProfileAttendMonthfilter}
                filterDateatten={profileAttendMonthfilter}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="ProfileAttendance-bottom">
        <div className="ProfileAttendance-wrapper">
          <div className="ProfileAttendance-head">Date</div>
          <div className="ProfileAttendance-head">Check-in</div>
          <div className="ProfileAttendance-head">Check-out</div>
        </div>

        <div className="ProfileAttendance-scroll-container">
          {studprofileAttendance?.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                }}
                className="ProfileAttendance-content-wrapper"
              >
                <div className="ProfileAttendance-content">{data?.Date}</div>
                <div className="ProfileAttendance-content">{data?.Checkin}</div>
                <div className="ProfileAttendance-content">
                  {data?.Checkout}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProfileAttendanceHistory;
