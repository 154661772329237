import { action, createStore } from "easy-peasy";



export const store = createStore({
    classData :[],
    addClassData :action((state,payload)=>{
        console.log("sadsad",payload);
        state.classData = payload
    })
})