import React, { useState } from "react";
import "./IndividualMsgPopup.css";
import InputComponent from "../InputComponent/InputComponent";
import apiCall from "../../Services/apiCall";
import { individualNotifyRoute } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";

const IndividualMsgPopup = ({ setIndividualMsg,msgPopupDataPass,setMsgSentSuccessPopup }) => {

  const navigate = useNavigate();

  const [individualMsgData, setIndividualMsgData] = useState({
    title:"",
    content:""
  })

  const individualMsgSubmit=async(e)=>{
    e.preventDefault();
    const route = individualNotifyRoute();
    const resp = await apiCall(route,{
      Title: individualMsgData.title?individualMsgData.title:"",
      Text:  individualMsgData.content?individualMsgData.content:"",
      AdmNo: msgPopupDataPass.admno?msgPopupDataPass.admno:""
    });
    if(resp.Status==="success"){
      setIndividualMsg(false)
      setMsgSentSuccessPopup(true)
    }
 
     else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }
  }

  return (
    <div className="individualMsg-wrapper">
      <form className="individualMsg-container" onSubmit={individualMsgSubmit}>
        <div className="individualMsg-head">Send to {msgPopupDataPass.name}</div>

        <div className="individualMsg-middle">
          <InputComponent type="text"
           isRequired={true} placeHolder="Title" 
           onChange={(e)=>setIndividualMsgData({...individualMsgData,title:e.target.value})}
           />

          <textarea
            required
            className="individualMsg-content"
            placeholder="Enter the Message...."
            name=""
            id=""
            cols="5"
            rows="8"
            onChange={(e)=>setIndividualMsgData({...individualMsgData,content:e.target.value})}
          ></textarea>
        </div>

        <div className="individualMsg-btns-container">
          <div
            className="individualMsg-cancelBtn"
            onClick={() => setIndividualMsg(false)}
          >
            Cancel
          </div>

          <button type="submit" className="individualMsg-chooseBtn btn-outline">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default IndividualMsgPopup;
