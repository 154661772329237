import React, { useEffect, useState } from 'react'
import dp from "./assets/dp.jpg"
import arrow from "./assets/arrow.svg"
import "./TabProfileNavbar.css"
import { Link, useNavigate } from 'react-router-dom'
import logout from "./assets/logout.svg"
import { AcademyView } from '../../Services/apiServices'
import apiCall from '../../Services/apiCall'

const TabProfileNavbar = ({section}) => {
  const navigate = useNavigate();
  const [tabNavAcademyData, setTabNavAcademyData] = useState([])

  const academyTabNavbarSubmit=async()=>{
    const route = AcademyView();
    const resp = await apiCall(route,{});
    if(resp.Status==="success"){
      setTabNavAcademyData(resp.Data)
    }
    else if(resp.Status==="no_data"){
      setTabNavAcademyData([])
    }
    else if(resp.Status==="token_is_invalid"){
      navigate("/login");
    }
  }

  useEffect(() => {
    academyTabNavbarSubmit();
  }, [])
  

    const [navProfile,setNavProfile] = useState(false)
  return (
    <div className={navProfile?"tab-navprofile nav-height mobile-nav":"tab-navprofile"}>
            <div className="tab-navbar-profile"  onClick={()=>setNavProfile(!navProfile)}>
           
            <div className="tab-navbar-content">

              <div className='tabNavbar-left'>
              <img className="tab-navbar-dp" 
            //   src={adminBaseUrl +"/Academy_View_Image?Academy_image="+headerAdminDp}
              src={dp}
              alt="no" />
              <div>
                <div className={navProfile?"tab-admin-name":"tab-admin-name inactive-display"}>
                  {tabNavAcademyData.name}
                </div>
                <div className={navProfile?"tab-navbar-email":"tab-navbar-email inactive-display"}>
                {tabNavAcademyData.email}
                  </div>         
              </div>
              </div>

                    <img className="tab-nav-arrow" src={arrow} alt="" />
                    </div>

            </div>
{navProfile?
<div className="tab-nav-drop">
  <div className="nav-drop-top">
  <Link className='menu-link' to="/dashboard"><div className={section==="dashboard"?"selected-menu":""}>Home</div></Link>
  <Link className='menu-link' to="/studentattendance"><div className={section==="studentattendance"?"selected-menu":""}>Attendance</div></Link>
  <Link className='menu-link' to="/academics"><div className={section==="academics"?"selected-menu":""}>Academics</div></Link>
  <Link className='menu-link' to="/profiles"><div className={section==="profiles"?"selected-menu":""}>Profiles</div></Link>
  <Link className='menu-link' to="/payments"><div className={section==="payments"?"selected-menu":""}>Payments</div></Link>
  <Link className='menu-link' to="/adminprofile"><div className={section==="adminprofile"?"selected-menu":""}>My Profile</div></Link>

  </div>
  {/* <div className="nav-drop-btm">
    <img className='tabNav-logout' src={logout} alt="" />
<p>Logout</p>
  </div> */}

</div>:""}

            </div>  
  )
}

export default TabProfileNavbar