async function apiCall(url, data, isFile = false, method = "POST") {
  
  const formData = new FormData();
  if (isFile && data) {
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
      // console.log(formData);
    });
  }
  const headers = new Headers();

  if (!isFile) headers.append("Content-Type", "application/json");

  if (localStorage.getItem("token"))
    headers.append("x-access-tokens", localStorage.getItem("token"));

  const response = await fetch(url, {

    method,
    headers,
    body: isFile ? formData : data ? JSON.stringify(data) : undefined,
    
  });
  return response.json();
}
export default apiCall;

export let baseUrl = ''


// http://127.0.0.1:5000


// http://192.168.1.45:5008/academy


// "proxy": "http://localhost:5000",
// "homepage": ".",