import React from "react";
import "./UploadImgBox.css";
import imglogo from "./assets/upload.svg";
import added from "./assets/added.svg";

const UploadImgBox = ({ type }) => {
  return (
    <div className="UploadImgBox-container">
      {type === "upload" ? (
        <>
          <p>Upload Image</p>
          <img src={imglogo} alt="" />
        </>
      ) : type === "added" ? (
        <>
          <p>Image Added</p>
          <img width="20" height="20" src={added} alt="" />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadImgBox;
