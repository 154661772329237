import React, { useEffect, useState } from "react";
import "./FilterDropdown.css";
import dwnArrow from "./assets/dwnArrow.svg";
import apiCall from "../../Services/apiCall";
import { CourseList, ViewDivision } from "../../Services/apiServices";

const FilterDropdown = ({cls,setCls,div,setDiv}) => {
  const [classDropdwn, setClassDropdwn] = useState(false);
  const [divDropdwn, setDivDropdwn] = useState(false);
  const [classData, setclassData] = useState([]);
  const [classes,setClasses] = useState("")
const [divData,setDivData] = useState([])
  const selectedClass = classData?.filter((data) => data.class === cls);

  const courseListSubmit = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if(resp.Status==="success"){
    setclassData(resp?.Course);
    }
  };
  const handleDivision =async(data)=>{
    const route = ViewDivision()
    const resp = await apiCall(route,{Class:data?data:""})
    if(resp.Status ==="success"){
      setDivData(resp?.Division)
    }
  }

  useEffect(() => {
    courseListSubmit();
  }, []);
  
  // useEffect(() => {
  //   setDiv("Division");
  // }, [cls]);

  return (
    <div className="FilterDropdown-container">
      <div className="FilterDropdown-class">
        <div
          className="FilterDropdown-clsDiv"
          onClick={() => {
            setClassDropdwn(!classDropdwn);
            setDivDropdwn(false);
          }}
        >
          <p> {cls} </p>
          <img
            className={
              classDropdwn ? "FilterDropdown-active" : "FilterDropdown-inactive"
            }
            src={dwnArrow}
            alt=""
          />
        </div>
        {classDropdwn ? (
          <div className="FilterDropdown-list">
            {classData?.map((data, index) => {
              return (
                <p
                  key={index}
                  onClick={() => {
                    setCls(data.class);
                    setClassDropdwn(false);
                    handleDivision(data.class)
                    setClasses(data.class)
                  }}
                >
                  {data.class}
                </p>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="FilterDropdown-division">
        <div
          className="FilterDropdown-clsDiv"
          onClick={() => {
            setDivDropdwn(!divDropdwn);
            setClassDropdwn(false);
          }}
        >
          <p> {div} </p>
          <img
            className={
              divDropdwn ? "FilterDropdown-active" : "FilterDropdown-inactive"
            }
            src={dwnArrow}
            alt=""
          />
        </div>
        {divDropdwn ? (
          <div className="FilterDropdown-list">
            {divData?.map((divdata, index) => {
              return (
                <p
                  key={index}
                  onClick={() => {
                    setDiv(divdata?.division);
                    setDivDropdwn(false);
                  }}
                >
                  {divdata?.division}
                </p>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className="FilterDropdown-clear"
        onClick={() => {
          setDiv("Division");
          setCls("Class");
        }}
      >
        clear
      </div>
    </div>
  );
};

export default FilterDropdown;
