import React from "react";
import "../TimetableBox/TimetableBox.css";
import { useState } from "react";
import { StudWebTimetable, studwebTimeslots, studwebViewDays } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ParentTimetable = () => {
  const navigate = useNavigate();
  const [daysdata, setdaysdata] = useState([]);
  // const [timedata, settimedata] = useState(slotData);
  const [viewData, setViewData] = useState([]);
  const [slotData, setSlotData] = useState([])

  const viewdaysSubmit = async () => {
    const route = studwebViewDays();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setdaysdata(resp);
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/studentLogin')
     }
  };

  const ViewTimeTable = async () => {
    const route = StudWebTimetable();
    const response = await apiCall(route, {});
    if (response.Status === "success") {
      setViewData(response?.Data);
    }
    else if(response.Status==='token_is_invalid'){
      navigate('/studentLogin')
     }
  };

  useEffect(() => {
    viewdaysSubmit();
    ViewTimeTable();
  }, []);

  const ViewTimeSlotApi = async () => {
    const route = studwebTimeslots();
    const response = await apiCall(route, {});
    if (response.Status === "success") {
      setSlotData(response?.Data);
    } 
    else if(response.Status==='token_is_invalid'){
      navigate('/studentLogin')
     }
  };

  useEffect(() => {
    ViewTimeSlotApi();
  }, [])
  

  return (
    <div className="TimetableBox-container">
      <div className="TimetableBox-timeColumn">
        <div>
          {[null, ...slotData]?.map((times, index) => {
            return (
              <div style={{ display: "flex" }} key={index}>
                <div key={index} className="TimetableBox-content time-div">
                  {times ? `${times?.StartTime} - ${times?.EndTime}` : "TIME"}
                </div>

                <div style={{ display: "flex" }}>
                  {daysdata.Data?.map((daydata, dayindex) => {
                    return (
                      <div
                        className="TimetableBox-content"
                        key={dayindex}
                       
                      >
                        {!times ? (
                          <div className="day-div">{daydata.day}</div>
                        ) : (
                          <PeriodBox
                            data={
                              viewData
                                .find((d) => d.period_id === times.id)
                                ?.timetable_data.find(
                                  (s) => s.day === daydata.day
                                )?.subject
                            }
                            teacher={
                              viewData
                                .find((d) => d.period_id === times.id)
                                ?.timetable_data.find(
                                  (s) => s.day === daydata.day
                                )?.teacher
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

       
      </div>
    </div>
  );
};

const PeriodBox = ({ data, teacher }) => {
  if (data && teacher)
    return (
      <div className="period-container">
        <div className="period-sub">{data}</div>
        <div className="period-teacher">{teacher}</div>
      </div>
    );
  return <label className="doubleclick-parent-lbl"></label>;
};

export default ParentTimetable;
