import React from 'react'
import "./SaveBtn.css"

const SaveCancelBtn = ({type,section,text}) => {
  return (
    <>
    
    {type==="save"?
    <div className="SaveBtn-container">
      {section==="profile"?"Save Changes":<span style={{fontSize:"12px",fontWeight:"500"}}>{text}</span>}            
  </div>
  :
  type==="cancel"?
  <div className={section==="profile"?'cancelBtn-container':"cancelBtn-notprofile"}>
        Cancel      
  </div>
  :
  type==="back"?
  <div className="cancelBtn-notprofile">
        Back      
  </div>
  :""}
  </>
  )
}

export default SaveCancelBtn