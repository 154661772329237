import React from "react";
import AttendanceTable from "../AttendanceTable/AttendanceTable";
import "./AttendanceComponent.css";
import attend from "./assets/attend.svg";
import filter from "./assets/filter.svg";
import dwnld from "./assets/dwnld.svg";
import IconButton from "../IconButton/IconButton";
import sort from "./assets/sort.svg";
import { useState } from "react";
import { useEffect } from "react";
import { Bars} from "react-loader-spinner";

const AttendanceComponent = ({
  attendDownload,
  data,
  setAttendFiltPopup,
  showFiltered,
  absentListView,
  Todayattendance,
  setAbsentDwnldPopup,
  setAbsentFiltPopup,
  sortType,
  setSortType,
  loading,
  attendanceresponse
}) => {
  const [sortDropdown, setSortDropdown] = useState(false);

  useEffect(() => {
    setSortDropdown();
  }, [sortType]);

  return (
    <div className="attendComponent-container">
      <div className="attendComponent-top">
        <div className="attendComponent-topLeft">
          <img src={attend} alt="" />
          <p>ATTENDANCE REPORT</p>
          <div className="attendComponent-filterShow">{showFiltered}</div>
        </div>
        {loading && (
      <Bars
      height="40"
      width="40"
      color="#4fa94d"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
        )}

        <div className="attendComponent-topRight">
          <div onClick={() => setSortDropdown(!sortDropdown)}>
            <IconButton label={sortType ? sortType : "Sort By"} img={sort} />
          </div>
          {sortDropdown ? (
            <div className="attendComponent-sortDropdown">
              <div
                onClick={() => {
                  setSortType("Present");
                  Todayattendance();
                }}
              >
                Present
              </div>
              <div
                onClick={() => {
                  setSortType("Absent");
                  absentListView();
                }}
              >
                Absent
              </div>
            </div>
          ) : (
            ""
          )}

          {sortType === "Present" || sortType === "" ? (
            <>
              <div onClick={attendDownload}>
                <IconButton label="Download" img={dwnld} />
              </div>
              <div onClick={() => setAttendFiltPopup(true)}>
                <IconButton label="Filter" img={filter} />
              </div>
            </>
          ) : sortType === "Absent" ? (
            <>
              <div onClick={() => setAbsentDwnldPopup(true)}>
                <IconButton label="Download" img={dwnld} />
              </div>
              <div onClick={() => setAbsentFiltPopup(true)}>
                <IconButton label="Filter" img={filter} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="attendComponent-middle">
        <AttendanceTable data={data} loading={loading} attendanceresponse={attendanceresponse}/>
      </div>
    </div>
  );
};

export default AttendanceComponent;
