// import React from "react";
// import apiCall from "../../Services/apiCall";
// import { StudWebTimetable } from "../../Services/apiServices";
// import { useEffect } from "react";

// function StudentTimetable() {
//   const StudTimeTableApi = async () => {
//     const route = StudWebTimetable();
//     const response = await apiCall(route, {});
//     console.log(response);
//   };

//   useEffect(() => {
//     StudTimeTableApi();
//   }, []);

//   return <div></div>;
// }

// export default StudentTimetable;
import React from 'react'

function StudentTimetable() {
  return (
    <div>
      
    </div>
  )
}

export default StudentTimetable
