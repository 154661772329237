import React from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./AcademicsPage.css";
import { useState } from "react";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import AcademicsComponent from "../../components/AcademicsComponent/AcademicsComponent";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import filtericon from "./assets/filter.svg"
import addicon from "./assets/add.svg"
import downloadicon from "./assets/dwnld.svg"
import IconButton from "../../components/IconButton/IconButton";
import MobileTableHeader from "../../components/MobileTableHeader/MobileTableHeader";
import ClassListTable from "../../components/ClassListTable/ClassListTable";
import TabTimetablenew from "../../components/TabTimetablenew/TabTimetablenew";
import AddClassPopup from "../../components/AddClassPopup/AddClassPopup";
import AddTimetablePopup from "../../components/AddTimetablePopup/AddTimetablePopup";
import { AcademicsClassList } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useEffect } from "react";
import AddSubjectPopup from "../../components/AddSubjectPopup/AddSubjectPopup";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import UnavailablePopup from "../../components/UnavailablePopup/UnavailablePopup";


function AcademicsPage({ section}) {


const [academicDwnldPop, setAcademicDwnldPop] = useState(false)
const [addCoursePopup, setAddCoursePopup] = useState(false)
const [addTimetablePopup, setAddTimetablePopup] = useState(false)
const [timetableAddData, setTimetableAddData] = useState({class:"",division:""});
const [clicked, setClicked] = useState("CLASS")
const [academicsClsData, setAcademicsClsData] = useState([])
const [addSubjectPopup, setaddSubjectPopup] = useState(false)
const [subjectAdded, setSubjectAdded] = useState(false)
const [courseAddSuccess, setCourseAddSuccess] = useState(false)
const [academyFiltDrop, setAcademyFiltDrop] = useState(false);



const classesList=async()=>{
  const route = AcademicsClassList();
  const resp = await apiCall(route,{});
  if(resp.Status==="success"){
    setAcademicsClsData(resp.Data)
  }
}

useEffect(() => {
  classesList();
}, [courseAddSuccess])


  return (
    <div className="timetable-container">
     

{academicDwnldPop? (
        <div className="warning-div">
          <UnavailablePopup setUnavailablePopup={setAcademicDwnldPop}/>
          {/* <DeleteWarnPopup
            type="download"
            setWarning={setAcademicDwnldPop}
          /> */}
        </div>
      ) : (
        ""
      )}

{subjectAdded? (
        <div className="warning-div">
          <SuccessFailPopup
          type="success"
          text="Subject Added"
          setclosePopup={setSubjectAdded}
          />
        </div>
      ) : (
        ""
      )}

{courseAddSuccess? (
        <div className="warning-div">
          <SuccessFailPopup
          type="success"
          text="Course Added"
          setclosePopup={setCourseAddSuccess}
          />
        </div>
      ) : (
        ""
      )}


{addSubjectPopup? (
        <div className="warning-div">
         <AddSubjectPopup 
         setSubjectAdded={setSubjectAdded}
         setaddSubjectPopup={setaddSubjectPopup}/>
        </div>
      ) : (
        ""
      )}

{addCoursePopup? (
        <div className="warning-div">
          <AddClassPopup
          setCourseAddSuccess={setCourseAddSuccess}
           setAddCoursePopup={setAddCoursePopup}/>
        </div>
      ) : (
        ""
      )}

{addTimetablePopup? (
        <div className="warning-div">
          <AddTimetablePopup setAddTimetablePopup={setAddTimetablePopup} setTimetableAddData={setTimetableAddData} timetableAddData={timetableAddData}/>
        </div>
      ) : (
        ""
      )}

      

     <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section}/>
      </div>

<div>
      <HeaderSection
        section={section}
      />
</div>




<div className="timetable-component-div">
<AcademicsComponent 
setAddCoursePopup={setAddCoursePopup}
setAcademicDwnldPop={setAcademicDwnldPop}
setAddTimetablePopup={setAddTimetablePopup}
timetableAddData={timetableAddData}
academicsClsData={academicsClsData}
setaddSubjectPopup={setaddSubjectPopup}
academyFiltDrop={academyFiltDrop}
  setAcademyFiltDrop={setAcademyFiltDrop}
/>
</div>



<div className="academicsMobile">

<div className="academicsMobile-title">
          <p>ACADEMICS</p>
        </div>

        <div className="academicsMobile-head">
          <MobileTableHeader
           clicked={clicked}
           setClicked={setClicked}
           header1="CLASS"
           header2="TIMETABLE"
          />
        </div>
        {clicked==="CLASS"?
        <div className="academicsMobile-btns">
        <div onClick={()=>setaddSubjectPopup(true)}> <IconButton img={addicon} /></div> 
        <div onClick={() => setAddCoursePopup(true)}> <IconButton img={addicon} /></div> 
        </div>
        :
         clicked==="TIMETABLE"?
        <div className="academicsMobile-btns">
        <div> <IconButton img={addicon} /></div> 
        <div onClick={() => setAcademicDwnldPop(true)}>  <IconButton img={downloadicon} /></div> 
        <div onClick={() => setAcademyFiltDrop(!academyFiltDrop)}> <IconButton img={filtericon} /></div> 
        </div>
        :""}

        <div className="academicsMobile-classTimeBox">
          {clicked==="CLASS"?
        <ClassListTable academicsClsData={academicsClsData}/>
        :clicked==="TIMETABLE"?
        <TabTimetablenew/>
        :""}
        </div>

</div>
  
     
    </div>
  );
}

export default AcademicsPage;
