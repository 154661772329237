import React from "react";
import "./HeaderSection.css";
import { useState } from "react";
import StudentCard from "../StudentCard/StudentCard";
import AttendanceCard from "../AttendanceCard/AttendanceCard";
import { Link, useNavigate } from "react-router-dom";
import zedlogo from "./assets/zedlogo2.svg";
import dp from "./assets/dp.jpg";
import appicon from "./assets/appicon.svg"
import apiCall, { baseUrl } from "../../Services/apiCall";
import { AcademyView, AcademyViewImage, ImageViewRoute } from "../../Services/apiServices";
import { useEffect } from "react";

const HeaderSection = ({ section, type ,dashboardcontents,ongoingclass,classwiseattendance}) => {

  const navigate = useNavigate();

  const [navProfile, setNavProfile] = useState(false);

  const [AcademyNavData, setAcademyNavData] = useState([])

const academyNavbarSubmit=async()=>{
  const route = AcademyView();
  const resp = await apiCall(route,{});
  if(resp.Status==="success"){
    setAcademyNavData(resp.Data)
  }
  else if(resp.Status==="no_data"){
    setAcademyNavData([])
  }
  else if(resp.Status==="token_is_invalid"){
    navigate("/login");
  }
}
const route=AcademyViewImage();

useEffect(() => {
  academyNavbarSubmit();
}, [])

  return (
    <div
      className={
        type === "studprofile" ||
        section === "studentattendance" ||
        section === "academics"||
        section === "profiles" ||
        section === "payments" ||
        section === "teacherprofile" ||
        section === "adminprofile"
          ? "headersection2"
          : "headersection"
      }
    >
      <div className="navbar-section">
        <div className="left-navbar">
          <Link className="zed-link" to="/dashboard">
            <img
              className="logo"
              // src={baseUrl + "/student/svg_image?Image=zedtaqplogo.svg"}
              src={zedlogo}
              alt="ZedTap"
            />
            <img
              className="app-logo"
              // src={baseUrl + "/student/svg_image?Image=zedtaqplogo.svg"}
              src={appicon}
              alt="ZedTap"
            />

          </Link>
         
        </div>

   <div className="middle-navbar">
          <Link
            to="/dashboard"
            className={
              section === "dashboard" ? "active-link" : "inactive-link"
            }
          >
            <div
              className={
                section === "dashboard" ? "active-navbar" : "nonactive-navbar"
              }
            >
              Dashboard
            </div>
          </Link>

          <Link
            to="/studentattendance"
            className={
              section === "studentattendance" ? "active-link" : "inactive-link"
            }
          >
            <div
              className={
                section === "studentattendance"
                  ? "active-navbar"
                  : "nonactive-navbar"
              }
            >
              Attendance
            </div>
          </Link>

          <Link
            to="/academics"
            className={
              section === "academics" ? "active-link" : "inactive-link"
            }
          >
            <div
              className={
                section === "academics" ? "active-navbar" : "nonactive-navbar"
              }
            >
              Academics
            </div>
          </Link>

          <Link
            to="/profiles"
            className={section === "profiles" ? "active-link" : "inactive-link"}
          >
            <div
              className={
                section === "profiles" || section === "studentprofile" ||section === "teacherprofile"
                  ? "active-navbar"
                  : "nonactive-navbar"
              }
            >
              Profiles
            </div>
          </Link>

          <Link
            to="/payments"
            className={section === "payments" ? "active-link" : "inactive-link"}
          >
            <div
              className={
                section === "payments" ? "active-navbar" : "nonactive-navbar"
              }
            >
              Payments
            </div>
          </Link>
        </div>

        <div className="right-navbar">
          <Link className="admin-link" to="/adminprofile">
            <div
              className="navbar-profile-section"
              onClick={() => setNavProfile(!navProfile)}
            >
              <div className="navbar-profile-content">
                <img
                  className="navbar-dp"
                 
                src={baseUrl+ImageViewRoute()+AcademyNavData?.image}
                  alt="no"
                />
                <div>
                  <div className="admin-name">{AcademyNavData.name}</div>
                  <div className="navbar-email">{AcademyNavData.email}</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      

      {section === "dashboard" ? (
        <div className="header-cards">
          <div className="studentcard-div">
            <StudentCard dashValues={dashboardcontents?.Total} />
          </div>
          <div className="attendance-div">
            <AttendanceCard
              title="TODAY'S ATTENDANCE"
              datas="attendance"
              heading="Strength"
              carddata={classwiseattendance}

            />
          </div>
          <div className="ongoing-div">
            <AttendanceCard
              title="ONGOING CLASSES"
              datas="ongoing"
              heading="Subject"
              carddata={ongoingclass}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {section === "profiles" ? (
        <div className="student-heading">Profiles</div>
      ) : section === "studentattendance" ? (
        <div className="student-heading">Attendance</div>
      ) : section === "adminprofile" ? (
        <div className="student-heading">Academy Profile</div>
      ) : section === "studentprofile" ? (
        <div className="student-heading">Student Profile</div>
      ): section ==="teacherprofile"?(
        <div className="student-heading">Teacher Profile</div>
      )
      : (
        ""
      )}

      {section === "payments" ? (
        <div className="student-heading">Payments</div>
      ) : (
        ""
      )}

      {section === "academics" && (
        <div className="student-heading">Academics</div>
      )}
    </div>
  );
};

export default HeaderSection;
