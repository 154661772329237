import React from 'react'
import "./ViewNoticePopup.css"

const ViewNoticePopup = ({setNoticePopup,setNoticeData,noticeData}) => {
  return (

    <div className='ViewNoticePopup-wrapper'>
        <div className="ViewNoticePopup-container">
            <div className='ViewNoticePopup-title'>{noticeData.title}</div>
            <div className='ViewNoticePopup-content'>{noticeData.text}</div>
           <div className='ViewNoticePopup-btn-box' >
            <div className='ViewNoticePopup-btn' onClick={()=>{setNoticeData([]);setNoticePopup(false)}}>Ok</div>
           </div>
        </div>
        
    </div>
  )
}

export default ViewNoticePopup