import React from 'react'
import "./PendingPayTable.css"

const PendingPayTable = ({pendingPayments,pendingPayFilterAdd}) => {


  return (
    <div className='PendingPayTable-container'>
    {/* <div className="PendingPayTable-wrapper">
  <div className='PendingPayTable-head'  >Admn.Id</div>
  <div className='PendingPayTable-head'  >Name</div>
  <div className='PendingPayTable-head'  >Class</div>
  <div className='PendingPayTable-head'  >Division</div>
  <div className='PendingPayTable-head'  >{pendingPayFilterAdd.month?"month":"no.of Months"} </div>
  <div className='PendingPayTable-head'  >Phone no.</div>
  </div> */}

{/* <div className='PendingPayTable-scroll-container'>

  {pendingPayments?.map((data,index)=>{
    return(
        <div key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='PendingPayTable-content-wrapper'>
  <div  className='PendingPayTable-content'>{data?.AdmNo.replace(
                      localStorage.getItem("adminKey"),
                      ""
                    )}</div>
  <div className='PendingPayTable-content'>{data?.Name}</div>
  <div  className='PendingPayTable-content'>{data?.Class}</div>
  <div className='PendingPayTable-content'>{data?.Division}</div>
  <div className='PendingPayTable-content'>{data?.PendingMonths}</div>
  <div className='PendingPayTable-content'>{data?.PhoneNo}</div>
</div>
    )
  })}

</div> */}

<div className="demmy" style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}>
  <label htmlFor=""><>Currently Not Available ...!</></label>
</div>


</div>
  )
}

export default PendingPayTable