import React, { useEffect, useState } from "react";
import "./TodayPaymentsComponent.css";
import todaycoin from "./assets/todaycoin.svg";
import TodayPaymentsTable from "../TodayPaymentsTable/TodayPaymentsTable";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import filter from "./assets/filter.svg";
import add from "./assets/add.svg";
import IconButton from "../IconButton/IconButton";
import apiCall from "../../Services/apiCall";
import { TodayFeePayment } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";

const TodayPaymentsComponent = ({
  setFeepopup,
  settodayFeeFilter,
  todayFeeFilter,
  feepopup,
}) => {
  const navigate = useNavigate();

  const [todayFeeData, setTodayFeedata] = useState([]);
  const [cls, setCls] = useState("Class");
  const [div, setDiv] = useState("Division");

  const TodaysFeeList = async () => {
    const route = TodayFeePayment();
    const response = await apiCall(route, {
      Class: cls === "Class" ? "" : cls,
      Division: div === "Division" ? "" : div,
    });
    if (response.Status === "success") {
      setTodayFeedata(response);
    } else if (response.Status === "no_data") {
      setTodayFeedata([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  

  useEffect(() => {
    TodaysFeeList();
  }, [feepopup, cls, div]);

  return (
    <div className="todayPayComponent-container">
      <div className="todayPayComponent-top">
        <div className="todayPayComponent-topLeft">
          <img src={todaycoin} alt="" />
          <p>TODAY'S FEE PAYMENTS</p>
        </div>

        <div className="todayPayComponent-topRight">
          <div onClick={() => setFeepopup(true)}>
            <IconButton label="Add Fee" img={add} />
          </div>
          <div
            className="todayPayComponent-filter"
            onClick={() => settodayFeeFilter(!todayFeeFilter)}
          >
            <IconButton label="Filter" img={filter} />
          </div>
          {todayFeeFilter ? (
            <div className="todayPayComponent-filter-drop">
              <FilterDropdown
                cls={cls}
                setCls={setCls}
                div={div}
                setDiv={setDiv}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="todayPayComponent-middle">
        <div>
          <TodayPaymentsTable todayFeeResponse={todayFeeData} />
        </div>
      </div>

      <div className="todayPayComponent-bottom">
        <div>
          <p>Total : </p>
          <p>₹ {todayFeeData?.Total}</p>
        </div>
      </div>
    </div>
  );
};

export default TodayPaymentsComponent;
