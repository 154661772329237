import React from 'react'
import "./AbsentFilterPopup.css"
import { CourseList, ViewDivision } from '../../Services/apiServices';
import apiCall from '../../Services/apiCall';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import InputComponent from '../InputComponent/InputComponent';
import "../AttendanceFilter/AttendanceFilter.css"
import { useEffect } from 'react';

const AbsentFilterPopup = ({
    setAbsentFiltPopup,
       absentDateFilter,
       setAbsentDateFilter,
       setFilterSelectInput,
       absentListView
}) => {
    const navigate = useNavigate();

    const [courseslistshow, setCourseslistshow] = useState([])


    const AbsentCourseList=async()=>{
        const route = CourseList();
        const resp = await apiCall(route,{});
        if(resp.Status==="success"){
            setCourseslistshow(resp.Course);
        }
        else if(resp.status==='no_data'){
            setCourseslistshow([])
           }
        else if(resp.status==='token_is_invalid'){
            navigate('/login')
           }
    }


    const AbsentDateDivisions= courseslistshow?.filter((data)=>data.class===absentDateFilter.class);
    const [div, setDiv] = useState([]);
    const handleDivision = async (data) => {
      const route = ViewDivision();
      const resp = await apiCall(route, { Class: data ? data : "" });
      if (resp.Status === "success") {
        setDiv(resp?.Division);
      }
    };

    const absentDateCancel=()=>{
        setAbsentFiltPopup(false);
        setAbsentDateFilter({
            ...absentDateFilter,
            date:"",
            class:"",
            division:""
        })
    }

    useEffect(() => {
        AbsentCourseList();
    }, [])

  return (
    <div className="AttendanceFilter-wrapper">
    <div className="AbsentFilter-container">

    <div className="AttendanceFilter-middle">
          <div className="AttendanceFilter-month">
            <p>Date : </p>
            <InputComponent
              type="date"
              isRequired={false}
              placeHolder="date"
                 value={absentDateFilter.date}
                 onChange={(e)=>{setAbsentDateFilter({...absentDateFilter,date:e.target.value});setFilterSelectInput(e.target.value)}}
            />
          </div>

          <select
            name=""
            id=""
            className="selectbox"
            value={absentDateFilter.class}
            onChange={(e)=>{setAbsentDateFilter({...absentDateFilter,class:e.target.value})
            handleDivision(e.target.value)}}
          >
            <option className="default-select" value="" selected>
              Class
            </option>
            {courseslistshow?.map((option,index)=>{
        return(
            <option key={index} value={option.class}>{option.class}</option>
     )
    })}
          </select>

          <select
            name=""
            id=""
            className="selectbox"
            value={absentDateFilter.division}
            onChange={(e)=>setAbsentDateFilter({...absentDateFilter,division:e.target.value})}
          >
            <option className="default-select" value="">
              Division
            </option>
            {div?.map((divdata,index)=>{
        return(
            <option key={index} value={divdata?.division}>{divdata?.division}</option>
         )
    })} 
          </select>
        </div>

        <div className="AttendanceFilter-btns-container">
          <div onClick={absentDateCancel} className="AttendanceFilter-cancelBtn">Cancel</div>

          <button onClick={absentListView}
           className="AttendanceFilter-chooseBtn btn-outline">
            Filter
          </button>
        </div>


        </div>
        </div>
  )
}

export default AbsentFilterPopup