import React, { useState } from "react";
import "./AttendanceFilterPopup.css";
import InputComponent from "../InputComponent/InputComponent";
import { CourseList, ViewDivision } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AttendanceFilterPopup = ({
  setAttendFiltPopup,
  dateFilterAttendanceSubmit,
  attendanceDateFilter,
  setAttendanceDateFilter,
  attendanceMonthFilter,
  setAttendanceMonthFilter,
  monthFilterAttendanceSubmit,
  setFilterSelectInput,
  monthAttendStatus,
  setMonthAttendStatus,
}) => {
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("date");
  const [selectedClass, setSelectedClass] = useState("");
  const [attendCourseData, setAttendCourseData] = useState([]);
  const [div,setDiv] = useState([])
  const handleDivision =async(data)=>{

    const route = ViewDivision()
    const resp = await apiCall(route,{Class:data?data:""})
    if(resp.Status ==="success"){
        setDiv(resp?.Division)
    }
  }
  const AttendCourseList = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setAttendCourseData(resp.Course);
    } else if (resp.status === "no_data") {
      setAttendCourseData([]);
    } else if (resp.status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const AttendanceDateDivisions = attendCourseData?.filter(
    (data) => data.class === attendanceDateFilter.class
  );
  const AttendanceMonthDivisions = attendCourseData?.filter(
    (data) => data.class === attendanceMonthFilter.class
  );

  const dateHandleCancel = () => {
    setAttendFiltPopup(false);
    setAttendanceDateFilter({
      ...attendanceDateFilter,
      date: "",
      class: "",
      division: "",
    });
  };

  const monthHandleCancel = () => {
    setAttendFiltPopup(false);
    setAttendanceMonthFilter({
      ...attendanceMonthFilter,
      month: "",
      class: "",
      division: "",
    });
    setMonthAttendStatus("");
  };

  useEffect(() => {
    AttendCourseList();
  }, []);

  useEffect(() => {
    setAttendanceDateFilter({
      ...attendanceDateFilter,
      date: "",
      class: "",
      division: "",
    });
    setFilterSelectInput("");

    setAttendanceMonthFilter({
      ...attendanceMonthFilter,
      month: "",
      class: "",
      division: "",
    });
  }, [selectedFilter]);

  return (
    <div className="AttendanceFilter-wrapper">
      <div className="AttendanceFilter-container">
        <div className="AttendanceFilter-head">
          <p
            className={
              selectedFilter === "date"
                ? "radiocheck"
                : "AttendanceFilter-select"
            }
            onClick={() => setSelectedFilter("date")}
          >
            <input
              type="radio"
              value="date"
              name="attendance"
              id=""
              checked={selectedFilter === "date"}
              onChange={(e) => setSelectedFilter(e.target.value)}
            />
            Datewise
          </p>
          <p
            className={
              selectedFilter === "month"
                ? "radiocheck"
                : "AttendanceFilter-select"
            }
            onClick={() => setSelectedFilter("month")}
          >
            <input
              type="radio"
              value="month"
              name="attendance"
              id=""
              checked={selectedFilter === "month"}
              onChange={(e) => setSelectedFilter(e.target.value)}
            />
            Monthwise
          </p>
        </div>

        {selectedFilter === "date" ? (
          <>
            <div className="AttendanceFilter-middle">
              <div className="AttendanceFilter-month">
                <p>Date : </p>
                <InputComponent
                  type="date"
                  isRequired={false}
                  placeHolder="date"
                  value={attendanceDateFilter.date}
                  onChange={(e) => {
                    setAttendanceDateFilter({
                      ...attendanceDateFilter,
                      date: e.target.value,
                    });
                    setFilterSelectInput(e.target.value);
                  }}
                />
              </div>

              <select
                name=""
                id=""
                className="selectbox"
                value={attendanceDateFilter.class}
                onChange={(e) =>
               {   setAttendanceDateFilter({
                    ...attendanceDateFilter,
                    class: e.target.value,
                  })
                  setSelectedClass(e.target.value)
                  handleDivision(e.target.value)}
                }
              >
                <option className="default-select" value="" selected>
                  class
                </option>
                {attendCourseData?.map((option, index) => {
                  return (
                    <option key={index} value={option.class}>
                      {option.class}
                    </option>
                  );
                })}
              </select>

              <select
                name=""
                id=""
                className="selectbox"
                value={attendanceDateFilter.division}
                onChange={(e) =>
                  setAttendanceDateFilter({
                    ...attendanceDateFilter,
                    division: e.target.value,
                  })
                }
              >
                <option className="default-select" value="">
                  Division
                </option>
                {div?.map((divdata, index) => {
                  return (
                    <option key={index} value={divdata?.division}>
                      {divdata?.division}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="AttendanceFilter-btns-container">
              <div
                onClick={dateHandleCancel}
                className="AttendanceFilter-cancelBtn"
              >
                Cancel
              </div>

              <button
                onClick={dateFilterAttendanceSubmit}
                className="AttendanceFilter-chooseBtn btn-outline"
              >
                Filter
              </button>
            </div>
          </>
        ) : (
          ""
        )}

        {selectedFilter === "month" ? (
          <>
            <div className="AttendanceFilter-middle">
              <div className="AttendanceFilter-month">
                <p>Month : </p>
                <InputComponent
                  type="month"
                  isRequired={false}
                  placeHolder="month"
                  value={attendanceMonthFilter.month}
                  onChange={(e) => {
                    setAttendanceMonthFilter({
                      ...attendanceMonthFilter,
                      month: e.target.value,
                    });
                    setFilterSelectInput(e.target.value);
                    setMonthAttendStatus("");
                  }}
                />
              </div>

              <select
                name=""
                id=""
                className="selectbox"
                value={attendanceMonthFilter.class}
                onChange={(e) => {
                  setAttendanceMonthFilter({
                    ...attendanceMonthFilter,
                    class: e.target.value,
                  });
                  setMonthAttendStatus("");
                  handleDivision(e.target.value)
                }}
              >
                <option className="default-select" value="" selected>
                  class
                </option>
                {attendCourseData?.map((option, index) => {
                  return (
                    <option key={index} value={option.class}>
                      {option.class}
                    </option>
                  );
                })}
              </select>

              <select
                name=""
                id=""
                className="selectbox"
                value={attendanceMonthFilter.division}
                onChange={(e) => {
                  setAttendanceMonthFilter({
                    ...attendanceMonthFilter,
                    division: e.target.value,
                  });
                  setMonthAttendStatus("");
                }}
              >
                <option className="default-select" value="">
                  Division
                </option>
                {div?.map(
                  (divdata, index) => {
                    return (
                      <option key={index} value={divdata?.division}>
                        {divdata?.division}
                      </option>
                    );
                  }
                )}
              </select>
            </div>

            <div>
              <p className="AttendanceFilter-status">{monthAttendStatus}</p>

              <div className="AttendanceFilter-btns-container">
                <div
                  onClick={monthHandleCancel}
                  className="AttendanceFilter-cancelBtn"
                >
                  Cancel
                </div>

                <button
                  onClick={monthFilterAttendanceSubmit}
                  className="AttendanceFilter-chooseBtn btn-outline"
                >
                  Filter
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AttendanceFilterPopup;
