import React from "react";
import "./CustomNotification.css";
import custom from "./assets/custom.svg";
import notify from "./assets/notify.svg";
import { useState } from "react";
import apiCall from "../../Services/apiCall";
import {
  CourseList,
  Customnotification,
  ViewDivision,
} from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const CustomNotification = ({ setnotifyStatus, notifyStatus }) => {
  const [typeOfNotification, setTypeOfNotification] = useState("select");
  const [classesData, setclassesData] = useState([]);

  const [customNotifyData, setcustomNotifyData] = useState({
    Class: "",
    Division: "",
    MessageCustom: "",
    title: "",
  });

  const navigate = useNavigate();

  const notifyFiltClass = classesData?.filter(
    (data) => data.class === customNotifyData.Class
  );
  const [div, setDiv] = useState([]);
  const handleDivision = async (data) => {
    const route = ViewDivision();
    const resp = await apiCall(route, { Class: data ? data : "" });
    if (resp.Status === "success") {
      setDiv(resp?.Division);
    }
  };

  const classdivSubmit = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setclassesData(resp.Course);
    }
  };

  const customNotify = async (e) => {
    e.preventDefault();
    const route = Customnotification();
    const response = await apiCall(route, {
      Classes: customNotifyData.Class ? customNotifyData.Class : "",
      Division: customNotifyData.Division ? customNotifyData.Division : "",
      Title: customNotifyData.title ? customNotifyData.title : "",
      Text: customNotifyData.MessageCustom
        ? customNotifyData.MessageCustom
        : "",
    });
    if (response.Status === "success") {
      setnotifyStatus(true);
      setcustomNotifyData({
        Class: "",
        Division: "",
        MessageCustom: "",
        title: "",
      });
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  useEffect(() => {
    classdivSubmit();
  }, []);

  useEffect(() => {
    setcustomNotifyData({
      Class: "",
      Division: "",
      MessageCustom: "",
      title: "",
    });
  }, [typeOfNotification]);

  return (
    <div className="customNotification">
      <div className="custom-top">
        <div className="customsvg-box">
          <img src={custom} alt="" />
        </div>
        <div>CUSTOM NOTIFICATION</div>
      </div>

      <form onSubmit={customNotify}>
        <div className="custom-middle">
          <div>
            <select
              name=""
              className="to-input"
              id=""
              value={typeOfNotification}
              onChange={(e) => {
                setTypeOfNotification(e.target.value);
              }}
              required
            >
              <option value="">Select</option>
              <option value="all">All</option>
              <option value="classdivision">Class Division</option>
              <option value="classwise">Classwise</option>
            </select>
          </div>
          {typeOfNotification === "classdivision" && (
            <>
              <div>
                <select
                  name=""
                  className="to-input"
                  id=""
                  onChange={(e) => {
                    setcustomNotifyData({
                      ...customNotifyData,
                      Class: e.target.value,
                    });
                    handleDivision(e.target.value);
                  }}
                  required
                >
                  <option value="">Select Class</option>
                  {classesData?.map((data, index) => {
                    return (
                      <option key={index} value={data.class}>
                        {data.class}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <select
                  name=""
                  className="to-input"
                  id=""
                  onChange={(e) => {
                    setcustomNotifyData({
                      ...customNotifyData,
                      Division: e.target.value,
                    });
                  }}
                  required
                >
                  <option value="">Select Division</option>
                  {div?.map((data, index) => {
                    return (
                      <option key={index} value={data?.division}>
                        {data?.division}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          )}
          {typeOfNotification === "classwise" && (
            <div>
              <select
                name=""
                className="to-input"
                id=""
                onChange={(e) => {
                  setcustomNotifyData({
                    ...customNotifyData,
                    Class: e.target.value,
                  });
                }}
                required
              >
                <option value="">Select</option>
                {classesData?.map((data, index) => {
                  return (
                    <option key={index} value={data.class}>
                      {data.class}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          <div>
            <input
              className="title-input"
              type="text"
              value={customNotifyData.title}
              placeholder="Title"
              onChange={(e) => {
                setcustomNotifyData({
                  ...customNotifyData,
                  title: e.target.value,
                });
              }}
              required
            />
          </div>
          <div>
            <textarea
              className="msg-input"
              placeholder="Enter message"
              name=""
              id=""
              cols="30"
              rows="10"
              value={customNotifyData.MessageCustom}
              onChange={(e) => {
                setcustomNotifyData({
                  ...customNotifyData,
                  MessageCustom: e.target.value,
                });
              }}
              required
            ></textarea>
          </div>
        </div>

        <div className="custom-btm">
          <button className="notify-btn" type="submit">
            <img src={notify} alt="" />
            <p>Notify</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomNotification;
