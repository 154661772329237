import React, { useEffect } from 'react'
import "./StudentRegisterContainer.css"
import LeftTopSvg from './assets/lefttop.png'
import BottomRightSvg from './assets/bottomright.png'
import "../LoginContainer/LoginContainer.css"
import Logo from './assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import apiCall from '../../Services/apiCall'
import { AcademyListViewRoute, LoginFunction, StudRegisterRoute, StudRegisterform1 } from '../../Services/apiServices'
import ResetComponent from '../../components/ResetComponent/ResetComponent'
import { useState } from 'react'

const StudentRegisterContainer = () => {

  const navigate = useNavigate();

    const [check, setCheck] = useState("");
   const [regSection, setRegSection] = useState(0);
   const [academyLists, setAcademyLists] = useState([])

  const [registerStudData,setregisterStudData] = useState({
    academy:"",
    admno:"",
    phone:"",
    username:"",
    createpass:"",
    confirmpass:""
  })

  

  const passwordSubmit=(e)=>{
    setregisterStudData({...registerStudData,password:(e.target.value)});
    setCheck("")
  }

  const viewAcademyList=async()=>{
    const route = AcademyListViewRoute();
    const resp = await apiCall(route,{});
    if(resp.Status==="success"){
      setAcademyLists(resp.Data);
    }
    else if(resp.Status==="no_data"){
      setAcademyLists([]);
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/registration')
     }
  }

  const registerFormOne=async(e)=>{
    e.preventDefault();
    const route = StudRegisterform1();
    const resp = await apiCall(route,{
      AdmNo: (registerStudData.admno&&registerStudData.academy)?registerStudData.academy+registerStudData.admno:"",
      PhoneNo : registerStudData.phone?"+91"+registerStudData.phone:"",
    })
    if(resp.Status==="success"){
      setCheck("");
      setRegSection(1);
    }
    else if(resp.Status==="already_registered"){
      setCheck("Already Registered !")
    }  
    else if(resp.Status==='token_is_invalid'){
      navigate('/registration')
     }
     else if(resp.Status==="student_not_exist"){
      setCheck("Enter Valid Admission no. or phone !")
    }
  }

const studRegisterSubmitLast=async(e)=>{
  e.preventDefault();
  const route = StudRegisterRoute();
  const resp = await apiCall(route,{
    AdmNo : (registerStudData.admno&&registerStudData.academy)?registerStudData.academy+registerStudData.admno:"",
    Username : registerStudData.username?registerStudData.username:"",
    Password : registerStudData.createpass?registerStudData.createpass:"",
   ConfirmPassword : registerStudData.confirmpass?registerStudData.confirmpass:""
  })
  if(resp.Status==="student_registered"){
    navigate('/redirect');
  }
  else if(resp.Status==="username_exists"){
    setCheck("username already exists");
  }
  else if(resp.Status==="password_mismatch"){
    setCheck("password mismatch");
  }
  else if(resp.Status==='token_is_invalid'){
    navigate('/registration');
   }
} 

useEffect(() => {
  viewAcademyList();
}, [regSection])



  return (
    <div className='loginpage-wrapper'>
    <img src={LeftTopSvg} alt="" className='loginpage-left-top' />
    <div className='loginpage-box'>
      <div className="loginbox-inside">
      <Link to={'/'} className='login-link'> 
        <img src={Logo} alt="" height={'20px'}/>
        </Link>
      </div>
      <div className="loginbox-inside">
        <h3>Welcome to Zedtap</h3>
      </div>
      <div className="loginbox-inside">

{regSection===0?

        <form className="login-main" onSubmit={registerFormOne} >

          <select 
          value={registerStudData.academy}
          onChange={(e)=>setregisterStudData({...registerStudData,academy:e.target.value})}
          style={{ color: registerStudData.academy ? "#FFFFFF" : "#575F75" }}
          className='studRegister-academy' name="" id="" required>
          <option className='nullfeetype' value="">Select Academy</option>
          {academyLists?.map((data,index)=>{
            return(
              <option key={index} value={data.adm_key}>{data.name}</option>
            )
          })}
          
          </select>

          <div className="optionslogin-main">
            <input className={registerStudData.admno?"active-input":""} 
            type="number" name="admno" autoComplete="off" id="" 
            value={registerStudData.admno}
            onChange={(e)=>{setregisterStudData({...registerStudData,admno:(e.target.value)});setCheck("")}} 
            required/>
            <label htmlFor="" className='login-label'>Admission number</label>
          </div>
          <div className="optionslogin-main">
            <input className={registerStudData.phone?"active-input":""} 
             type="tel" pattern="[6789]\d{9}" name="phone" id="" autoComplete='off'
             value={registerStudData.phone}
               onChange={(e)=>{setregisterStudData({...registerStudData,phone:(e.target.value)})}}
                required/>
            <label htmlFor="" className='login-label'>Phone</label>
          </div>

          
       
          <div className="optionslogin-main">
            <button type='submit' className='loginContainer-btn'>Next</button>
          </div>

          <div className='login-container-status'>
          {check}
          </div>

          <div className="optionslogin-main">
           <Link to={'/studentLogin'} className='login-link'>Already have an account? <span style={{color:"white"}}>Login</span></Link>
          </div>
        </form>
        :""}

{regSection===1?

<form className="login-main" onSubmit={studRegisterSubmitLast} >

  <div className="optionslogin-main">
    <input className={registerStudData.username?"active-input":""} 
    type="text" name="admno" autoComplete="off" id="" 
    value={registerStudData.username}
    onChange={(e)=>{setregisterStudData({...registerStudData,username:(e.target.value)})}}
     required/>
    <label htmlFor="" className='login-label'>Username</label>
  </div>

  <div className="optionslogin-main">
    <input className={registerStudData.createpass?"active-input":""}
      type="password" name="phone" id="" autoComplete='off' 
      value={registerStudData.createpass}
       onChange={(e)=>{setregisterStudData({...registerStudData,createpass:(e.target.value)})}}
        required/>
    <label htmlFor="" className='login-label'>Create Password</label>
  </div>

  <div className="optionslogin-main">
    <input className={registerStudData.confirmpass?"active-input":""} 
     type="password" name="phone" id="" autoComplete='off' 
     value={registerStudData.confirmpass}
      onChange={(e)=>{setregisterStudData({...registerStudData,confirmpass:(e.target.value)})}} 
      required/>
    <label htmlFor="" className='login-label'>Re-enter Password</label>
  </div>

  <div className="optionslogin-main">
    <button type='submit' className='loginContainer-btn'>Register</button>
  </div>
  <div className='regSection-back'><p onClick={()=>setRegSection(0)}>Go Back</p></div>

  <div className='login-container-status'>
  {check}
  </div>

  <div className="optionslogin-main">
   <Link to={'/studentLogin'} className='login-link'>Already have an account? <span style={{color:"white"}}>Login</span></Link>
  </div>
</form>
:""}


      </div>
    </div>
    <img src={BottomRightSvg} alt="" className='loginpage-right-top' />
  </div>
  )
}

export default StudentRegisterContainer