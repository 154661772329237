import React from 'react'
import { useState } from 'react'
import "./ChangePasswordPopup.css"
import apiCall from '../../Services/apiCall'

const ChangePasswordPopup = ({setAdminPasswordPop}) => {

const [resetPassword,setResetpassword] = useState({})
const [errorStatus,setErrorStatus] = useState('')

  const handleResetpassword = async (e) => {
    e.preventDefault()
    let token = localStorage.getItem("token");

    const resetresult = await apiCall("/Reset_Password", {
      password: resetPassword.password ? resetPassword.password : "",
      confirmpassword: resetPassword.confirmpassword ? resetPassword.confirmpassword : "",
      token: token,
    })
    setErrorStatus(resetresult.status)
    setTimeout(()=>{setAdminPasswordPop(false)},2000)
    
    
  }


    const handleSubmitReset =(e)=>{
     
            setResetpassword({...resetPassword,[e.target.name]:e.target.value})
      }
    
  return (
    <div
      className="changepass-popup-wrap"
    >
      <div className="change-pass-box">
        <div className="change-pass-label">
        Change Password
        </div>

        <form action="" method="POST" className="reset-form" onSubmit={handleResetpassword}>

          <input
            type="password"
            name="password"
            id=""
            placeholder="Enter new password"
            pattern=".{8,}"
            title="Must contain at least 8 characters"
            onChange={(e) => {
              handleSubmitReset(e)
            }}
            required
          />
          <input
            type="password"
            name="confirmpassword"
            id=""
            placeholder="Confirm new password"
            pattern=".{8,}"
            title="Must contain at least 8 characters"
            onChange={(e) => {
              handleSubmitReset(e)

            }}
            required
          />

<div className="error_message">{errorStatus}</div>
          <div className="reset-btns-container">

          <div className="chnge-btns-container">
            
            <button type="submit" className="change-pass-btn" > Reset </button>

              <button className="change-pass-cancel" onClick={()=>setAdminPasswordPop(false)} >Cancel</button>
          </div>
          </div>
        </form>
  </div></div>
  )
}

export default ChangePasswordPopup
