import React from "react";
import "./ListCard.css";
import ClassDisplayBox from "../ClassDisplayBox/ClassDisplayBox";
import msgicon from "./msgicon.svg";
import { useNavigate } from "react-router-dom";

const ListCard = ({type,data,setIndividualMsg}) => {
  const toStudProfile = useNavigate();
  const toTeacherProfile = useNavigate();
  return (
    <>
    {type==="student_list"?
    <div className="ListCard-container" >
      <img onClick={()=>setIndividualMsg(true)} className="ListCard-msgicon" src={msgicon} alt="" />

      <div className="ListCard-top" >
        <div>
          <p className="ListCard-name">{data?.Name}</p>
          <p className="ListCard-id">ID : {data?.AdmNo.replace(localStorage.getItem("adminKey"), "")}</p>
        </div>
        <div>
          <ClassDisplayBox std={data?.Class} division={data?.Division}/>
        </div>
      </div>

      <div className="ListCard-btm"
      onClick={()=>toStudProfile(`/profile/${data?.AdmNo}`)}
      >
        <div className="ListCard-btmContent">
          <p className="ListCard-btmLabel">Phone :</p>
          <p className="ListCard-btmDetail">{data?.ParentPhone}</p>
        </div>

        <div className="ListCard-btmContent">
          <p className="ListCard-btmLabel">Guardian :</p>
          <p className="ListCard-btmDetail">{data?.Gaurdian}</p>
        </div>

        <div className="ListCard-btmContent">
          <p className="ListCard-btmLabel">Address :</p>
          <p className="ListCard-btmDetail">
            {data?.Address}
          </p>
        </div>
      </div>
    </div>
    :

    type==="teacher_list"?

    <div className="ListCard-container" onClick={()=>toTeacherProfile(`/teacherProfile/${data?.Id}`)}>
      {/* <img className="ListCard-msgicon" src={msgicon} alt="" /> */}

      <div className="ListCard-top">
        <div>
          <p className="ListCard-name" >{data.Name}</p>
          {/* <p className="ListCard-id">ID : 2000</p> */}
        </div>
        {data.Subject[0]?.subject_name?
        <div>
          <ClassDisplayBox std={data.Subject[0]?.subject_name} />
        </div>
        :""}
      </div>

      <div className="ListCard-btm">
        <div className="ListCard-btmContent">
          <p className="ListCard-btmLabel">Phone :</p>
          <p className="ListCard-btmDetail">{data.PhoneNo}</p>
        </div>

       
      </div>
    </div>


:""}

    </>
  );
};

export default ListCard;
