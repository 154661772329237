import React from "react";
import "./GradientLandingPage.css";
import zedlogo from "./figma assets/zedtaplogo.svg";
import "../../App.css";
import vector23 from "./figma assets/Vector 23.png";
import group3631 from "./figma assets/Group 3631.png";
import line195 from "./figma assets/Line 195.svg";
import bullet from "./figma assets/bullet.svg";
import vector20 from "./figma assets/Vector 20.png";
import cube from "./figma assets/cube.png";
import line194 from "./figma assets/Line 194.svg";
import ringGradient from "./figma assets/Vector 21.png";
import ring from "./figma assets/Group 3632.png";
import PricingCard from "../../components/PricingCard/PricingCard";
import { BasicPlan } from "../../Arraydata";
import { ProPlan } from "../../Arraydata";
import { proPlusPlan } from "../../Arraydata";
import circleringSvg from "./figma assets/circleringSvg.svg";
import circleRingGradient from "./figma assets/Vector 25.png";
import footerLogo from "./figma assets/footerLogo.svg";
import facebook from "./figma assets/facebook.svg";
import twitter from "./figma assets/twitter.svg";
import instagram from "./figma assets/instagram.svg";
import CurvyBox from "../../components/CurvyBox/CurvyBox";
import { NavLink } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import LinkedIn from "./linkedin.png"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const GradientLandingPage = () => {
  return (
    <div className="GradientLandingPage-wrapper">
      <header className="Gradientpage-header" id="home">
        <div className="Gradientpage-leftheader">
          <img src={zedlogo} alt="ZEDTAP" />
        </div>
        <div className="Gradientpage-rightheader">
          <div><a href="#home">Home</a></div>
          <div><a href="#product">Product</a></div>
          <div> <a href="#pricing">Pricing</a></div>
          <div><a href="#about">About</a></div>
          <NavLink to="/login" className="navlink">
            <div>LOGIN</div>
          </NavLink>
        </div>
      </header>

      <section className="gpage-section">
        <div className="Gradientpage-sec1">
          <img className="vector23" src={vector23} alt="" />
          <img className="group3631" src={group3631} alt="" />

          <div className="Gradientpage-sec1-left">
            <div className="Latofont">Introducing Zedtap</div>
            <div className="Gradientpage-title">
              Safeguarding the Future of Child Safety through Attendance
              Management
            </div>
            <div className="Gradientpage-title-des">
              Discover a seamless and innovative way to ensure child safety with
              ZedTap. Our advanced RFID technology, real-time updates, and
              data-driven insights revolutionize how institutions and parents
              can enhance child safety through attendance management.
            </div>
            <div className="Gradientpage-sec1-btns-container">
              <a href="https://forms.gle/p9BjBVRSfSEC8asq6" className="Gradientpage-sec1-btn">Book a Demo</a>
             <a href="https://www.youtube.com/watch?v=6Zy7iVBrS4g"> <div className="Gradientpage-sec1-btn">Watch Video</div></a>
            </div>
          </div>
          <div className="Gradientpage-sec1-right"></div>
        </div>

        <div className="Gradientpage-sec2" id="product">
          <img className="vector20" src={vector20} alt="" />
          <img className="cube" src={cube} alt="" />

          <div className="Gradientpage-sec2-title">
            Unlocking Educational Excellence with ZedTap
          </div>
          <div className="Gradientpage-sec2-solution">
            <p>Solutions</p>
            <img src={line195} alt="" />
          </div>
          <div className="sec2-solution-listing">
            <div className="sec2-solutionlist">
              <img src={bullet} alt="" />
              <p>
                ZedTap offers a comprehensive platform that prioritizes child
                safety in educational institutions.. From student check-ins to
                leave management, we've got you covered.
              </p>
            </div>
            <div className="sec2-solutionlist">
              <img src={bullet} alt="" />
              <p>
                ZedTap enhances child safety by providing real-time attendance
                updates, bridging the gap between parents and teachers.
                Strengthen the parent-teacher partnership with ZedTap to jointly
                ensure the well-being of your children.
              </p>
            </div>
            <div className="sec2-solutionlist">
              <img src={bullet} alt="" />
              <p>
                Make informed decisions with ZedTap's intelligent analytics.
                Identify attendance patterns, predict trends, and implement
                strategies to boost student engagement and academic success.
              </p>
            </div>
          </div>
        </div>

        <div className="carousel-container">
          <div className="carousel-card">
            <div className="green-corner"></div>
            <p className="carousel-head">User-Friendly Interface</p>
            <p className="carousel-des">
              Simplicity at your fingertips. ZedTap's user-friendly interface
              makes attendance management effortless, ensuring that even those
              unfamiliar with the technology can easily navigate the system
              while maintaining child safety as a top priority.
            </p>
            <img className="line194" src={line194} alt="" />
          </div>

          <div className="carousel-card">
            <div className="green-corner"></div>
            <p className="carousel-head">Secure Access Control</p>
            <p className="carousel-des">
              Ensure that only authorized individuals have access to your
              institution, providing an extra layer of protection for your
              children. With ZedTap, you can have peace of mind, knowing that
              only trusted individuals can enter the premises, strengthening the
              overall safety of your learning environment.
            </p>
            <img className="line194" src={line194} alt="" />
          </div>

          <div className="carousel-card">
            <div className="green-corner"></div>
            <p className="carousel-head">Streamlined Attendance Tracking</p>
            <p className="carousel-des">
              Say goodbye to manual attendance processes and welcome a new era
              of child safety. ZedTap's RFID card technology simplifies
              attendance tracking, reducing administrative burdens and
              maximizing instructional time and efficiency. Ensure a secure
              environment where every child's attendance is accurately recorded
            </p>
            <img className="line194" src={line194} alt="" />
          </div>

          <div className="carousel-card">
            <div className="green-corner"></div>
            <p className="carousel-head">Intelligent Analytics</p>
            <p className="carousel-des">
              Prioritize child safety with data-driven decisions. ZedTap's
              powerful analytics not only enhance student engagement and
              academic performance but also contribute to a safer learning
              environment. Gain valuable insights to identify patterns and
              trends that promote the well-being of every child.
            </p>
            <img className="line194" src={line194} alt="" />
          </div>

          <div className="carousel-card">
            <div className="green-corner"></div>
            <p className="carousel-head">Customizable Solutions</p>
            <p className="carousel-des">
              Customize ZedTap for your institution's unique requirements.
              Select from a variety of plans and features or create a
              personalized attendance management solution. Prioritize child
              safety by choosing features that ensure the security and
              well-being of your students, tailoring the system to your exact
              needs.
            </p>
            <img className="line194" src={line194} alt="" />
          </div>
  
        </div>
      </section>

      <section className="ring-sec-container">
        <img className="ringGradient" src={ringGradient} alt="" />
        <div className="ring-section">
          <p>
            ZedTap <span>'Ring' :</span>
          </p>
          <p>Your Gateway to Effortless Attendance Management</p>
          <img className="ring-img" src={ring} alt="" />
          {/* <Spline scene="https://prod.spline.design/ml6hF7DN3BmO8f9Z/scene.splinecode"/> */}
        </div>
        <div className="pricing-heading" id="pricing">
          <div className="pricing-line"></div>
          <div className="pricing-text">Pricing Plans</div>
          <div className="pricing-line"></div>
        </div>
      </section>

      <section className="pricing-section">
        <PricingCard data={BasicPlan} />
        <PricingCard data={ProPlan} />
        <PricingCard data={proPlusPlan} />
      </section>

      <section className="about-section" id="about">
        <div className="about-container">
          <div className="about-box">
            <p className="about-title">About Us</p>
            <p className="about-des">
              At ZedTap, we are driven by a passion for transforming the
              landscape of attendance management in educational institutions.
              Our innovative solutions bridge the gap between institutions,
              parents, and students, enabling seamless communication and
              efficient processes.
            </p>
          </div>
        </div>

        <div className="about2 about-margin">
          <div className="about2-left">
            <p className="about2-head">Our Vision</p>
            <p className="about2-des">
              We envision a future where attendance tracking is not just a
              routine task, but a critical element in ensuring child safety and
              educational excellence. Our goal is to empower institutions with
              advanced technology, data-driven insights, and user-friendly
              platforms that not only enhance engagement and improve efficiency
              but also prioritize child safety, contributing to the overall
              success and well-being of every student.
            </p>
          </div>
          <div className="about2-right">
            <img className="circleringSvg" src={circleringSvg} alt="" />
            <img
              className="circleRingGradient"
              src={circleRingGradient}
              alt=""
            />
          </div>
        </div>

        <div className="about2">
          <div className="about2-left">
            <p className="about2-head">Who We Are ?</p>
            <p className="about2-des">
              We're a dedicated team of technologists, educators, and innovators
              with a shared mission: revolutionizing education sector attendance
              management. Our deep understanding of institutional and parental
              challenges drives us to create solutions that streamline
              administration and promote collaboration, making educational
              processes more efficient and engaging for all stakeholders.
            </p>
          </div>
          <div className="about2-left">
            <p className="about2-head">What Set Us Apart?</p>
            <p className="about2-des">
              ZedTap is dedicated to impactful solutions. Our RFID-based
              attendance system, real-time notifications, and intelligent
              analytics offer a holistic attendance tracking approach.
              Personalized support and constant improvement underpin our
              commitment to exceptional service, ensuring our customers benefit
              from a comprehensive and continuously refined experience.
            </p>
          </div>
        </div>

        <div className="about-container">
          <div className="mission-box">
            <p className="about-title">Our Mission</p>
            <p className="about-des">
              Our mission is to empower educational institutions with
              cutting-edge technology that prioritizes child safety, streamlines
              attendance tracking, enhances communication, and enables better
              decision-making. We are dedicated to creating a positive impact on
              both institutions and parents, fostering a culture of
              transparency, accountability, and, above all, ensuring the highest
              standards of child safety and educational excellence.
            </p>
          </div>
        </div>

        <div className="area-container">
          <div className="mission-box">
            <p className="about-title">Areas of Service</p>
            <p className="about-des">
              ZedTap is dedicated to transforming attendance management for
              educational institutions across various sectors. Our services
              extend to:
            </p>
          </div>
        </div>

        <div className="curvyboxes-container">
            <CurvyBox
              order="1"
              title="SCHOOLS"
              content="ZedTap streamlines attendance tracking from primary to secondary schools, allowing teachers to prioritize student learning."
            />

            <CurvyBox
              order="2"
              title="Tuition Centers"
              content="ZedTap simplifies attendance management for tuition centers, offering comprehensive solutions for instructors and students."
            />

            <CurvyBox
              order="3"
              title="Colleges and Universities"
              content="ZedTap empowers higher education with efficient attendance management, fostering student engagement and academic success insights."
            />

            <CurvyBox
              order="4"
              title="Coaching Institutes"
              content="ZedTap aids coaching centers in efficient attendance tracking, enhancing communication among instructors, students, and parents, leading to better learning results."
            />
          </div>

      </section>

      <section className="join-section">
        <div className="join-sec-left">
          <p>Join the ZedTap Journey</p>
          <p>
            Experience the difference that ZedTap brings to attendance
            management. Join us on a journey towards a more efficient, engaging,
            and connected educational ecosystem. Together, we're shaping the
            future of attendance management for a brighter tomorrow.
          </p>
        </div>
        <div className="join-sec-right">
          <button>          <a href="https://forms.gle/p9BjBVRSfSEC8asq6" style={{color:'black'}}>JOIN NOW</a>
</button>
        </div>
      </section>

      <footer className="footer-section">
        <div>
          <img src={footerLogo} alt="" />
        </div>
        <div className="footer-middle">
          <div className="footer-head">Contact Us</div>
          <div className="footer-contact">
            <p>info@zedtap.com</p>
            <p>+91 8078382216</p>
          </div>
          {/* <div className="footer-list">
            <p>Home</p>
            <p>Product</p>
            <p>Pricing</p>
            <p>About Us</p>
            <p>Contact</p>
          </div> */}
          <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100095351350543">  <img src={facebook} alt="" /></a>
         <a href="https://twitter.com/zedtap"><img src={twitter} alt="" /></a>   
         <a href="https://instagram.com/__zedtap__?igshid=NGVhN2U2NjQ0Yg=="> <img src={instagram} alt="" /></a>  
         <a href="https://www.linkedin.com/showcase/zedtap/"><LinkedInIcon/></a>  

          </div>
          <div>© Copyright 2023 - Zedtap</div>
        </div>
        <div className="footer-right"></div>
      </footer>
    </div>
  );
};

export default GradientLandingPage;
