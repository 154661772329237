import React from "react";
import "./StudentCard.css";
import Studentsvg from "./assets/studentsvg.svg";
import { baseUrl } from "../../Services/apiCall";

const StudentCard = ({dashValues}) => {
  return (
    <div className="StudentCard">
      <div className="studentcard-left">
        <div className="svg-box">
          <img className="svgstud-img" 
          // src={baseUrl + "/student/svg_image?Image=studentsvg.svg"}
          src={Studentsvg}
           alt="" />
        </div>
        <div className="card-text">Total Students</div>
      </div>

      <div className="studentcard-right">
        {dashValues}
        </div>

      
    </div>
  );
};

export default StudentCard;
