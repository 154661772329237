import React from 'react'
import "./IconButton.css"

const IconButton = (
  {
              label,
              img
  }
) => {
  return (
   
    <div className='FilterBtn-container'>
        <img 
         // src={baseUrl + "/student/svg_image?Image=download.svg"}
        src={img} 
        alt="" />
        <p>{label}</p>
    </div>
   
  )
}

export default IconButton