import React from "react";
import "./style.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./assets/logo.png";
import apiCall from "../../Services/apiCall";
import { ResetPassword } from "../../Services/apiServices";
import ResetSuccessComponent from "../ResetSuccessComponent/ResetSuccessComponent";

function ResetComponent({setReset}) {
  const [passwords, setPasswords] = useState({
    firstPassword: "",
    retypePassword: "",
  });
  const[resetsuccess,setResetSuccess]= useState(false)
  const[check,setCheck]=useState('')

  const handleReset = async (e) => {
    e.preventDefault()
    const route = ResetPassword();
    const response = await apiCall(route, {
      Password: passwords.firstPassword?passwords.firstPassword:'',
      ConfirmPassword: passwords.retypePassword?passwords.retypePassword:"",
    });
    if(response.Status==='password_mismatch'){
      setCheck("Password mismatch")
    }else if(response.Status==='token_is_invalid'){
      setCheck("Token Expired")
    }else if(response.Status==='enter_a_new_password'){
      setCheck("Please enter a new password")
    }else if(response.Status==='success'){
      setResetSuccess(true)
    }
  };
  return (
    <div>
     {resetsuccess?<ResetSuccessComponent setReset={setReset}/>: <div className="loginpage-box">
      <div className="loginbox-inside">
        <img src={Logo} alt="" height={"20px"} />
      </div>
      <div className="loginbox-inside">
        <h3>Reset Password</h3>
      </div>
      <div className="loginbox-inside">
        <form className="login-main" onSubmit={handleReset}>
          <div className="optionslogin-main">
            <input
              className={passwords.firstPassword ? "active-input" : ""}
              type="password"
              name="firstpassword"
              autoComplete="off"
              id=""
              onChange={(e) => {
                setPasswords({ ...passwords, firstPassword: e.target.value });
              }}
              minLength={'8'}
              required
            />
            <label htmlFor="" className="login-label">
              Enter new password
            </label>
          </div>
          <div className="optionslogin-main">
            <input
              className={passwords.retypePassword ? "active-input" : ""}
              type="password"
              name="retypepassword"
              id=""
              autoComplete="off"
              onChange={(e) => {
                setPasswords({ ...passwords, retypePassword: e.target.value });
                setCheck('')
              }}
              minLength={'8'}
              required
            />
            <label htmlFor="" className="login-label">
              Confirm password
            </label>
          </div>

       
          <div className="optionslogin-main">
            <button type="submit" className="loginContainer-btn">
              Reset
            </button>
          </div>
        
          <div className='login-container-status'>
            {check}
            </div>

          <div className="optionslogin-main">
            <Link to={""} className="login-link">
              Go back
            </Link>
          </div>
        </form>
      </div>
      <div className="loginbox-inside"></div>
    </div>}
    </div>
  );
}

export default ResetComponent;
