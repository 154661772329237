import React, { useState } from "react";
import "./PaymentPopup.css";
import { AddFees, AdmnoStudFilter } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PaymentPopup = ({ setFeepopup, setPaySuccessPopup }) => {
  const navigate = useNavigate();
  let keys = localStorage.getItem("adminKey");

  const [admNo, setAdmNo] = useState("");
  const [studAdmnoFilt, setStudAdmnoFilt] = useState([]);
  const [payError, setpayError] = useState(true);
  const [addFeeStatus, setAddFeeStatus] = useState("");
  const [addFeeData, setAddFeeData] = useState({
    receiptNo: "",
    feeType: "",
    amount: "",
    paidDate: "",
    feemonth: "",
  });

  const addfeeCancelSubmit = () => {
    setFeepopup(false);
    setAddFeeStatus("");
    setAddFeeData("");
    setAdmNo("");
  };

  const handleFeeChange = (event) => {
    setAddFeeData({ ...addFeeData, feeType: event.target.value });
  };
  const admnoSubmit = async (e) => {
    const route = AdmnoStudFilter();
    const resp = await apiCall(route, { AdmNo: admNo ? keys + admNo : "" });
    if (resp.Status === "success") {
      setStudAdmnoFilt(resp?.Data);
      setpayError(false);
    } else if (resp.Status === ("no_data" || "student_deleted")) {
      setStudAdmnoFilt([]);
      setpayError(true);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };
  const addFeeSubmit = async (e) => {
    e.preventDefault();
    const route = AddFees();
    const resp = await apiCall(route, {
      ReceiptNo: addFeeData.receiptNo ? addFeeData.receiptNo : "",
      TypeOfFee: addFeeData.feeType ? addFeeData.feeType : "",
      Amount: addFeeData.amount ? addFeeData.amount : "",
      Month: addFeeData.feemonth ? addFeeData.feemonth : "",
      AdmNo: admNo ? keys + admNo : "",
      Date: addFeeData.paidDate ? addFeeData.paidDate : "",
    });
    if (resp.Status === "success") {
      setFeepopup(false);
      setPaySuccessPopup(true);
    } else if (resp.Status === "no_student") {
      setAddFeeStatus("no_student");
    } else if (resp.Status === "receipt_no_exist") {
      setAddFeeStatus("receipt_exists");
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };
  useEffect(() => {
    admnoSubmit();
  }, [admNo]);

  return (
    <div className="PaymentPopup-wrapper">
      <form className="PaymentPopup-container" onSubmit={addFeeSubmit}>
        <div className="PaymentPopup-top">
          <p>ADD PAYMENT DETAILS</p>
          <div></div>
        </div>

        <div className="PaymentPopup-middle">
          <div className="PaymentPopup-clsDiv-container">
            <div className="PaymentPopup-customWidth">
              <input
                // value={studPayId?  studPayId:admNo}
                placeholder="Admission No."
                className={
                  payError
                    ? "PaymentPopup-input error-text"
                    : "PaymentPopup-input"
                }
                onChange={(e) => setAdmNo(e.target.value)}
                required
                type="text"
              />
              {addFeeStatus === "no_student" ? (
                <p className="PaymentPopup-error">
                  enter a valid admission no. !
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="PaymentPopup-customWidth">
              <input
                placeholder="Receipt No."
                className="PaymentPopup-input"
                type="text"
                required
                onChange={(e) => {
                  setAddFeeData({ ...addFeeData, receiptNo: e.target.value });
                  setAddFeeStatus("");
                }}
              />
              {addFeeStatus === "receipt_exists" ? (
                <p className="PaymentPopup-error">
                  Receipt no. already exists !
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <input
              placeholder="Student name"
              className="PaymentPopup-input inp-pointer"
              type="text"
              value={admNo && studAdmnoFilt[0] ? studAdmnoFilt[0].Name : ""}
              required
              readOnly
            />
          </div>

          <div className="PaymentPopup-clsDiv-container">
            <input
              required
              type="text"
              placeholder="Class"
              className="PaymentPopup-input inp-pointer"
              name=""
              id=""
              value={admNo && studAdmnoFilt[0] ? studAdmnoFilt[0].Class : ""}
              readOnly
            />

            <input
              type="text"
              placeholder="Division"
              className="PaymentPopup-input inp-pointer"
              name=""
              id=""
              value={admNo && studAdmnoFilt[0] ? studAdmnoFilt[0].Division : ""}
              readOnly
            />
          </div>

          <div className="PaymentPopup-dropdown">
            <select
              value={addFeeData.feeType}
              onChange={handleFeeChange}
              style={{ color: addFeeData.feeType ? "#282F41" : "#7c7c7c" }}
              className="PaymentPopup-input feeselect"
              name=""
              required
            >
              <option className="nullfeetype" value="">
                Type of Fees
              </option>
            {studAdmnoFilt[0]?.TypeOfFee?.map((item,index)=>{
              return(
                <option value={item?.value} key={index}>{item?.Type}</option>
              )
            })}
            </select>
          </div>

          <div>
            <input
              placeholder="Admission No."
              className="PaymentPopup-input"
              onChange={(e) =>
                setAddFeeData({ ...addFeeData, feemonth: e.target.value })
              }
              type="month"
            />
            <p className="PaymentPopup-monthtxt">
              select the month for which you want to make the payment
            </p>
          </div>

          <div className="PaymentPopup-clsDiv-container">
            <input
              placeholder="Amount (₹)"
              className="PaymentPopup-input"
              type="number"
              onChange={(e) =>
                setAddFeeData({ ...addFeeData, amount: e.target.value })
              }
              required
            />
            <div className="paiddate-picker">
              <input
                type="date"
                className="PaymentPopup-input"
                onChange={(e) =>
                  setAddFeeData({ ...addFeeData, paidDate: e.target.value })
                }
                required
              />
              <p className="paid-date">(paid date)</p>
            </div>
          </div>
        </div>

        <div className="PaymentPopup-bottom">
          <div onClick={addfeeCancelSubmit} className="PaymentPopup-cancelbtn">
            Cancel
          </div>
          <button type="submit" className="PaymentPopup-savebtn">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentPopup;
