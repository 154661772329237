import React, { useLayoutEffect } from "react";
import "./StudentDashboardPage.css";
import StudentHeaderSection from "../../components/StudentHeaderSection/StudentHeaderSection";
import StudDashboardCard from "../../components/StudDashboardCard/StudDashboardCard";
import NoticeComponent from "../../components/NoticeComponent/NoticeComponent";
import IconButton from "../../components/IconButton/IconButton";
import dwnld from "./assets/dwnld.svg";
import TabTimetablenew from "../../components/TabTimetablenew/TabTimetablenew";
import TimetableBox from "../TimetableBox/TimetableBox";
import apiCall from "../../Services/apiCall";
import {
  IndividualStudentAttendance,
  StudPaymentsHistoryRoute,
  studLoginProfileRoute,
  studwebAttendanceHistoryRoute,
  studwebNoticeContentView,
  studwebPayHistoryRoute,
  viewNotice,
} from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ViewNoticePopup from "../../components/ViewNoticePopup/ViewNoticePopup";
import UnavailablePopup from "../../components/UnavailablePopup/UnavailablePopup";
import StudentTimetable from "../StudentTimetable/StudentTimetable";
import BasicTabs from "./BasicTabs";
import { Box, Grid, Paper } from "@mui/material";

const StudentDashboardPage = () => {
  const navigate = useNavigate();
  const studId = localStorage.getItem("StudAdmNo");
  const [studNoticeId, setStudNoticeId] = useState("");
  const [studSingleNoticeData, setStudSingleNoticeData] = useState([]);
  const [studNoticePopup, setStudNoticePopup] = useState(false);
  const [studwebTimetablePopup, setstudwebTimetablePopup] = useState(false);
  const [studNavbarData, setstudNavbarData] = useState({
    name: "",
    email: "",
    image: "",
  });
  const [attendanceData,setAttendanceData] = useState([])
  const [paymentData,setPaymentData] = useState([])

  const studViewNoticeSubmit = async () => {
    const route = studwebNoticeContentView();
    const resp = await apiCall(route, {
      Id: studNoticeId ? studNoticeId : "",
    });
    if (resp.Status === "success") {
      setStudSingleNoticeData(resp?.Data);
    } else if (resp.Status === "no_data") {
      setStudSingleNoticeData([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  };
  const studLoginDetails = async () => {
    const route = studLoginProfileRoute();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setstudNavbarData({
        ...studNavbarData,
        name: resp.Data[0]?.Name,
        image: resp.Data[0]?.Image,
        email: resp.Data[0]?.Email,
      });
    } else if (resp.Status === "no_data") {
      setstudNavbarData([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  };
  const AttendanceHistory = async () => {
    const route = studwebAttendanceHistoryRoute();
    const response = await apiCall(
      route,
      {
        Month: "",
      },
      false
    );
    if (response.Status === "success") {
      setAttendanceData(response?.Data)
    } else if (response.Status === "no_data") {
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const studpayHistorySubmit=async()=>{
    const route = studwebPayHistoryRoute();
    const resp = await apiCall(route,{
        Month:""
    },false,"POST");
    if(resp.Status==="success"){
      setPaymentData(resp?.Data)
    }
    else if(resp.Status==="no_data"){

      }
      else if(resp.Status==="token_is_invalid"){
        navigate("/login");
      }
}

  useEffect(() => {
    AttendanceHistory();
    studpayHistorySubmit()
    // studwebViewNoticeRoute()
  }, []);

  useEffect(() => {
    studViewNoticeSubmit();
    studpayHistorySubmit()
    studLoginDetails();
  }, [studNoticeId]);

  return (
    <>
      <div className="StudentDashboardPage-container">
        {studNoticePopup && studSingleNoticeData ? (
          <div className="feepopup-div">
            <ViewNoticePopup
              noticeData={studSingleNoticeData}
              setNoticeData={setStudSingleNoticeData}
              setNoticePopup={setStudNoticePopup}
            />
          </div>
        ) : (
          ""
        )}

        {studwebTimetablePopup ? (
          <div className="feepopup-div">
            <UnavailablePopup setUnavailablePopup={setstudwebTimetablePopup} />
          </div>
        ) : (
          ""
        )}

        <StudentHeaderSection
          type="home"
          studLoginProfileInfo={studNavbarData}
        />

        <div className="StudentDashboardPage-header">
          <StudDashboardCard type="attendance" />
          <StudDashboardCard type="ongoing" />
          <NoticeComponent
            type="student"
            setNoticeId={setStudNoticeId}
            setNoticePopup={setStudNoticePopup}
          />
        </div>
      </div>
      <Grid container>
        <Grid item sx={{ m: 2, width: "100%" }}>
          <Paper elevation={1}>
            <BasicTabs attendanceData={attendanceData} paymentData={paymentData}/>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentDashboardPage;
