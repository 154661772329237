import React from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./AdminProfile.css";
import apiCall from "../../Services/apiCall";
import { useState, useEffect } from "react";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import { useNavigate } from "react-router-dom";
import ChangePasswordPopup from "../../components/ChangePasswordPopup/ChangePasswordPopup";
import { useStoreActions } from "easy-peasy";
import ProfileBar from "../../components/ProfileBar/ProfileBar";
import {
  AcademyImgUploadRoute,
  AcademyUpdate,
  AcademyView,
} from "../../Services/apiServices";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import ImgUploadPopup from "../../components/ImgUploadPopup/ImgUploadPopup";

const AdminProfile = ({
  academyData,
  headerAdminDp,
  setAdminUpdate,
  adminUpdate,
  section,
}) => {
  const [adminPasswordPop, setAdminPasswordPop] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);

  const [academyImgChangePopup, setAcademyImgChangePopup] = useState(false);
  const [academyChangeImgfile, setAcademyChangeImgfile] = useState("");

  const [academyDetails, setAcademyDetails] = useState({
    academyName: "",
    email: "",
    address: "",
    phone_no: "",
    image: "",
  });
  const [academyUpdtSuccess, setAcademyUpdtSuccess] = useState(false);

  const addClass = useStoreActions((action) => action.addClassData);
  let navigate = useNavigate();

  const academyProfileView = async () => {
    const route = AcademyView();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setAcademyDetails({
        ...academyDetails,
        academyName: resp.Data.name,
        email: resp.Data.email,
        address: resp.Data.address,
        phone: resp.Data.phone_no,
        image: resp.Data.image,
      });
    } else if (resp.Status === "no_data") {
      setAcademyDetails({
        academyName: "",
        email: "",
        address: "",
        phone: "",
        image: "",
      });
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const logoutSubmit = () => {
    localStorage.clear();
    addClass([]);
    navigate("/");
  };

  

  const addAcademyImgSubmit=async()=>{
    const route = AcademyImgUploadRoute();
    const resp = await apiCall(route,{
      Image:academyChangeImgfile?academyChangeImgfile:""
    },true);
    if(resp.Status==="success"){    
      setAcademyDetails({...academyDetails,image:resp.Image});
      setAcademyImgChangePopup(false)
    }
    else if(resp.status==='token_is_invalid'){
         navigate('/login')
        }
  }

  const updateAcademySubmit = async () => {
    const route = AcademyUpdate();
    const resp = await apiCall(route, {
      PhoneNo: academyDetails.phone ? academyDetails.phone : "",
      Image: academyDetails.image ? academyDetails.image : "",
      Address: academyDetails.address ? academyDetails.address : "",
    });

    if (resp.Status === "success") {
      setAcademyImgChangePopup(false);
      setAdminUpdate(true);
      setAcademyUpdtSuccess(true);
    }
  };

  useEffect(() => {
    academyProfileView();
  }, [academyUpdtSuccess]);

  return (
    <div className="adminprofile-container">
      {logoutPopup ? (
        <div className="logout-popup">
          <DeleteWarnPopup
            type="logout"
            logoutSubmit={logoutSubmit}
            setWarning={setLogoutPopup}
          />
        </div>
      ) : (
        ""
      )}

      {academyUpdtSuccess ? (
        <div className="logout-popup">
          <SuccessFailPopup
            type="success"
            text="Profile Updated"
            setclosePopup={setAcademyUpdtSuccess}
          />
        </div>
      ) : (
        ""
      )}

      {academyImgChangePopup ? (
        <div className="logout-popup">
         
          <ImgUploadPopup 
         setImgToBackend={setAcademyChangeImgfile} 
         setChangeImgPop={setAcademyImgChangePopup}
         updateImgSubmit={addAcademyImgSubmit}
         />
        </div>
      ) : (
        ""
      )}

      {adminPasswordPop ? (
        <div className="adminpass-popup">
          <ChangePasswordPopup setAdminPasswordPop={setAdminPasswordPop} />
        </div>
      ) : (
        ""
      )}

<div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

      <div>
        <HeaderSection
          section={section}
          academyData={academyData}
          headerAdminDp={headerAdminDp}
        />
      </div>

      <div className="admin-profilebar-container">
        <ProfileBar
          type="academy"
          setLogoutPopup={setLogoutPopup}
          setAcademyDetails={setAcademyDetails}
          academyDetails={academyDetails}
          updateAcademySubmit={updateAcademySubmit}
          setAcademyImgChangePopup={setAcademyImgChangePopup}
        />
      </div>
    </div>
  );
};

export default AdminProfile;
