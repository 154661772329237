import React, { useState } from "react";
import "./AcademicsComponent.css";
import TableHeader from "../TableHeader/TableHeader";
import ClassListTable from "../ClassListTable/ClassListTable";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import IconButton from "../IconButton/IconButton";
import filter from "./assets/filter.svg";
import dwnld from "./assets/dwnld.svg";
import add from "./assets/add.svg";
import TimetableBox from "../../containers/TimetableBox/TimetableBox";
import TabTimetablenew from "../TabTimetablenew/TabTimetablenew";
import ClassDisplayBox from "../ClassDisplayBox/ClassDisplayBox";
import AddTimeSlotPopup from "../AddTimeSlotPopup/AddTimeSlotPopup";
import { useEffect } from "react";
import { ViewTimeSlot } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useNavigate } from "react-router-dom";
import AddSubjectPopup from "../AddSubjectPopup/AddSubjectPopup";
import AddPeriodPopup from "../AddPeriodPopup/AddPeriodPopup";

const AcademicsComponent = ({
  setAcademicDwnldPop,
  setAddCoursePopup,
  setAddTimetablePopup,
  timetableAddData,
  academicsClsData,
  setaddSubjectPopup,
  academyFiltDrop, 
  setAcademyFiltDrop
  
}) => {
  const [headerType, setheaderType] = useState("CLASS");
  const [timetableFilterClass, setTimetableFilterClass] = useState("Class");
const [timetableFilterDivision, settimetableFilterDivision] =useState("Division");
 
  const [addTimeslot, setAddTimeslot] = useState(false);
  const [slotData, setSlotData] = useState([]);
  const navigate = useNavigate();
  const [classtotimeslot, setClasstotimeslot] = useState({
    class: "",
    division: "",
    starttime: "",
    endtime: "",
  });
  const [addPeriodPopup, setAddPeriodPopup] = useState(false)
  const [periodAddData, setPeriodAddData] = useState({
    timeslotId:"",
    dayId:""
  })


  const ViewTimeSlotApi = async () => {
    const route = ViewTimeSlot();
    const response = await apiCall(route, {
      Class: timetableFilterClass==="Class"?"": timetableFilterClass,
      Division: timetableFilterDivision==="Division"?"":timetableFilterDivision,
    });
    if (response.Status === "success") {
      setSlotData(response?.Data);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  useEffect(() => {
    setClasstotimeslot({
      ...classtotimeslot,
      class: timetableAddData.class,
      division: timetableAddData.division,
    });
  }, [timetableAddData]);
  
  useEffect(() => {
    setAcademyFiltDrop(false)
  }, [headerType])
  

  useEffect(()=>{
    ViewTimeSlotApi()
  },[timetableFilterDivision,timetableFilterClass,addTimeslot])

  return (
    <div className="Academics-container">
       {addPeriodPopup && (
              <div className="warning-div">
               <AddPeriodPopup
               timetableFilterDivision={timetableFilterDivision}
               timetableFilterClass={timetableFilterClass}
                setAddPeriodPopup={setAddPeriodPopup}
                periodAddData={periodAddData}
                />
              </div>
            )}
      <div className="Academics-top">
        <div className="Academics-top-left">
          <div>
            <TableHeader
              header1="CLASS"
              header2="TIMETABLE"
              setheaderType={setheaderType}
            />
          </div>
          {headerType === "TIMETABLE" ? (
            // <div className="Academics-filtShow">10 A</div>
            <div style={{ marginRight: "20px" }}>
              <ClassDisplayBox
                std={
                  timetableFilterClass === "Class"
                    ? "No class selected"
                    : timetableFilterClass
                }
                division={
                  timetableFilterDivision === "Division"
                    ? ""
                    : timetableFilterDivision
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {headerType === "CLASS" ? (
          <div className="Academics-top-right">
             <div onClick={()=>setaddSubjectPopup(true)}>
              <IconButton label="Add Subject" img={add} />
            </div>
            <div onClick={() => setAddCoursePopup(true)}>
              <IconButton label="Add Course" img={add} />
            </div>
           
            {/* <div onClick={() => setAcademicDwnldPop(true)}>
              <IconButton label="Download" img={dwnld} />
            </div> */}
          </div>
        ) : headerType === "TIMETABLE" ? (
          <div className="Academics-top-right">
            <div onClick={() => setAddTimetablePopup(true)}>
              <IconButton label="Add Timetable" img={add} />
            </div>
            <div onClick={() => setAcademicDwnldPop(true)}>
              <IconButton label="Download" img={dwnld} />
            </div>
            <div onClick={() => setAcademyFiltDrop(!academyFiltDrop)}>
              <IconButton label="Filter" img={filter} />
            </div>
          </div>
        ) : (
          ""
        )}

        {academyFiltDrop ? (
          <div className="Academics-filter-drop">
            <FilterDropdown
              cls={timetableFilterClass}
              setCls={setTimetableFilterClass}
              div={timetableFilterDivision}
              setDiv={settimetableFilterDivision}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="Academics-bottom">
        {headerType === "CLASS" ? (
          <ClassListTable academicsClsData={academicsClsData} />
        ) : headerType === "TIMETABLE" ? (
          <>
            <div className="Academics-timetable-div">
              {/* <TimetableNew /> */}
              <TimetableBox
                timetableFilterClass={timetableFilterClass}
                timetableFilterDivision={timetableFilterDivision}
                setAddTimeslot={setAddTimeslot}
                classtotimeslot={classtotimeslot}
                slotData={slotData}
                setAddPeriodPopup={setAddPeriodPopup}
                periodAddData={periodAddData}
                  setPeriodAddData={setPeriodAddData}
                  addPeriodPopup={addPeriodPopup}
              />
            </div>
            {addTimeslot && (
              <div className="warning-div">
                <AddTimeSlotPopup
                  classtotimeslot={classtotimeslot}
                  setClasstotimeslot={setClasstotimeslot}
                  addTimeslot={addTimeslot}
                  setAddTimeslot={setAddTimeslot}
                  timetableFilterClass={timetableFilterClass}
                  timetableFilterDivision={timetableFilterDivision}
                />
              </div>
            )}
            <div className="tabTimetable-div">
              <TabTimetablenew />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AcademicsComponent;
