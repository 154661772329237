import React, { useEffect } from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./Profiles.css";
import { useState } from "react";
import ProfilesComponent from "../../components/ProfilesComponent/ProfilesComponent";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import MobileTableHeader from "../../components/MobileTableHeader/MobileTableHeader";
import IconButton from "../../components/IconButton/IconButton";
import filtericon from "./assets/filter.svg";
import downloadicon from "./assets/dwnld.svg";
import addstudicon from "./assets/addstud.svg";
import ListCard from "../../components/ListCard/ListCard";
import apiCall from "../../Services/apiCall";
import { AllStudents, AllTeachers, StudFilter } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";
import AddStudentForm from "../../components/AddStudentForm/AddStudentForm";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import AddTeacherForm from "../../components/AddTeacherForm/AddTeacherForm";
import IndividualMsgPopup from "../../components/IndividualMsgPopup/IndividualMsgPopup";
import UnavailablePopup from "../../components/UnavailablePopup/UnavailablePopup";
import MarkAttendancePopup from "../../components/MarkAttendancePopup/MarkAttendancePopup";
import { ToastContainer } from "react-toastify";

const Profiles = ({ section }) => {
  // profiles page

  const [studentPopup, setStudentPopup] = useState(false);
  const [teacherPopup, setTeacherPopup] = useState(false)
const [addstudSuccess,setAddstudSuccess]=useState(false);
  const [profilesDwnldpop, setprofilesDwnldpop] = useState(false);
  const [profileSelect, setProfileSelect] = useState("Student List");
  const [studentList, setStudentList] = useState([]);
  const [teachersList,setTeachersList] = useState([]);
  const [proClass, setProClass] = useState("Class");
  const [proDiv, setProDiv] = useState("Division");
  const [teacherAddSuccess, setTeacherAddSuccess] = useState(false)
  const [dwnldFilename, setDwnldFilename] = useState({
    teachersFilename:"",
    studentsFilename:""
  })
  const [studprofilesDwnldpop, setStudprofilesDwnldpop] = useState(false)
  const [individualMsg, setIndividualMsg] = useState(false)
  const [profilesFiltDrop, setprofilesFiltDrop] = useState(false);
  const [msgPopupDataPass, setMsgPopupDataPass] = useState({
    admno:"",
    name:""
  })
  const [msgSentSuccessPopup, setMsgSentSuccessPopup] = useState(false);
  const [manualAttendancePopup, setManualAttendancePopup] = useState(false);
  const[loading,setLoading] = useState(false)

  const navigate = useNavigate();


  

  const Allteacherslist =async()=>{
    setLoading(true)
    const route = AllTeachers();
    const response = await apiCall(route,{},false,"PUT")
    if(response.Status==='success'){
      setTeachersList(response.Data);
      setLoading(false)
     }else if(response.Status==='no_data'){
      setTeachersList([])
      setLoading(false)
     }else if(response.Status==='token_is_invalid'){
      navigate('/login')
     }
  }

  const teacherlistDownload=async()=>{
    setLoading(true)
    const route = AllTeachers();
    const resp = await apiCall(route,{});
    if(resp.Status==="success"){
      setDwnldFilename({...dwnldFilename,teachersFilename:resp?.FileName})
      setLoading(false)
    }
    else if(resp.Status==='no_data'){
      setDwnldFilename({...dwnldFilename,teachersFilename:""})
      setLoading(false)

     }else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }


  }

  

  const StudFiltSubmit=async()=>{
    setLoading(true)
    const route = StudFilter();
    const resp = await apiCall(route,{
      Class:proClass==="Class"?"":proClass,
      Division:proDiv==="Division"?"":proDiv
    },false,"PUT");
    if(resp.Status==="success"){
      setLoading(false)
      setStudentList(resp.Data)
    }
    else if(resp.Status==='no_data'){
      setLoading(false)
      setStudentList([]);
     }else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }

  }

  const StudentsListDownload=async()=>{
    const route = StudFilter();
    const resp = await apiCall(route,{
      Class:proClass==="Class"?"":proClass,
      Division:proDiv==="Division"?"":proDiv
    });
    if(resp.Status==="success"){
      setDwnldFilename({...dwnldFilename,studentsFilename:resp?.FileName})
    }
    else if(resp.Status==='no_data'){
      setDwnldFilename({...dwnldFilename,studentsFilename:""})
     }else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }

  }





  useEffect(()=>{
    Allteacherslist()
  },[teacherAddSuccess])

  useEffect(() => {
    StudFiltSubmit()
  }, [proClass,proDiv,addstudSuccess])
  

  return (
    <div>
      {profilesDwnldpop ? (
        <div className="nodata-today-div">
          <UnavailablePopup setUnavailablePopup={setprofilesDwnldpop}/>
          {/* <DeleteWarnPopup type="download" setWarning={setprofilesDwnldpop} dwnldFilename={dwnldFilename.teachersFilename} /> */}
        </div>
      ) : (
        ""
      )}

      {individualMsg?(
        <div className="nodata-today-div">
          <IndividualMsgPopup
          setIndividualMsg={setIndividualMsg}
          msgPopupDataPass={msgPopupDataPass}
          setMsgSentSuccessPopup={setMsgSentSuccessPopup}
          />
      </div>
    ) : (
      ""
    )}

{manualAttendancePopup?(
        <div className="nodata-today-div">
         <MarkAttendancePopup
         setMarkAttendPopup={setManualAttendancePopup}
         userid={msgPopupDataPass.admno}
         text={msgPopupDataPass.name}
         />
      </div>
    ) : (
      ""
    )}

{studprofilesDwnldpop ? (
        <div className="nodata-today-div">
          <DeleteWarnPopup type="download" setWarning={setStudprofilesDwnldpop} dwnldFilename={dwnldFilename.studentsFilename} />
        </div>
      ) : (
        ""
      )}

{addstudSuccess ? (
        <div className="nodata-today-div">
          <SuccessFailPopup type="success" text="Student Added" setclosePopup={setAddstudSuccess}/>
        </div>
      ) : (
        ""
      )}

{msgSentSuccessPopup ? (
        <div className="nodata-today-div">
          <SuccessFailPopup type="success" text="Message Sent" setclosePopup={setMsgSentSuccessPopup}/>
        </div>
      ) : (
        ""
      )}


{teacherAddSuccess ? (
        <div className="nodata-today-div">
          <SuccessFailPopup type="success" text="Teacher Added" setclosePopup={setTeacherAddSuccess}/>
        </div>
      ) : (
        ""
      )}

      {studentPopup ? (
        <div className="nodata-today-div">
          <AddStudentForm
           setStudentPopup={setStudentPopup}
           setAddstudSuccess={setAddstudSuccess}
           />
        </div>
      ) : (
        ""
      )}

{teacherPopup ? (
        <div className="nodata-today-div">
          <AddTeacherForm 
          setTeacherAddSuccess={setTeacherAddSuccess}
          setTeacherPopup={setTeacherPopup}/>
        </div>
      ) : (
        ""
      )}

     

      <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>
      <HeaderSection section="profiles" />
      <div className="academics-component-div">

        <ProfilesComponent
        setTeacherPopup={setTeacherPopup}
        setStudentPopup={setStudentPopup}
         setprofilesDwnldpop={setprofilesDwnldpop}
          dataList={studentList}
          teachersList={teachersList}
          proClass={proClass}
           setProClass={setProClass}
           proDiv={proDiv}
            setProDiv={setProDiv}
            teacherlistDownload={teacherlistDownload}
            StudentsListDownload={StudentsListDownload}
            setStudprofilesDwnldpop={setStudprofilesDwnldpop}
            setIndividualMsg={setIndividualMsg}
            msgPopupDataPass={msgPopupDataPass}
            setMsgPopupDataPass={setMsgPopupDataPass}
            setprofilesFiltDrop={setprofilesFiltDrop}
            profilesFiltDrop={profilesFiltDrop}
            setManualAttendancePopup={setManualAttendancePopup}
            loading={loading}
          />
      </div>

      <div className="profilesMobile">
        <div className="profilesMobile-title">
          <p>PROFILES</p>
        </div>

        <div className="profilesMobile-head">
          <MobileTableHeader
            clicked={profileSelect}
            setClicked={setProfileSelect}
            header1="Student List"
            header2="Teacher List"
          />
        </div>

        {profileSelect === "Student List" ?
        <div className="profilesMobile-btns">
          <div onClick={()=>setStudentPopup(true)}><IconButton img={addstudicon} /></div>
          <div onClick={() =>{setStudprofilesDwnldpop(true);StudentsListDownload();}}><IconButton img={downloadicon} /></div>
          <div onClick={() => setprofilesFiltDrop(!profilesFiltDrop)}><IconButton img={filtericon} /></div>
        </div>
        :
        profileSelect === "Teacher List"?
        <div className="profilesMobile-btns">
          <div onClick={()=>setTeacherPopup(true)}><IconButton img={addstudicon} /></div>
          <div onClick={() => {setprofilesDwnldpop(true);teacherlistDownload();}}><IconButton img={downloadicon} /></div>
        </div>
        :""}

        <div>
          {profileSelect === "Student List" ? (
            <div className="profilesMobile-bottom">
             {studentList?.map((student,index)=>{
              return(
                <ListCard type="student_list" 
                setIndividualMsg={setIndividualMsg}
                data={student} key={index}/>

              )
             })}
             
              
            </div>
          ) : profileSelect === "Teacher List" ? (
            <div className="profilesMobile-bottom">
              {teachersList?.map((teacher,index)=>{
              return(
                <ListCard type="teacher_list" data={teacher} key={index}/>

              )
             })}                         
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Profiles;
