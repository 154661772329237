import React from "react";
import "./MarkAttendancePopup.css";
import close from "./close.svg";
import InputIcon from "react-multi-date-picker/components/input_icon";
import error from "./error.svg";
import { markAttendanceRoute } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useState } from "react";
import DatePicker from "react-multi-date-picker";
import indian from "react-date-object/calendars/indian";
import indian_en from "react-date-object/locales/indian_en";
import { ToastContainer, toast } from "react-toastify";
const MarkAttendancePopup = ({ userid, setMarkAttendPopup, text }) => {
  const date = new Date();
  const emptySpace = " ";

  const [markAttendSection, setMarkAttendSection] = useState(0);
  const [timeStatus, setTimeStatus] = useState("Time");

  const [markAttendanceTime, setMarkAttendanceTime] = useState("");
  const [markAttendanceDate, setMarkAttendanceDate] = useState(new Date());
  console.log("here", markAttendanceDate);
  const markAttendanceSubmit = async (e) => {
    e.preventDefault();
    const route = markAttendanceRoute();
    const resp = await apiCall(route, {
      AdmNo: userid ? userid : "",
      Date: markAttendanceDate ? markAttendanceDate : "",
      Time: markAttendanceTime
        ? 
          markAttendanceTime+":00"
        : "",
    });

    if (resp.Status === "Not_Checked_in") {
      setMarkAttendSection(1);
      setTimeStatus("Check-in Time");
    } else if (resp.Status === "not_checked_out") {
      setMarkAttendSection(1);
      setTimeStatus("Check-out Time");
    } else if (resp.Status === "successfully_checked_in") {
      toast.success("Successfully Checked In", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMarkAttendPopup(false);
    } else if (resp.Status === "successfully_checked_out") {
      toast.error("Successfully checked out", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMarkAttendPopup(false);}
      else if(resp.Status==="cannot_use_the_given_time"){
        toast.warn("You cant mark attendence for now!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    
  };

  return (
    <div className="markAttendance-container">
      <ToastContainer/>
      <div className="markAttendance-box">
        <img
          onClick={() => {
            setMarkAttendPopup(false);
          }}
          className="markAttendance-close-svg"
          src={close}
          alt=""
        />

        <div className="markAttendance-title">
          MARK ATTENDANCE {text ? ` of ${text}` : ""}
        </div>

        {markAttendSection === 0 ? (
          <form className="markAttendance-wrap" onSubmit={markAttendanceSubmit}>
            <div className="markAttendance-content">
              <p>Date :</p>
              <DatePicker
              style={{padding:"10px",height:"40px"}}
              onChange={(selectedDate) => setMarkAttendanceDate(selectedDate.format("YYYY-MM-DD"))}
                placeholder="Select date"
                maxDate={date}
                selectedDate={markAttendanceDate}
                required
              />
            </div>

            <div className="markAttendance-btnbox">
              <button type="submit" className="markAttendance-btn">
                Next
              </button>
            </div>
          </form>
        ) : (
          ""
        )}

        {markAttendSection === 1 ? (
          <form className="markAttendance-wrap" onSubmit={markAttendanceSubmit}>
            <div className="markAttendance-content">
              <p>{timeStatus} :</p>
              <input
                className="markAttendance-date"
                type="time"
                onChange={(e) => setMarkAttendanceTime(e.target.value)}
                required
              />
            </div>

            <div className="markAttendance-btnbox">
              <button type="submit" className="markAttendance-btn">
                Mark
              </button>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MarkAttendancePopup;
