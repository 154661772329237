import React, { useState } from 'react'
import LeftTopSvg from './assets/lefttop.png'
import BottomRightSvg from './assets/bottomright.png'
import './LoginContainer.css'
import Logo from './assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import apiCall from '../../Services/apiCall'
import { LoginFunction } from '../../Services/apiServices'
import ResetComponent from '../../components/ResetComponent/ResetComponent'

function LoginContainer() {

    const [check, setCheck] = useState("");
    const[reset,setReset] = useState(false)

  const [loginData,setLoginData] = useState({
    email:'',
    password:''
  })

  const navigate = useNavigate();

  const passwordSubmit=(e)=>{
    setLoginData({...loginData,password:(e.target.value)});
    setCheck("")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const route = LoginFunction()
    const resp = await apiCall(route, { Email:loginData.email, Password:loginData.password });
    if (resp.Status === "first_login") {

      localStorage.setItem("adminKey", resp.Key)
      localStorage.setItem("token", JSON.stringify(resp.Token));
      setReset(true)
      
    } else if (resp.Status === "success") {      
      localStorage.setItem("adminKey", resp.Key)
      localStorage.setItem("token", JSON.stringify(resp.Token));
      navigate("/dashboard");
    } else if(resp.Status==="incorrect_password"){
      setCheck("incorrect email or password !");
     
    }
    else if(resp.Status==="not_a_valid_email"){
      setCheck("incorrect email or password !");
    }
    else if(resp.Status==="no_academy"){
      setCheck("No Such Academy");
    }
    else if(resp.Status==="academy_deleted"){
      setCheck("No Such Academy");
    }
    else if(resp.Status==="not_active"){
      setCheck("Temporarily Suspended");
    }
 
  };



  return (
    <div className='loginpage-wrapper'>
      <img src={LeftTopSvg} alt="" className='loginpage-left-top' />
     {reset?<ResetComponent setReset={setReset}/>: <div className='loginpage-box'>
        <div className="loginbox-inside login-header">
        <Link to={'/'} className='login-link'> 
          <img  src={Logo} alt="" height={'20px'}/>
          </Link>
          <h3>Welcome to Zedtap</h3>
        </div>
        <div className="loginbox-inside">
          <h3>Academy Login</h3>
        </div>
        <div className="loginbox-inside">
          <form className="login-main" onSubmit={handleSubmit}>
            <div className="optionslogin-main">
              <input className={loginData.email?"active-input":""} type="email" name="email" autoComplete="off" id="" onChange={(e)=>{setLoginData({...loginData,email:(e.target.value)});setCheck("")}} required/>
              <label htmlFor="" className='login-label'>Email address</label>

            </div>
            <div className="optionslogin-main">
              <input className={loginData.password?"active-input":""}  type="Password" name="password" id="" autoComplete='off' onChange={passwordSubmit} required/>
              <label htmlFor="" className='login-label'>Password</label>
            </div>
         
            <div className="optionslogin-main">
              <button type='submit' className='loginContainer-btn'>Login</button>
            </div>

            <div className='login-container-status'>
            {check}
            </div>

            <div className="optionslogin-main">
             <Link to={'/forgetpassword'} className='login-link'> Forget Password?</Link>
             <Link style={{marginTop:"20px"}} to={'/studentLogin'} className='login-link'>Login as Parent</Link>
            </div>
          </form>
        </div>
        <div className="loginbox-inside"></div>
      </div>}
      <img src={BottomRightSvg} alt="" className='loginpage-right-top' />
    </div>
  )
}

export default LoginContainer