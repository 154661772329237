import React from 'react'
import "./ClassDisplayBox.css"

const ClassDisplayBox = ({std, division}) => {
  return (
    <div className='ClassDisplayBox-container'>
        <p>{std}</p>
        <p>{division?division:""}</p>
    </div>
  )
}

export default ClassDisplayBox