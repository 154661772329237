import React from 'react'
import { useState } from 'react'
import { useLayoutEffect } from 'react'
import { useEffect } from 'react'
import apiCall from '../../Services/apiCall'
import DocumentComponent from '../DocumentComponent/DocumentComponent'
import "./ViewDocumentPopup.css"
import close from "./close.svg"
import NewDocument from '../NewDocument/NewDocument'
import UploadDocumentPopup from '../UploadDocumentPopup/UploadDocumentPopup'
import { DocsViewRoute, StudwebDocListRoute } from '../../Services/apiServices'
import { useNavigate } from 'react-router-dom'

const ViewDocumentPopup = ({setDocumentPopup,userid,setaddnewDocPopup,type}) => {

  const [documentData, setDocumentData] = useState([])

  const navigate = useNavigate()

const DocList = async()=>{
  const route = DocsViewRoute();
  const resp = await apiCall(route,{
    AdmNo: userid?userid:""
  })

  if(resp.Status==="success"){
    setDocumentData(resp.Data)
  }
   else if(resp.Status==="no_data"){
    setDocumentData([])
  }
  else if(resp.Status==='token_is_invalid'){
    navigate('/login')
   }

}



const StudwebDocList = async()=>{
  const route = StudwebDocListRoute();
  const resp = await apiCall(route,{})

  if(resp.Status==="success"){
    setDocumentData(resp.Data)
  }
   else if(resp.Status==="no_data"){
    setDocumentData([])
  }
  else if(resp.Status==='token_is_invalid'){
    navigate('/studentLogin')
   }

}

useLayoutEffect(() => {
  if(type==="studentWeb"){
    StudwebDocList();
  }else{
    DocList();
  }
  
}, [])


  return (
    <div className='view-document-wrapper'>



        <div className="view-document-container">

          <img className='close-doc' src={close} alt="" onClick={()=>setDocumentPopup(false)}/>

<div className="view-document-top">
  Documents
</div>

<div className="documents-box">

  {documentData?.map((docdata,index)=>{
    return(
      <div  key={index}> <DocumentComponent  docdata={docdata}/></div>
    )
  })}

{type==="studentWeb"?
"":
  <div onClick={()=>{setDocumentPopup(false);setaddnewDocPopup(true)}}><NewDocument/></div>
}

</div>





        </div>
        
    </div>
  )
}

export default ViewDocumentPopup