import React from 'react'
import "./UnavailablePopup.css"

const UnavailablePopup = ({setUnavailablePopup}) => {
  return (
    <div className='unavailable-container'>
        <div className="unavailable-box">
            <div>Currently Unavailable !</div>
            <div className='unavailable-btn' onClick={()=>setUnavailablePopup(false)}>Ok</div>
        </div>
    </div>
  )
}

export default UnavailablePopup