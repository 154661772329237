import React from "react";
import LeftTopSvg from "./assets/lefttop.png";
import BottomRightSvg from "./assets/bottomright.png";
import Logo from "./assets/logo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Otppopup from "./Otppopup";
import "./style.css";
import apiCall from "../../Services/apiCall";
import { ForgotChangePassword, SendMailForVerification } from "../../Services/apiServices";
import { Circles } from "react-loader-spinner";

function ForgetPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const[password,Setpassword] = useState("")
  const[confirm,setConfirm] = useState("")
  const [loading, setLoadind] = useState(false);
  const [popup, setPopup] = useState(false);
  const[otpVerify,setOtpVerify] = useState("")
  const SendEmail = async (e) => {
    e.preventDefault();
    setLoadind(true);
    const route = SendMailForVerification();
    const response = await apiCall(route, { Email: email ? email : "" });
    if (response.status === "send_mail") {
      setLoadind(false);
      setPopup(true);
    }
  };

  const SendPasswordForget =async(e)=>{
    e.preventDefault()
    setLoadind(true);
    const route = ForgotChangePassword();
    const response = await apiCall(route, { Email: email ? email : "",Password:password?password:"",ConfirmPassword:confirm?password:"" });
    if (response.Status === "success") {
      setLoadind(false);
      navigate('/login')
    }


  }
  return (
    <>
      <div className="loader">
        {loading && (
          <div className="PaySuccess-wrapper">
            <Circles
              height="80"
              width="80"
              color="#00CAA6"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div className="popupopen">
        {popup && <Otppopup setPopup={setPopup} email={email} setOtpVerify={setOtpVerify}/>}
      </div>
      <div className="loginpage-wrapper">
        <img src={LeftTopSvg} alt="" className="loginpage-left-top" />
        <div className="loginpage-box">
          <div className="loginbox-inside">
            <img src={Logo} alt="" height={"20px"} />
          </div>
          <div className="loginbox-inside">
            <h3>Forget Password ?</h3>
          </div>
          <div className="loginbox-inside">
            <form className="login-main" onSubmit={SendEmail}>
              <div className="optionslogin-main">
                <input
                  className={email ? "active-input" : ""}
                  type="email"
                  name="email"
                  autoComplete="off"
                  id=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
                <label htmlFor="" className="login-label">
                  Email address
                </label>
              </div>

              {otpVerify === "otp_verified" &&<>
              <div className="optionslogin-main">
                <input
                  className={email ? "active-input" : ""}
                  type="password"
                  name="password"
                  autoComplete="off"
                  id=""
                  onChange={(e) => {
                    Setpassword(e.target.value)
                  }}
                required/>
                <label htmlFor="" className="login-label">
                  Password
                </label>
              </div>

              <div className="optionslogin-main">
                <input
                  className={email ? "active-input" : ""}
                  type="password"
                  name="confirm"
                  autoComplete="off"
                  id=""
                  onChange={(e) => {
                    setConfirm(e.target.value)
                  }}
                required/>
                <label htmlFor="" className="login-label">
                  Retype Password
                </label>
              </div> </>}

              {/* <div className="optionslogin-main">
                <input
                  className={email ? "active-input" : ""}
                  type="email"
                  name="email"
                  autoComplete="off"
                  id=""
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                required/>
                <label htmlFor="" className="login-label">
                  Email address
                </label>
              </div>

              <div className="optionslogin-main">
                <input
                  className={email ? "active-input" : ""}
                  type="email"
                  name="email"
                  autoComplete="off"
                  id=""
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                required/>
                <label htmlFor="" className="login-label">
                  Email address
                </label>
              </div> */}

              <div className="optionslogin-main">
              { otpVerify==="otp_verified"?<div onClick={SendPasswordForget} className="loginContainer-btn">
                  Reset Password
                </div>: <button className="loginContainer-btn" >
                  Send Verification code
                </button>}
              </div>

              <div className="login-container-status">{}</div>

              <div className="optionslogin-main">
                <Link to={"/login"} className="login-link">
                  Go back
                </Link>
              </div>
            </form>
          </div>
          <div className="loginbox-inside"></div>
        </div>
        <img src={BottomRightSvg} alt="" className="loginpage-right-top" />
      </div>
    </>
  );
}

export default ForgetPassword;
