import React from "react";
import "./TabTimetablenew.css";
import {tabtimetablenew} from "../../Arraydata"

const TabTimetablenew = () => {
  return (
    <div className="TabTimetablenew-container">

      {tabtimetablenew?.map((data,index)=>{
        return(
<div className="TabTimetablenew-box" key={index}>
        <div className={data.ongoing==="active"?"TabTimetablenew-boxLeft active-time":"TabTimetablenew-boxLeft"}>{data.time}</div>
        <div className="TabTimetablenew-boxRight">
          <div className="TabTimetablenew-subject">
            <p className="TabTimetablenew-sub">{data.subject}</p>
            <p className="TabTimetablenew-tutor">{data.tutor}</p>
          </div>
        </div>
      </div>
        )
      })}

      

      


    </div>

   
  );
};

export default TabTimetablenew;
