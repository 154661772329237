import React from 'react'
import "./MobileTableHeader.css"

const MobileTableHeader = ({
    clicked,
    setClicked,
    header1,
    header2
}) => {

  return (
    <div className='MobileTableHeader-container'>

        <div onClick={()=>setClicked(header1)} className={clicked===header1?"clicked-border":""}>{header1}</div>
        <div onClick={()=>setClicked(header2)} className={clicked===header2?"clicked-border":""}>{header2}</div>
        
    </div>
  )
}

export default MobileTableHeader