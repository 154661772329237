import React from "react";
import "./ReceivedPayFilterPopup.css";
import InputComponent from "../InputComponent/InputComponent";
import apiCall from "../../Services/apiCall";
import { CourseList, ViewDivision } from "../../Services/apiServices";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ReceivedPayFilterPopup = ({
  setReceivedFilterPopup,
  receivedFilterInput,
  setReceivedFilterInput,
  Receivedpaymentlist,
}) => {
  const navigate = useNavigate();

  const [receivedCourseData, setreceivedCourseData] = useState([]);
const [div,setDiv]=useState([])
  const receivedCourseList = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setreceivedCourseData(resp.Course);
    } else if (resp.status === "no_data") {
      setreceivedCourseData([]);
    } else if (resp.status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const receivedPayDivisions = receivedCourseData?.filter(
    (data) => data.class === receivedFilterInput.class
  );
  const handleDivision =async()=>{

    const route = ViewDivision()
    const resp = await apiCall(route,{Class:receivedFilterInput?.class})
    if(resp.Status ==="success"){
        setDiv(resp?.Division)
    }
  }

  const handleReceivedCancel = () => {
    setReceivedFilterInput({
      ...receivedFilterInput,
      class: "",
      division: "",
      month: "",
    });
    setReceivedFilterPopup(false);
  };

  useEffect(() => {
    receivedCourseList();
  }, []);

  return (
    <div className="ReceivedFiltPopup-wrapper">
      <div className="ReceivedFiltPopup-container">
        <div className="ReceivedFiltPopup-head">Received Payments Filter</div>

        <div className="ReceivedFiltPopup-middle">
          <div className="ReceivedFiltPopup-month">
            <p>Month : </p>
            <InputComponent
              type="month"
              isRequired={false}
              placeHolder="month"
              value={receivedFilterInput.month}
              onChange={(e) =>
                setReceivedFilterInput({
                  ...receivedFilterInput,
                  month: e.target.value,
                })
              }
            />
          </div>

          <select
            name=""
            id=""
            className="selectbox"
            value={receivedFilterInput.class}
            onChange={(e) =>
              {setReceivedFilterInput({
                ...receivedFilterInput,
                class: e.target.value,
              })
              handleDivision()
             }
            }
          >
            <option className="default-select" value="" selected>
              class
            </option>
            {receivedCourseData?.map((option, index) => {
              return (
                <option key={index} value={option.class}>
                  {option.class}
                </option>
              );
            })}
          </select>

          <select
            name=""
            id=""
            className="selectbox"
            value={receivedFilterInput.division}
            onChange={(e) =>
              setReceivedFilterInput({
                ...receivedFilterInput,
                division: e.target.value,
              })
            }
          >
            <option className="default-select" value="">
              Division
            </option>
            {div?.map((divdata, index) => {
              return (
                <option key={index} value={divdata?.division}>
                  {divdata?.division}
                </option>
              );
            })}
          </select>
        </div>

        <div className="ReceivedFiltPopup-btns-container">
          <div
            className="ReceivedFiltPopup-cancelBtn"
            onClick={handleReceivedCancel}
          >
            Cancel
          </div>

          <button
            className="ReceivedFiltPopup-chooseBtn btn-outline"
            onClick={Receivedpaymentlist}
          >
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceivedPayFilterPopup;
