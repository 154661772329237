import React from 'react'
import PieChart from '../PieChart/PieChart'
import "./PieChartComponent.css"
import pie from "./pie.svg"

const PieChartComponent = ({Absent,Present}) => {
  return (
    <div className='Piechart-wrapper'>

        <div className="Piechart-top">
           <img src={pie} alt="" />
           <p>WHO'S AWAY</p>
        </div>

        <div className="Piechart-btm">

            <div className="Piechart-btm-left">
            <PieChart
            Present={Present}
            Absent={Absent}
            />
            </div>

            <div className="Piechart-btm-right">
                 <div className="Piechart-legend">
                    <div className='legend-color-present'></div>
                    <p>Present</p>
                    <p className='legend-percent'>{Present?Present:0}%</p>
                    
                 </div>

                 <div className="Piechart-legend">
                    <div className='legend-color-absent'></div>
                    <p>Absent</p>
                    <p className='legend-percent'>{Absent?Absent:100}%</p>
                 </div>
            </div>


        </div>
       
    </div>
  )
}

export default PieChartComponent