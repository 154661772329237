import React from "react";
import "./ProfilesComponent.css";
import TableHeader from "../TableHeader/TableHeader";
import { useState } from "react";
import StudListTable from "../StudListTable/StudListTable";
import TeacherListTable from "../TeacherListTable/TeacherListTable";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import IconButton from "../IconButton/IconButton";
import filter from "./assets/filter.svg";
import dwnld from "./assets/dwnld.svg";
import addstud from "./assets/addstud.svg";


const ProfilesComponent = ({ setprofilesDwnldpop,
  dataList,
  setStudentPopup,
  teachersList,
  setTeacherPopup,
  proClass,
  setProClass,
  proDiv,
   setProDiv ,
   teacherlistDownload,
   StudentsListDownload,
   setStudprofilesDwnldpop,
   setIndividualMsg,
   profilesFiltDrop, 
   setprofilesFiltDrop,
   msgPopupDataPass,
   setMsgPopupDataPass,
   setManualAttendancePopup,
   loading
  }) => {
  const [headerType, setheaderType] = useState("STUDENT LIST");
  
const [searchInput, setSearchInput] = useState("");

const searchedList = dataList?.filter(item=>item.Name.toLowerCase().includes(searchInput.toLowerCase())
||
item.AdmNo.replace(localStorage.getItem("adminKey"),"").includes(searchInput)
)
 console.log(searchedList);

  return (
    <div className="Profiles-container">
      <div className="Profiles-top">
        <div className="Profiles-top-left">
          <div>
            <TableHeader
              header1="STUDENT LIST"
              header2="TEACHER LIST"
              setheaderType={setheaderType}
            />
          </div>
          {/* {headerType === "TEACHER LIST" ? (
            <div className="Profiles-filtShow">10 A</div>
          ) : (
            ""
          )} */}
        </div>

        {headerType === "STUDENT LIST" ? (
          <div className="Profiles-top-right">
             <div >
              <input type="search" placeholder="Search by Name or Adm.no." className="Profiles-searchbox"
                onChange={(e)=>setSearchInput(e.target.value)}/>
            </div>
            <div onClick={()=>setStudentPopup(true)}>
              <IconButton label="Add Student" img={addstud} />
            </div>
            <div onClick={() =>{setStudprofilesDwnldpop(true);StudentsListDownload();}}>
              <IconButton label="Download" img={dwnld} />
            </div>
            <div onClick={() => setprofilesFiltDrop(!profilesFiltDrop)}>
              <IconButton label="Filter" img={filter} />
            </div>
          </div>
        ) : headerType === "TEACHER LIST" ? (
          <div className="Profiles-top-right">
            <div onClick={()=>setTeacherPopup(true)}>
              <IconButton label="Add Teacher" img={addstud} />
            </div>
            <div onClick={() => {setprofilesDwnldpop(true);teacherlistDownload();}}>
              <IconButton label="Download" img={dwnld} />
            </div>
            
          </div>
        ) : (
          ""
        )}

        {profilesFiltDrop ? (
          <div className="Profiles-filter-drop">
            <FilterDropdown
            cls={proClass}
            setCls={setProClass}
            div={proDiv}
            setDiv={setProDiv}/>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="Profiles-bottom">
        {headerType === "STUDENT LIST" ? (
          <StudListTable
          setIndividualMsg={setIndividualMsg}
           dataList={dataList}
           msgPopupDataPass={msgPopupDataPass}
   setMsgPopupDataPass={setMsgPopupDataPass}
   setManualAttendancePopup={setManualAttendancePopup}
   searchedList={searchedList}
   loading={loading}
           />
        ) : headerType === "TEACHER LIST" ? (
          <TeacherListTable teachersList={teachersList}    loading={loading}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProfilesComponent;
