import "./TabProfileHeader.css"

const TabProfileHeader = ({
    selected,
    setSelected
}) => {


  return (
    <div className='TabProfileHeader-container'>

        <div onClick={()=>setSelected("profile")} className={selected==="profile"?"selected-border":""}>Profile Details</div>
        <div onClick={()=>setSelected("attendance")} className={selected==="attendance"?"selected-border":""}>Attendance</div>
        <div onClick={()=>setSelected("payments")} className={selected==="payments"?"selected-border":""}>Payment History</div>
        
    </div>
  )
}

export default TabProfileHeader