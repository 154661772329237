import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
function PaymentHistoryStudent({ data }) {
  return (
    <>
        <div className="action-sec">
        {/* <div className="ico-sec">
          <Tooltip title="Download">
            <IconButton>
              <CloudDownloadIcon fontSize="large" sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter">
            <IconButton>
              <FilterAltIcon fontSize="large" sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div> */}
      </div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Amount</h3>
              </TableCell>
              <TableCell>
                <h3>Date</h3>
              </TableCell>
              <TableCell>
                <h3>Purpose</h3>
              </TableCell>
              <TableCell>
                <h3>Reciept No</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((data, index) => {
              return (
                <TableRow
                  sx={{ backgroundColor: index % 2 == 1 ? "#e1e8f2" : "" }}
                >
                  <TableCell>{data?.amount}</TableCell>
                  <TableCell>{data?.date}</TableCell>
                  <TableCell>{data?.purpose}</TableCell>
                  <TableCell>{data?.receipt_no}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PaymentHistoryStudent;
