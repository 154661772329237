import React, { useEffect } from 'react'
import "./MyProfileStudent.css"
import StudentHeaderSection from '../../components/StudentHeaderSection/StudentHeaderSection'
import ProfileBar from '../../components/ProfileBar/ProfileBar'
import ProfileAttendanceHistory from '../../components/ProfileAttendanceHistory/ProfileAttendanceHistory'
import ProfilepaymentHistory from '../../components/ProfilepaymentHistory/ProfilepaymentHistory'
import { useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import OutStandingFee from '../../components/OutStandingFee/OutStandingFee'
import TabProfileHeader from '../../components/TabProfileHeader/TabProfileHeader'
import { studLoginProfileRoute, studwebOutstandingFees, studwebOutstandingFeesRoute } from '../../Services/apiServices'
import apiCall from '../../Services/apiCall'
import { useNavigate } from 'react-router-dom'
import DeleteWarnPopup from '../../components/DeleteWarnPopup/DeleteWarnPopup'
import UnavailablePopup from '../../components/UnavailablePopup/UnavailablePopup'
import ViewDocumentPopup from '../../components/ViewDocumentPopup/ViewDocumentPopup'
import ParentTimetable from '../ParentTimetable/ParentTimetable'

const MyProfileStudent = () => {
  const navigate = useNavigate();

  const [myProfileShow, setMyProfileShow] = useState("");
  const [myProfileMonth, setMyProfileMonth] = useState(new DateObject());

  const [myProfilepayMonthShow, setMyProfilepayMonthShow] = useState("");
  const [myProfilepayFilter, setMyProfilepayFilter] = useState(new DateObject());

const [profileOption, setProfileOption] = useState("profile")
const [studwebAttendancedwnldPopup, setStudwebAttendancedwnldPopup] = useState(false);
const [studwebPaymentdwnldPopup, setStudwebPaymentdwnldPopup] = useState(false)
const [studwebChangeDpPopup, setStudwebChangeDpPopup] = useState(false)
const [studLogoutPopup, setStudLogoutPopup] = useState(false)
const [studLoginProfileInfo, setStudLoginProfileInfo] = useState({
  admno:"",
  name:"",
  class:"",
  division:"",
  guardian:"",
parentphone:"",
address:"",
image:"",
studentphone:"",
dateofbirth:"",
bloodgroup:"",
father:"",
mother:""
})
const [studDocpopup, setStudDocpopup] = useState(false)


const studLoginProfileView=async()=>{
  const route = studLoginProfileRoute();
  const resp = await apiCall(route,{});
  if(resp.Status==="success"){
    setStudLoginProfileInfo({...studLoginProfileInfo,
      admno:resp.Data[0]?.AdmNo,
      name:resp.Data[0]?.Name,
      class:resp.Data[0]?.Class,
      division:resp.Data[0]?.Division,
      guardian:resp.Data[0]?.Gaurdian,
parentphone:resp.Data[0]?.ParentPhone,
address:resp.Data[0]?.Address,
image:resp.Data[0]?.Image,
email:resp.Data[0]?.Email,
studentphone:resp.Data[0]?.StudentPhone,
dateofbirth:resp.Data[0]?.DOB,
bloodgroup:resp.Data[0]?.BloodGroup,
father:resp.Data[0]?.FatherName,
mother:resp.Data[0]?.MotherName

    })
  }
  else if(resp.Status==="no_data"){
    setStudLoginProfileInfo({...studLoginProfileInfo,
      admno:"",
  name:"",
  class:"",
  division:"",
  guardian:"",
parentphone:"",
address:""
    })
  }
  else if(resp.Status==='token_is_invalid'){
    navigate('/studentLogin')
   }

}



const studLogoutSubmit = () => {
  localStorage.clear();
  navigate("/");
};


useEffect(() => {
  studLoginProfileView();
}, [])



  return (
    <div className='MyProfileStudent-container'>

{studLogoutPopup ? (
  <div className="Studlogout-popup">
    <DeleteWarnPopup
      type="logout"
      logoutSubmit={studLogoutSubmit}
      setWarning={setStudLogoutPopup}
    />
  </div>
) : (
  ""
)}

{studDocpopup ? (
  <div className="Studlogout-popup">
    <ViewDocumentPopup 
    type="studentWeb"
    setDocumentPopup={setStudDocpopup} 
    />
  </div>
) : (
  ""
)}


{studwebAttendancedwnldPopup ? (
  <div className="Studlogout-popup">
  <UnavailablePopup setUnavailablePopup={setStudwebAttendancedwnldPopup}/>
  </div>
) : (
  ""
)}

{studwebPaymentdwnldPopup ? (
  <div className="Studlogout-popup">
  <UnavailablePopup setUnavailablePopup={setStudwebPaymentdwnldPopup}/>
  </div>
) : (
  ""
)}

{studwebChangeDpPopup ? (
  <div className="Studlogout-popup">
  <UnavailablePopup setUnavailablePopup={setStudwebChangeDpPopup}/>
  </div>
) : (
  ""
)}

      <StudentHeaderSection 
      type="profile"
      studLoginProfileInfo={studLoginProfileInfo}
      />

      {/* <div className="MyProfileStudent-tab">

        <TabProfileHeader
        selected={profileOption}
        setSelected={setProfileOption}
        />

      </div> */}


{profileOption==="profile"?
      <div className="MyProfileStudent-wrap">

        <div className="MyProfileStudent-wrap-left">
          <ProfileBar 
          type="studentLogin"
          studLoginProfileInfo={studLoginProfileInfo}
          setStudLoginProfileInfo={setStudLoginProfileInfo}
          setStudLogoutPopup={setStudLogoutPopup}
          setStudwebChangeDpPopup={setStudwebChangeDpPopup}
          setStudDocpopup={setStudDocpopup}
          />
          <div className='MyProfileStudent-fee'>
            <OutStandingFee
            type="studentWeb"
            />
          </div>

        </div>

        {/* <div className="MyProfileStudent-wrap-right">

        <ProfileAttendanceHistory         
              type="studentWeb"
              profileAttendMonth={myProfileMonth}
              setProfileAttendMonth={setMyProfileMonth}
              profileAttendMonthfilter={myProfileShow}
              setProfileAttendMonthfilter={setMyProfileShow}
              AttendanceHistoryDownload={setStudwebAttendancedwnldPopup}
            />
            <ProfilepaymentHistory
            type="studentWeb"
              profilePayFilter={myProfilepayFilter}
              setprofilePayFilter={setMyProfilepayFilter}
              profilePayMonthFilt={myProfilepayMonthShow}
              setprofilePayMonthFilt={setMyProfilepayMonthShow}
              payHistoryDownload={setStudwebPaymentdwnldPopup}
            />

        </div>  */}




          {/* <TimetableBox/> */}
        <ParentTimetable/>

      









      </div>
  
  :profileOption==="attendance"?
  <div className="mystudprofile-tabData">
    <ProfileAttendanceHistory
            type="studentWeb"
            profileAttendMonth={myProfileMonth}
            setProfileAttendMonth={setMyProfileMonth}
            profileAttendMonthfilter={myProfileShow}
            setProfileAttendMonthfilter={setMyProfileShow}
            AttendanceHistoryDownload={setStudwebAttendancedwnldPopup}
           />
  </div>
   :profileOption==="payments"?
   <div className="mystudprofile-tabData">
  <ProfilepaymentHistory
          type="studentWeb"
          profilePayFilter={myProfilepayFilter}
          setprofilePayFilter={setMyProfilepayFilter}
          profilePayMonthFilt={myProfilepayMonthShow}
          setprofilePayMonthFilt={setMyProfilepayMonthShow}
          payHistoryDownload={setStudwebPaymentdwnldPopup}
          />
   </div>
   :""}

  

  
      
    </div>
  )
}

export default MyProfileStudent