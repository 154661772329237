import React, { useEffect } from "react";
import "./StudDashboardCard.css";
import listicon from "./assets/listicon.svg";
import ClassDisplayBox from "../ClassDisplayBox/ClassDisplayBox";
import calendaricon from "./assets/calendaricon.svg"
import { StudLoginDashAttendanceRoute, StudLoginOngoingClassRoute } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const StudDashboardCard = ({type}) => {
  const navigate = useNavigate();
const studLoginAdmNo = localStorage.getItem("StudAdmNo");

  const [studDashAttendance, setStudDashAttendance] = useState([])
  const [studDashOngoingCls, setStudDashOngoingCls] = useState([])

  const studLoginAttendanceDash = async () => {
    const route = StudLoginDashAttendanceRoute();
    const resp = await apiCall(route, {
      AdmissionNo:studLoginAdmNo?studLoginAdmNo:""
    });
    if (resp.Status === "success") {
      setStudDashAttendance(resp.Data[0]?resp.Data[0]:[]);
    } else if (resp.Status === "no_data") {
      setStudDashAttendance([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  };
  
  

  const studLoginOngoingDash = async () => {
    const route = StudLoginOngoingClassRoute();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setStudDashOngoingCls(resp.Data[0]?resp.Data[0]:[]);
    } else if (resp.Status === "no_data") {
      setStudDashOngoingCls([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  };
  console.log(studDashOngoingCls.subject);

  useEffect(() => {
    studLoginAttendanceDash();
    studLoginOngoingDash();
  }, [])
  

  return (
    <>
    {type==="attendance"?
      <div className="StudDashboardCard-container">
        <div className="StudDashboardCard-top">
          <img src={listicon} alt="" />
          <p>TODAY'S ATTENDANCE</p>
        </div>

        <div className="StudDashboardCard-middle">
          <p>{localStorage.getItem("StudName")}</p>
          <ClassDisplayBox 
          std={localStorage.getItem("StudClass")} 
          division={localStorage.getItem("StudDivision")} 
          />
        </div>

        <div className="StudDashboardCard-btm">
          <div className="StudDashboardCard-btm-content">
            <p className="StudDashboardCard-check">Check-in : </p>
            <p className="StudDashboardCard-time">{studDashAttendance.Checkin?studDashAttendance.Checkin:"-- : --"}</p>
          </div>
          <div className="StudDashboardCard-btm-content">
            <p className="StudDashboardCard-check">Check-out : </p>
            <p className="StudDashboardCard-time">{studDashAttendance.Checkout?studDashAttendance.Checkout:"-- : --"}</p>
          </div>
        </div>
      </div>
      :""}

{type==="ongoing"?
 <div className="StudDashboardCard-container">
 <div className="StudDashboardCard-top">
   <img src={calendaricon} alt="" />
   <p>ONGOING CLASSES</p>
 </div>

 <div className="StudDashboardCard-middle">
<p>{studDashOngoingCls.start_time?studDashOngoingCls.start_time:"-- : --"} - {studDashOngoingCls.end_time?studDashOngoingCls.end_time:"-- : --"}</p>
 </div>

 <div className="StudDashboardCard-btm">
   <div className="StudDashboardCard-btm-box">
    <p className="StudDashboardCard-time">{studDashOngoingCls.subject?studDashOngoingCls.subject:"Subject"}</p>
    <p className="StudDashboardCard-check">{studDashOngoingCls.teacher?studDashOngoingCls.teacher:"Teacher"}</p>
   </div>
 </div>
</div>
:""}




    </>
  );
};

export default StudDashboardCard;
