import React, { useState } from 'react'
import "./ClassListTable.css"
import {dummy} from "../../Arraydata"
import accordionArrow from "./accordionArrow.svg"
import {accordiondata} from "../../Arraydata"
import apiCall from '../../Services/apiCall'
import { AcademicsClassList } from '../../Services/apiServices'
import { useEffect } from 'react'

const ClassListTable = ({academicsClsData}) => {

    const [dataArray, setdataArray] = useState(accordiondata.datas);

    const [openIndex, setOpenIndex] = useState(null);

    const handleAccordionItemClick = (index) => {
      if (index === openIndex) {
        setOpenIndex(null);
      } else {
        setOpenIndex(index);
      }
    };

  
    
    
  return (
    <div className='ClassListTable-container'>
    <div className="ClassListTable-wrapper">
  <div className='ClassListTable-head' ></div>
  <div className='ClassListTable-head'  >Sl.no</div>
  <div className='ClassListTable-head'  >Class/Course</div>
  <div className='ClassListTable-head'  >Students</div>
  <div className='ClassListTable-head'  >Total Fee</div>
  </div>

<div className='ClassListTable-scroll-container'>

  {academicsClsData?.map((data,index)=>{
    return(
    <div>


 <div onClick={() => handleAccordionItemClick(index)} key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='ClassListTable-content-wrapper clslist-pointer'>
 <div  className='ClassListTable-content'>
    <img className={openIndex===index?'accordion-svg-active':"accordion-svg-inactive"} src={accordionArrow} alt="" />
 </div>
  <div  className={openIndex===index?'ClassListTable-content bold-text':"ClassListTable-content"}>{index+1}</div>
  <div className={openIndex===index?'ClassListTable-content bold-text':"ClassListTable-content"}>{data.class}</div>
  <div className={openIndex===index?'ClassListTable-content bold-text':"ClassListTable-content"}>{data.total_students}</div>
  <div className={openIndex===index?'ClassListTable-content bold-text':"ClassListTable-content"}></div>

</div>

{openIndex===index?
<>
    {data?.classwise_data.map((item,index)=>{
        return(
            <div className='ClassListTable-content-wrapper' style={{ backgroundColor: openIndex % 2 === 0 ? '#f2f2f2' : '#ffffff' }} key={index}>
            <div  className='ClassListTable-content'></div>
            <div className='ClassListTable-content'></div>
            <div className='ClassListTable-content'>{item.Class}  {item.Division}</div>
            <div className='ClassListTable-content'>{item.students}</div>
            <div className='ClassListTable-content'>{item.total_fee?("₹"+item.total_fee):""}</div>
          </div>
        )
    })}
    </>
    :""} 



</div>


    )
  })}

</div> {/* ClassListTable-scroll-container */}



</div>
  )
}

export default ClassListTable