import React from 'react'
import "./StudentLogin.css"
import "../LoginContainer/LoginContainer.css"
import "../StudentRegisterContainer/StudentRegisterContainer.css"
import Logo from './assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import LeftTopSvg from './assets/lefttop.png'
import BottomRightSvg from './assets/bottomright.png'
import { useState } from 'react'
import ResetComponent from '../../components/ResetComponent/ResetComponent'
import apiCall from '../../Services/apiCall'
import { StudentLoginRoute } from '../../Services/apiServices'

const StudentLogin = () => {

    const [check, setCheck] = useState("");
    const[reset,setReset] = useState(false)

  const [studLoginData,setStudLoginData] = useState({
    username:'',
    password:''
  })

  const navigate = useNavigate();

  const passwordSubmit=(e)=>{
    setStudLoginData({...studLoginData,password:(e.target.value)});
    setCheck("")
  }


  const studentLoginSubmit=async(e)=>{
    e.preventDefault()
    const route = StudentLoginRoute();
    const resp = await apiCall(route,{
      Username : studLoginData.username?studLoginData.username:"",
      Password : studLoginData.password?studLoginData.password:""
    });
    if(resp.Status==="success"){
      localStorage.setItem("StudKey", resp.Key)
      localStorage.setItem("token", JSON.stringify(resp.Token));
      localStorage.setItem("StudClass", resp.Data[0]?.Class);
      localStorage.setItem("StudAdmNo", resp.Data[0]?.AdmNo);
      localStorage.setItem("StudDivision", resp.Data[0]?.Division);
      localStorage.setItem("StudName", resp.Data[0]?.Name);
      navigate("/studentdashboard");
    }
    else if(resp.Status==="no_such_student"){
      setCheck("no such student");
    }
    else if(resp.Status==="incorrect_password"){
      setCheck("incorrect password or username");
    }
  }

  return (
    <div className='loginpage-wrapper'>
    <img src={LeftTopSvg} alt="" className='loginpage-left-top' />
   {reset?<ResetComponent setReset={setReset}/>: <div className='loginpage-box'>
      <div className="loginbox-inside login-header">
      <Link to={'/'} className='login-link'> 
        <img src={Logo} alt="" height={'20px'}/>
        </Link>
        <h3>Welcome to Zedtap</h3>
       
      </div>
      <div className="loginbox-inside">
      <h3>Parent Login</h3>
      </div>
      <div className="loginbox-inside">
        <form className="login-main" onSubmit={studentLoginSubmit}>
          <div className="optionslogin-main">
            <input className={studLoginData.username?"active-input":""} type="text" name="username" autoComplete="off" id="" onChange={(e)=>{setStudLoginData({...studLoginData,username:(e.target.value)});setCheck("")}} required/>
            <label htmlFor="" className='login-label'>Username</label>

          </div>
          <div className="optionslogin-main">
            <input className={studLoginData.password?"active-input":""}  type="Password" name="password" id="" autoComplete='off' onChange={passwordSubmit} required/>
            <label htmlFor="" className='login-label'>Password</label>
          </div>
       
          <div className="optionslogin-main">
            <button type='submit' className='loginContainer-btn'>Login</button>
          </div>

          <div className='login-container-status'>
          {check}
          </div>

          <div className="optionslogin-main">
           {/* <Link to={'/forgetpassword'} className='login-link'> Forget Password?</Link> */}
           <Link style={{marginTop:"15px"}} to={'/registration'} className='login-link'>Register as Parent</Link>
           <Link to={'/login'} style={{marginTop:"15px"}} className='login-link'>Not a Parent? <span style={{color:"white"}}>Login here</span></Link>
          </div>
        </form>
      </div>
      <div className="loginbox-inside"></div>
    </div>}
    <img src={BottomRightSvg} alt="" className='loginpage-right-top' />
  </div>
  )
}

export default StudentLogin