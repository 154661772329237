import React, { useEffect, useState } from 'react';
import "../FeeAddComponent/feestyle.css"
import square from "./square.svg"
import close from "./close.svg"
import SaveCancelBtn from '../SaveBtn/SaveBtn';
import apiCall from '../../Services/apiCall';
import { AllTeachers } from '../../Services/apiServices';
import { useNavigate } from 'react-router-dom';

const AssignSubjectComponent = ({
    setAddclassOption,
    TeacherSubjectSubmit,
    subjectTeacherData,
    teachers,
    subjects,
    setsubjectTeacherData}) => {




  const handleSubmit = (e) => {
    e.preventDefault();
    TeacherSubjectSubmit();
  };

  const handleChange = (index, field, value) => {
    setsubjectTeacherData(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
  };

  const handleRemoveInput = (index) => {
    const updatedInputBoxes = [...subjectTeacherData];
    updatedInputBoxes.splice(index, 1);
    setsubjectTeacherData(updatedInputBoxes);
  };

  
  
 

  const renderInputs = () => {
 
    return subjectTeacherData.map((item, index) => (
      <div key={index} className='AddClass-feeStructure'>
        <img height="12" width="12" onClick={()=>handleRemoveInput(index)}  className='AddClass-feeClose'src={close} alt="" />
       
         <select
         name="" id=""
         className='AddClass-categoryInput'
         value={item.SubjectId || ''}
         onChange={e => handleChange(index, 'SubjectId', e.target.value)}
        //  required
         >
        <option value="">Subject</option>
        {subjects?.map((data,index)=>{
            return(
                <option key={index} value={data.id}>{data.name}</option>
            )
        })}
    
        </select>
        <div>
          :
        </div>
       
        <select
         name="" id=""
         className='AddClass-feeInput no-scroll'
         value={item.TeacherId || ''}
         onChange={e => handleChange(index, 'TeacherId', e.target.value)}
        //  required
         >
        <option value="">Teacher</option>
        {teachers?.map((data,index)=>{
            return(
                <option key={index} value={data.Id}>{data.Name}</option>
            )
        })}
        </select>
      </div>
    ));
  };


  return (
    <form  onSubmit={handleSubmit}>
      
      <div className='AddClass-feeScroll-box'>
        <div className='AddClass-feeaddbox'>

      {renderInputs()}
      <img className='AddClass-feeadd' onClick={()=>setsubjectTeacherData([...subjectTeacherData,""])} src={square} alt="" />
      </div>
      </div>

  

      <div className="AddClass-btns">

          <div 
          onClick={()=>setAddclassOption(0)}
           >
            <SaveCancelBtn
            type="back"
            />
            </div>

            <button type='submit' className='btn-outline'>
            <SaveCancelBtn
            type="save"
            text="Save"
            />
            </button>
</div>
     
    </form>
  );
};

export default AssignSubjectComponent;
