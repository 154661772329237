import React from 'react'
import "./StudentHeaderSection.css"
import zedlogo from "./assets/zedlogo2.svg"
import dp from "./assets/dp.jpg"
import  "../HeaderSection/HeaderSection.css"
import { Link } from 'react-router-dom'
import appicon from "./assets/appicon.svg"
import { baseUrl } from '../../Services/apiCall'
import { ImageViewRoute } from '../../Services/apiServices'

const StudentHeaderSection = ({type,studLoginProfileInfo}) => {
  return (
    <div className={type==="home"?'StudentHeaderSection-container':"studmyprofile-height"}>

<div className="navbar-section">

  <Link className='myprofile-link' to="/studentdashboard">
        <div className="stud-left-navbar">
            <img
              className="logo"
              // src={baseUrl + "/student/svg_image?Image=zedtaqplogo.svg"}
              src={zedlogo}
              alt="ZedTap"
            />  
             <img
              className="app-logo"
              src={appicon}
              alt="ZedTap"
            /> 
        </div>
    </Link>

  
        <div className="right-navbar" >
          <Link className='myprofile-link'  to="/MyProfileStudent">
            <div
              className="StudentHeaderSection-profilebox"
            //   onClick={() => setNavProfile(!navProfile)}
            >
              <div className="navbar-profile-content">
                <img
                  className="navbar-dp"
                  src={baseUrl+ImageViewRoute()+studLoginProfileInfo.image}
                  // src={dp}
                  alt="no"
                />
                <div className='StudentHeaderSection-nameEmail-cover'>
                  <div className="StudentHeaderSection-name">{studLoginProfileInfo?.name}</div>
                  <div className="StudentHeaderSection-email">{studLoginProfileInfo?.email}</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
        
    </div>
  )
}

export default StudentHeaderSection