import React from 'react'
import "./PieChart.css"
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip);

const PieChart = ({Present,Absent}) => {
  return (
    <div className='piechart-container'>
        <Pie 
 
 data = {{
  labels: ['Absent', 'present'],
  
  datasets: [
    {
      label: '# of Votes',
      data: [Absent?Absent:0,Present?Present:0],
      backgroundColor: [
        '#A6B8FA',
        '#02E1A8'
        
      ],
      borderWidth: 3,
      borderColor:"white",
    },
  ],

 }}


 options={
  {
    responsive:true,
  hoverBorderWidth:0,
    plugins: {
        tooltip: {
            enabled: true,
          },
          legend: {
            display: false, 
          }}    
  }
  
 }
 />
    </div>
  )
}

export default PieChart