import React from 'react'
import "./SelectBox.css"

const SelectBox = ({
    defaultValue,
    data,
    onChange,
    isRequired
}) => {
  return (
  <>
  <select 
  className='selectbox' 
  name="" id=""
   onChange={(e) => onChange(e)}
   required={isRequired}
   >
    <option className='default-select' value="" selected>{defaultValue}</option>
    {data?.map((option,index)=>{
        return(
            <option key={index} value={option}>{option}</option>
        )
    })}
  
  </select>
  </>
  )
}

export default SelectBox