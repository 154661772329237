import React from 'react'
import LeftTopSvg from './assets/lefttop.png'
import BottomRightSvg from './assets/bottomright.png'
import "../LoginContainer/LoginContainer.css"
import Logo from './assets/logo.png'
import { Link } from 'react-router-dom'
import success from "./assets/verified.svg"
import "./LoginRegResetSuccessPage.css"


function LoginRegResetSuccessPage() {



  return (
    <div className='loginpage-wrapper'>
      <img src={LeftTopSvg} alt="" className='loginpage-left-top' />
     <div className='loginpage-box'>
        <div className="loginbox-inside login-header">
          <img src={Logo} alt="" height={'20px'}/>
          {/* <h3>Welcome to Zedtap</h3> */}
          
        </div>
       
        <div className="loginbox-inside success-tick">
        <img src={success} alt="" />
          <h3> Registration Successful</h3>
        
        </div>
        <div className="loginbox-inside">
          <form className="login-main">
           
         
            <div className="optionslogin-main">
            <Link className='tolink' to={"/studentLogin"}>  <button  className='loginContainer-btn'>Login</button></Link>
            </div>

            
          </form>
        </div>
        <div className="loginbox-inside"></div>
      </div>
      <img src={BottomRightSvg} alt="" className='loginpage-right-top' />
    </div>
  )
}

export default LoginRegResetSuccessPage