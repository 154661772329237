import React from 'react'
import apiCall, { baseUrl } from '../../Services/apiCall'
import "./DocumentComponent.css"
import doc from "./dp.jpg"
import wall from "./wall.jpg"
import trash from "./trash.svg"
import pnglogo from "./assets/pnglogo.png"
import jpglogo from "./assets/jpglogoimg.png"
import filelogo from "./assets/file.png"
import pdflogo from "./assets/pdflast-removebg-preview.png"
import { Docpreview } from '../../Services/apiServices'

const DocumentComponent = ({docdata}) => {
console.log("here",docdata);

  return (
   
   
    <a href={baseUrl+Docpreview()+docdata?.FileName} className="DocumentComponent-link"  rel="noreferrer" target="_blank">

    <div className='document-component'>
<div className='DocumentComponent-imgwrap'>
{docdata.Type===".jpg"||docdata.Type===".jpeg"||docdata.Type===".png"?
<img className='DocumentComponent-img'
 src={baseUrl+Docpreview()+docdata.FileName}
  alt="unavailable" />
:docdata.Type===".pdf"?
<img className='DocumentComponent-other' src={pdflogo} alt="" />
:
<img className='DocumentComponent-other' src={filelogo} alt="" />
  }
</div>
        <div className="DocumentComponent-btm">
        <p>{docdata.Name}</p>
        {/* <img src={trash} alt="" /> */}
        </div>
      
        
    </div>
      </a>

  )
}

export default DocumentComponent