import React from 'react'
import "./AddPeriodPopup.css"
import SelectBox from '../SelectBox/SelectBox'
import SaveCancelBtn from '../SaveBtn/SaveBtn'
import { AddPeriodRoute, AddTimeTable, TimetableSubjectTeacherView } from '../../Services/apiServices'
import apiCall from '../../Services/apiCall'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const AddPeriodPopup = ({
  setAddPeriodPopup,
  timetableFilterDivision,
  timetableFilterClass,
  periodAddData
}) => {
  const navigate = useNavigate()

  const [subjectTeachersData, setsubjectTeachersData] = useState([])
  const [addPeriodData, setAddPeriodData] = useState({
    subject:"",
    teacher:''
})

const [teacherData,setTeacherData] = useState([])

  const viewsubteachers=async()=>{
    const route = TimetableSubjectTeacherView();
    const resp = await apiCall(route,{
      Class:timetableFilterClass==="Class"?"":timetableFilterClass,
    Division:timetableFilterDivision==="Division"?"":timetableFilterDivision
    });
    // setsubjectTeachersData(resp?.data)
    if(resp.Status==="success"){
      setsubjectTeachersData(resp?.Data)
      
    }
    else if(resp.Status==="no_data"){
      setsubjectTeachersData([])
  }
  else if(resp.Status==='token_is_invalid'){
   navigate('/login')
  }
  }
  console.log(addPeriodData);


  

  const AddPeriodSubmit=async(e)=>{
    e.preventDefault();
    const route = AddPeriodRoute();
    const resp = await apiCall(route,{
      CourseAssignId :addPeriodData.teacher?addPeriodData.teacher:"",
      WorkingDaysId: periodAddData.dayId?periodAddData.dayId:"",
      TimetableId : periodAddData.timeslotId?periodAddData.timeslotId:""
    })
    if(resp.Status==="success"){
      setAddPeriodPopup(false)
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }
  }

  useEffect(() => {
    viewsubteachers();
  },[])
  


  return (
    <div className='AddPeriod-wrapper'>
        <form className="AddPeriod-container" onSubmit={AddPeriodSubmit}>
          
            <select 
            onChange={(e)=>{setAddPeriodData({...addPeriodData,subject:e.target.value});
            for (let i = 0; i <= subjectTeachersData.length; i++) {
              if (subjectTeachersData[i]?.subject == e.target.value) {
                setTeacherData(subjectTeachersData[i]?.teacher)
              }
            }
         }
          }
            className='AddPeriod-select' 
            name="" id="">
              <option value="">Subject</option>
              {subjectTeachersData?.map((data,index)=>{
                return(
                  <option key={index} value={data?.subject}>{data?.subject}</option>
                )
              })}
            </select>


            <select 
            onChange={(e)=>setAddPeriodData({...addPeriodData,teacher:e.target.value})}
            className='AddPeriod-select' 
            name="" id="">
              <option value="">Teacher</option>
              {teacherData?.map((data,index)=>{
                return(
                  <option key={index} value={data?.id}>{data?.name}</option>
                )
              })}
            </select>

            <div className="AddPeriod-btns">
              <div onClick={()=>setAddPeriodPopup(false)}>
                <SaveCancelBtn
                type="cancel"
                />
                </div>
                <button className='btn-outline' type='submit'>
                 <SaveCancelBtn
                type="save"
                text="Enter"
                />
                </button>
            </div>
        </form>
        
    </div>
  )
}

export default AddPeriodPopup