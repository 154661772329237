import React from 'react'
import "./DayPicker.css"

const DayPicker = ({type,setDayData,dayData}) => {
 

    const updateValue = (day) => {
      setDayData ((prevWorkingDays) => {
        // Find the day in the workingDays array and update its value
        const updatedWorkingDays = prevWorkingDays.map((item) => {
          if (item.key === day) {
            return {
              ...item,
              value: !item.value
            };
          }
          return item;
        });
  
        return updatedWorkingDays;
      });
    };


  return (
    <>
    {type==="day"?
    <div className='AddTimetable-dayselect'>
  
    {dayData?.map((stringValue) => (
      <div
        key={stringValue.key}
        
        className={stringValue.value===true?'AddTimetable-day active-day' : 'AddTimetable-day inactive-day'}
        onClick={() => {updateValue(stringValue.key)}}
        
      >
        {stringValue.key.substring(0, 3)}
      
      </div>
    ))}
  </div>
:

type==="month"?
<div className="AddTimetable-monthselect">
{/* {stringValues.map((stringValue) => (
  <div
    key={stringValue}
    className={isSelected(stringValue) ? 'AddTimetable-month active-day' : 'AddTimetable-month inactive-day'}
    onClick={() => toggleString(stringValue)}
  >
    {stringValue.substring(0, 3)}
  </div>
))} */}
</div>
:""}



  </>
  )
}

export default DayPicker