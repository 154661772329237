import React from 'react'
import "./TeacherListTable.css"
import { useNavigate } from 'react-router-dom';

const TeacherListTable = ({teachersList}) => {

  const toTeacherProfile = useNavigate();

  return (
    <div className='TeacherListTable-container'>
    <div className="TeacherListTable-wrapper">
  <div className='TeacherListTable-head' >Sl.no</div>
  <div className='TeacherListTable-head'  >Name</div>
  <div className='TeacherListTable-head'  >Subject</div>
  <div className='TeacherListTable-head'  >Phone </div>
  </div>

<div className='TeacherListTable-scroll-container'>

  {teachersList?.map((data,index)=>{
    return(
        <div key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='TeacherListTable-content-wrapper'>
 <div  className='TeacherListTable-content'>{index+1}</div>
  <div className='TeacherListTable-content hover-underline' onClick={()=>toTeacherProfile(`/teacherProfile/${data?.Id}`)}>{data.Name}</div>
  <div className='TeacherListTable-content'>
  {data.Subject[0]?.subject_name}
  </div>
  <div className='TeacherListTable-content'>{data.PhoneNo}</div>

</div>
    )
  })}

</div>

</div>
  )
}

export default TeacherListTable