import React, { useState } from "react";
import "./AddTimetablePopup.css";
import SaveCancelBtn from "../SaveBtn/SaveBtn";
import DayPicker from "../DayPicker/DayPicker";
import { useEffect } from "react";
import apiCall from "../../Services/apiCall";
import { CourseList, TimetableSlotsadd, ViewDivision } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";

const AddTimetablePopup = ({ setAddTimetablePopup,timetableAddData,setTimetableAddData }) => {

  const [course, setCourse] = useState(null);
  const navigate = useNavigate();
  const [dayData, setDayData] = useState([
    {
      key: "Monday",
      value: false,
    },
    {
      key: "Tuesday",
      value: false,
    },
    {
      key: "Wednesday",
      value: false,
    },
    {
      key: "Thursday",
      value: false,
    },
    {
      key: "Friday",
      value: false,
    },
    {
      key: "Saturday",
      value: false,
    },
    {
      key: "Sunday",
      value: false,
    },
  ]);

  const  timetableSelectedClass =  course?.filter((data)=>data.class===timetableAddData.class);

  const handleCourseView = async () => {
    const apiroute = CourseList();
    const response = await apiCall(apiroute, {});
    if (response.Status === "success") {
      setCourse(response?.Course);
    } else if (response.Status === "token_is_invalid") navigate("/login");
  };

  const AddTimetableDays=async(e)=>{
    e.preventDefault();
    const route = TimetableSlotsadd();
    const resp = await apiCall(route,{
      Class:timetableAddData.class,
      Division:timetableAddData.division,
      WorkingDays:dayData?dayData:""
    })
    if(resp.Status==="success"){
      setAddTimetablePopup(false)
    }
  }

  const [div, setDiv] = useState([]);
  const handleDivision = async (data) => {
    const route = ViewDivision();
    const resp = await apiCall(route, { Class: data ? data : "" });
    if (resp.Status === "success") {
      setDiv(resp?.Division);
    }
  };

  useEffect(() => {
    handleCourseView();
  }, []);

  return (
    <div className="AddTimetable-wrapper">
      <form className="AddTimetable-container" onSubmit={AddTimetableDays}>
        <div className="AddTimetable-header">NEW TIMETABLE</div>

          <select name="" id=""
           className="selectbox"
           required 
           onChange={(e)=>{setTimetableAddData({...timetableAddData,class:e.target.value})
           handleDivision(e.target.value)}} >
            <option className="default-select" value="" selected>
              Class / Course
            </option>
            {course?.map((option, index) => {
              return (
                <option key={index} value={option.class}>
                  {option.class}
                </option>
              );
            })}
          </select>

          <select name="" id=""
           className="selectbox"
           required 
           onChange={(e)=>setTimetableAddData({...timetableAddData,division:e.target.value})} >
            <option className="default-select" value="" selected>
              Division
            </option>
            {div?.map((option, index) => {
              return (
                <option key={index} value={option?.division}>
                  {option?.division}
                </option>
              );
            })}
          </select>

          

        <div className="AddTimetable-daysDiv">
          <p className="AddTimetable-selectHead">Select working days</p>

          <DayPicker
            type="day"
            stringValues={dayData}
            setDayData={setDayData}
            dayData={dayData}
          />
        </div>

        <div className="AddTimetable-btns">
          <div onClick={() => setAddTimetablePopup(false)}>
            <SaveCancelBtn type="cancel" text="Cancel" />
          </div>

          <button type="submit" className="btn-outline">
            <SaveCancelBtn type="save" text="Enter" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTimetablePopup;
