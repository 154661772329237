import React from 'react'
import "./MonthYearBtn.css"
import next from "./assets/next.svg"
import previous from "./assets/previous.svg"
import date2 from "./assets/date.svg"
import { useRef, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { baseUrl } from '../../Services/apiCall'



export const getMonthFromString=(mon)=> {

  var d = Date.parse(mon + "1, 2012");
  if (!isNaN(d)) {
    if ((new Date(d).getMonth() + 1) < 10) {
      return `0${new Date(d).getMonth() + 1}`

    } else {
      return new Date(d).getMonth() + 1
    }
  }
  return -1;
}

const MonthYearBtn = ({ date, setDate ,setFilterDateatten,filterDateatten}) => {




  // console.log(getMonthFromString(date?.month.name))

  // console.log(date?.year);

  // setFilterDateatten(getMonthFromString(date?.month.name)+"/"+date?.year)

  setFilterDateatten(date?.year+"-"+getMonthFromString(date?.month?.name))

  

  const calendarRef = useRef();

  function update(key, value) {
    let date = calendarRef?.current?.date;

    calendarRef.current.set(key, date[key] + value);

    setDate(new DateObject(date));
  }

  const style = {
    display: "inline-block",
    // width: "80px",
    fontSize: "11px",
  };
  return (
    <div className='month-year-btn'>
      <div className='month-year'>
        <button className='prev-btn' onClick={() => update("month", -1)}>
          <img 
          // src={baseUrl + "/student/svg_image?Image=previous.svg"}
          src={previous}
           alt="" />
        </button>

        {/* <img className='date-icon' 
        src={baseUrl + "/student/svg_image?Image=date.svg"}
        src={date2}
        alt="" /> */}
        <span className='month' style={style}>{date?.month?.name}</span>
        <span className='year' style={style}>{date?.year}</span>

        <button className='next-btn' onClick={() => update("month", 1)}>
          <img
          //  src={baseUrl + "/student/svg_image?Image=next.svg"}
           src={next}
           alt="" />
        </button>
      </div>
      <Calendar
        // readOnly={true}
        ref={calendarRef}
        disableDayPicker={true}
      />
    </div>
  )
}

export default MonthYearBtn
