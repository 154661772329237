import React from 'react'
import "./NewDocument.css"
import newdoc from "./newdoc.svg"

const NewDocument = () => {
  return (
    <div className='NewDocument-container'>

<img className='NewDocument-img' src={newdoc} alt="" />

<p>Click here to upload new document</p>

        
    </div>
  )
}

export default NewDocument