import React from "react";
import "./AddClassPopup.css";
import InputComponent from "../InputComponent/InputComponent";
import { useState } from "react";
import SaveCancelBtn from "../SaveBtn/SaveBtn";
import FeeAddComponent from "../FeeAddComponent/FeeAddComponent";
import close from "./assets/close.svg";
import apiCall from "../../Services/apiCall";
import {
  AddCourseRoute,
  AllTeachers,
  SubjectList,
  TeacherSubjectsAssignRoute,
} from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";
import SelectBox from "../SelectBox/SelectBox";
import { durationData } from "../../Arraydata";
import AssignSubjectComponent from "../AssignSubjectComponent/AssignSubjectComponent";
import { useEffect } from "react";

const AddClassPopup = ({ setAddCoursePopup, setCourseAddSuccess }) => {
  const navigate = useNavigate();
  const [addclassOption, setAddclassOption] = useState(0);

  const [addCourseData, setAddCourseData] = useState({
    class: "",
    division: "",
    duration: "",
    totalfee: "",
  });

  const [feeStructure, setFeeStructure] = useState([""]);
  const [subjectTeacherData, setsubjectTeacherData] = useState([""]);
  const [addcourseError, setaddcourseError] = useState("");
  const [clsAssignCourseId, setClsAssignCourseId] = useState("");

  const tofeeForm = (e) => {
    e.preventDefault();
    setAddclassOption(1);
  };

  const [teachers, setteachers] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const viewTeachers = async () => {
    const route = AllTeachers();
    const resp = await apiCall(route, {}, false, "PUT");
    if (resp.Status === "success") {
      setteachers(resp.Data);
    } else if (resp.Status === "no_data") {
      setteachers([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const viewSubjects = async () => {
    const route = SubjectList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setSubjects(resp.Data);
    } else if (resp.Status === "no_data") {
      setSubjects([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const AddCourseSubmit = async () => {
    setAddclassOption(2);
    const route = AddCourseRoute();
    const resp = await apiCall(route, {
      Class: addCourseData.class ? addCourseData.class : "",
      Division: addCourseData.division ? addCourseData.division : "",
      Department: "",
      AcademicYear: addCourseData.duration ? addCourseData.duration : "",
      TotalFee: addCourseData.totalfee ? addCourseData.totalfee : "",
      FeeStructure: feeStructure[0] ? feeStructure : "",
    });
    if (resp.Status === "success") {
      setAddclassOption(2);
      setClsAssignCourseId(resp?.CourseId);
    } else if (resp.Status === "already_exists") {
      setAddclassOption(0);
      setaddcourseError("Class Already Exists !");
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const TeacherSubjectSubmit = async () => {
    const route = TeacherSubjectsAssignRoute();
    const resp = await apiCall(route, {
      CourseId: clsAssignCourseId ? clsAssignCourseId : "",
      TeacherSubjects: subjectTeacherData[0] ? subjectTeacherData : "",
    });
    if (resp.Status === "success") {
      setAddCoursePopup(false);
      setCourseAddSuccess(true);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  useEffect(() => {
    viewTeachers();
    viewSubjects();
  }, []);

  return (
    <div className="AddClass-wrapper">
      <div className="AddClass-box">
        <img
          onClick={() => setAddCoursePopup(false)}
          className="AddClass-close-svg"
          src={close}
          alt=""
        />

        {addclassOption === 0 ? (
          <form className="AddClass-container" onSubmit={tofeeForm}>
            <div className="AddClass-title">ADD CLASS / COURSE</div>
            <InputComponent
              type="text"
              isRequired={true}
              value={addCourseData.class}
              onChange={(e) => {
                setAddCourseData({ ...addCourseData, class: e.target.value });
                setaddcourseError("");
              }}
              placeHolder="Class/ Course name"
            />
            <InputComponent
              type="text"
              value={addCourseData.division}
              isRequired={false}
              onChange={(e) => {
                setAddCourseData({
                  ...addCourseData,
                  division: e.target.value,
                });
                setaddcourseError("");
              }}
              placeHolder="Division"
            />

            <SelectBox
              defaultValue="Course Duration"
              data={durationData}
              onChange={(e) =>
                setAddCourseData({ ...addCourseData, duration: e.target.value })
              }
              isRequired={true}
            />
            <div>
              <InputComponent
                type="number"
                isRequired={false}
                onChange={(e) =>
                  setAddCourseData({
                    ...addCourseData,
                    totalfee: e.target.value,
                  })
                }
                placeHolder="Total Fee (₹)"
              />
            </div>

            <div>
              <div className="AddClass-error">{addcourseError}</div>

              <div className="AddClass-btns">
                <div onClick={() => setAddCoursePopup(false)}>
                  <SaveCancelBtn type="cancel" text="Cancel" />
                </div>
                <button className="btn-outline" type="submit">
                  <SaveCancelBtn type="save" text="Next" />
                </button>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}

        {addclassOption === 1 ? (
          <div className="AddClass-container">
            <div className="AddClass-title">ADD FEE STRUCTURE</div>

            <div>
              <FeeAddComponent
                feeStructure={feeStructure}
                setFeeStructure={setFeeStructure}
                AddCourseSubmit={AddCourseSubmit}
                setAddclassOption={setAddclassOption}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {addclassOption === 2 ? (
          <div className="AddClass-container">
            <div className="AddClass-title">ASSIGN COURSE</div>

            <div>
              <AssignSubjectComponent
                subjectTeacherData={subjectTeacherData}
                setsubjectTeacherData={setsubjectTeacherData}
                AddCourseSubmit={AddCourseSubmit}
                setAddclassOption={setAddclassOption}
                teachers={teachers}
                subjects={subjects}
                TeacherSubjectSubmit={TeacherSubjectSubmit}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddClassPopup;
