import "./App.css";
import "./Fonts/Ubuntu-Regular.ttf";
import { Routes, Route, useLocation } from "react-router-dom";
import DashboardPage from "./containers/DashboardPage/DashboardPage";
import PaymentsPage from "./containers/PaymentsPage/PaymentsPage";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import AdminProfile from "./containers/AdminProfile/AdminProfile";
import { useState, useEffect } from "react";
import Attendance from "./containers/Attendance/Attendance";
import { StoreProvider } from "easy-peasy";
import { store } from "./Services/Models";
import LoginContainer from "./containers/LoginContainer/LoginContainer";
import AcademicsPage from "./containers/AcademicsPage/AcademicsPage";
import Profiles from "./containers/Profiles/Profiles";
import StudentDashboardPage from "./containers/StudentDashboardPage/StudentDashboardPage";
import MyProfileStudent from "./containers/MyProfileStudent/MyProfileStudent";
import ForgetPassword from "./containers/ForgetPassword/ForgetPassword";
import AdummyTimetable from "./containers/TimetableBox/TimetableBox";
import MultipleFilter from "./components/MultipleFilter/MultipleFilter";
import StudentRegisterContainer from "./containers/StudentRegisterContainer/StudentRegisterContainer";
import TeacherProfilePage from "./containers/TeacherProfilePage/TeacherProfilePage";
import StudentLogin from "./containers/StudentLogin/StudentLogin";
import LoginRegResetSuccessPage from "./containers/LoginRegResetSuccessPage/LoginRegResetSuccessPage";
import CourseListFilter from "./components/CourseListFilter/CourseListFilter";
import PayFilterPopup from "./components/PayFilterPopup/PayFilterPopup";
import ReceivedPayFilterPopup from "./components/ReceivedPayFilterPopup/ReceivedPayFilterPopup";
import AttendanceFilterPopup from "./components/AttendanceFilterPopup/AttendanceFilterPopup";
import MarkAttendancePopup from "./components/MarkAttendancePopup/MarkAttendancePopup";
import LandingPage from './containers/landing/Pages/Landingpage/LandingPage'
import GradientLandingPage from "./containers/GradientLandingPage/GradientLandingPage";

function App() {
  const [section, setSection] = useState("dashboard");
  const [studlist, setstudlist] = useState([]);
  const [academyData, setAcademyData] = useState({
    academyName: "",
    academyEmail: "",
    academyImage: "",
  });
  const [headerAdminDp, setHeaderAdminDp] = useState("");
  const [adminUpdate, setAdminUpdate] = useState(false);

  const handleSubmit = async () => {
    // const studlistresp = await apiCall("/student/Stud_List", {});
    // setstudlist(studlistresp.data);
  };

  const location = useLocation();

  useEffect(() => {
    setSection(location.pathname.split("/")[1]);
  }, [location]);
  // console.log(section);

  useEffect(() => {
    handleSubmit();
  }, [section]);

  return (
    <StoreProvider store={store}>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<LandingPage/>} /> */}
          <Route path="/" element={<GradientLandingPage/>} />
          <Route path="/login" element={<LoginContainer />} />
          <Route
            path="/dashboard"
            element={
              <DashboardPage
                headerAdminDp={headerAdminDp}
                section={section}
                setstudlist={setstudlist}
                academyData={academyData}
              />
            }
          />
          <Route
            path="/profiles"
            element={
              <Profiles
                headerAdminDp={headerAdminDp}
                studlist={studlist}
                setstudlist={setstudlist}
                academyData={academyData}
                section={section}
              />
            }
          />
          <Route
            path="/payments"
            element={
              <PaymentsPage
                headerAdminDp={headerAdminDp}
                section={section}
                academyData={academyData}
              />
            }
          />
          <Route
            path="/academics"
            element={
              <AcademicsPage
                headerAdminDp={headerAdminDp}
                section={section}
                academyData={academyData}
              />
            }
          />
          <Route
            path="/profile/:userid"
            element={
              <ProfilePage
                headerAdminDp={headerAdminDp}
                academyData={academyData}
              />
            }
          />
          <Route
            path="/teacherProfile/:teacherId"
            element={
              <TeacherProfilePage
                section={section}
                // headerAdminDp={headerAdminDp}
                //   academyData={academyData}
              />
            }
          />
          <Route
            path="/adminprofile"
            element={
              <AdminProfile
                adminUpdate={adminUpdate}
                setAdminUpdate={setAdminUpdate}
                headerAdminDp={headerAdminDp}
                academyData={academyData}
                section={section}
              />
            }
          />
          <Route
            path="/studentattendance"
            element={
              <Attendance
                headerAdminDp={headerAdminDp}
                section={section}
                academyData={academyData}
              />
            }
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/timetable" element={<AdummyTimetable />} />
          <Route path="/studentdashboard" element={<StudentDashboardPage />} />

          <Route path="/MyProfileStudent" element={<MyProfileStudent />} />

          <Route path="/registration" element={<StudentRegisterContainer />} />
          <Route path="/teacher" element={<TeacherProfilePage />} />
          <Route path="/studentLogin" element={<StudentLogin />} />
          <Route path="/redirect" element={<LoginRegResetSuccessPage />} />

          {/* <Route path="/filter" element={<MultipleFilter/>} />
<Route path="/course2" element={<CourseListFilter/>} /> */}

          <Route path="/mark" element={<MarkAttendancePopup />} />
        </Routes>
      </div>
    </StoreProvider>
  );
}

export default App;
