import React from 'react'
import HeaderSection from '../../components/HeaderSection/HeaderSection'
import ProfileBar from '../../components/ProfileBar/ProfileBar'
import "../ProfilePage/ProfilePage.css"
import SuccessFailPopup from '../../components/SuccessFailPopup/SuccessFailPopup'
import { useState } from 'react'
import apiCall, { baseUrl } from '../../Services/apiCall'
import { TeacherImgUploadRoute, TeacherProfileUpdateRoute, TeacherProfileViewRoute } from '../../Services/apiServices'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import TabProfileNavbar from '../../components/TabProfileNavbar/TabProfileNavbar'
import ImgUploadPopup from '../../components/ImgUploadPopup/ImgUploadPopup'

const TeacherProfilePage = ({section}) => {

  let { teacherId } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });

  }, []);

 
  

    const [teacherDetails, setteacherDetails] = useState({
        name:"",
        subject:"",
        email:"",
        phone:"",
        address:"",
        image:""
    });
    const [teacherImgChangePopup, setTeacherImgChangePopup] = useState(false)
    const [teacherUpdtSuccess, setTeacherUpdtSuccess] = useState(false)
    const [teacherChangeImgfile, setTeacherChangeImgfile] = useState(null);
 
    
   
    

    const teacherProfileViewSubmit=async()=>{
        const route = TeacherProfileViewRoute();
        const resp = await apiCall(route,{
            Id:teacherId?teacherId:""
        });
        if(resp.Status==="success"){
            setteacherDetails({
                ...teacherDetails,
                name:resp.Data[0]?.name,
                subject:resp.Data[0].subject[0]?.subject_name,
                email:resp.Data[0]?.email,
                phone:resp.Data[0]?.phone_no,
                address:resp.Data[0]?.address,
                image:resp.Data[0]?.image
            });
        }
        else if(resp.Status==="no_data"){
            setteacherDetails([])
        }
        else if(resp.Status==='token_is_invalid'){
         navigate('/login')
        }
    }

    const teacherImgSubmit=async()=>{
      const route = TeacherImgUploadRoute();
      const resp = await apiCall(route,{
        Image:teacherChangeImgfile?teacherChangeImgfile:""
      },true);
      if(resp.Status==="success"){    
        setteacherDetails({
          ...teacherDetails,image:resp.Image});
          setTeacherImgChangePopup(false)
          console.log(resp);
      }
      else if(resp.status==='token_is_invalid'){
           navigate('/login')
          }
    }

    const teacherProfileUpdate=async(e)=>{
      e.preventDefault();
      const route = TeacherProfileUpdateRoute();
      const resp  = await apiCall(route,{
        Id : teacherId?teacherId:"",
        Name: teacherDetails.name?teacherDetails.name:"",
        PhoneNo : teacherDetails.phone?teacherDetails.phone:"",
        Address : teacherDetails.address?teacherDetails.address:"",
        Image:teacherDetails.image?teacherDetails.image:""
      });
      if(resp.Status==="success"){
        setTeacherUpdtSuccess(true);
        setteacherDetails({
          ...teacherDetails,
          name:resp.Data[0]?.name,
          subject:resp.Data[0].subject[0]?.subject_name,
          email:resp.Data[0]?.email,
          phone:resp.Data[0]?.phone_no,
          address:resp.Data[0]?.address,
          image:resp.Data[0]?.image
      });
      
      }
      else if(resp.Status==="no_data"){
        setteacherDetails([])
    }
    else if(resp.Status==='token_is_invalid'){
     navigate('/login')
    }
    }

    useEffect(() => {
        teacherProfileViewSubmit();
    }, [])
    

  return (
    <div className="adminprofile-container">
   

    {teacherUpdtSuccess ? (
      <div className="logout-popup">
        <SuccessFailPopup
          type="success"
          text="Profile Updated"
          setclosePopup={setTeacherUpdtSuccess}
        />
      </div>
    ) : (
      ""
    )}

    {teacherImgChangePopup ? (
      <div className="logout-popup">
        

<ImgUploadPopup 
         setImgToBackend={setTeacherChangeImgfile} 
         setChangeImgPop={setTeacherImgChangePopup}
         updateImgSubmit={teacherImgSubmit}
         />
      </div>
    ) : (
      ""
    )}

<div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

    <div>
      <HeaderSection
        section="teacherprofile"
        // academyData={academyData}
        // headerAdminDp={headerAdminDp}
      />
    </div>

    <div className="admin-profilebar-container">
      <ProfileBar
        type="teacher"
        // setLogoutPopup={setLogoutPopup}
        setteacherDetails={setteacherDetails}
        teacherDetails={teacherDetails}
        setTeacherImgChangePopup={setTeacherImgChangePopup}
        teacherProfileUpdate={teacherProfileUpdate}
      />
    </div>
  </div>
  )
}

export default TeacherProfilePage