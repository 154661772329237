import React from "react";
import "./TimetableBox.css";
import { timeslot_data } from "../../Arraydata";
import { days_data } from "../../Arraydata";
import { useState } from "react";
import { timetablesubject } from "../../Arraydata";
import add from "./add.svg";
import { ViewDays, ViewTimetable } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useEffect } from "react";

const TimetableBox = ({
  timetableFilterClass,
  timetableFilterDivision,
  setAddTimeslot,
  classtotimeslot,
  slotData,
  setAddPeriodPopup,
  periodAddData,
  setPeriodAddData,
  addPeriodPopup,
}) => {
  const [daysdata, setdaysdata] = useState([]);
  // const [timedata, settimedata] = useState(slotData);
  const [viewData, setViewData] = useState([]);

  const viewdaysSubmit = async () => {
    const route = ViewDays();
    const resp = await apiCall(route, {
      Class: timetableFilterClass === "Class" ? "" : timetableFilterClass,
      Division:
        timetableFilterDivision === "Division" ? "" : timetableFilterDivision,
    });
    if (resp.Status === "success") {
      setdaysdata(resp);
    }
  };

  const ViewTimeTable = async () => {
    const route = ViewTimetable();
    const response = await apiCall(route, {
      Class: timetableFilterClass ? timetableFilterClass : "",
      Division: timetableFilterDivision ? timetableFilterDivision : "",
    });
    if (response.status === "success") {
      setViewData(response?.data);
    }
  };

  useEffect(() => {
    viewdaysSubmit();
    ViewTimeTable();
  }, [timetableFilterClass, timetableFilterDivision, addPeriodPopup]);

  return (
    <div className="TimetableBox-container">
      <div className="TimetableBox-timeColumn">
        <div>
          {[null, ...slotData]?.map((times, index) => {
            return (
              <div style={{ display: "flex" }} key={index}>
                <div key={index} className="TimetableBox-content time-div">
                  {times ? `${times?.StartTime} - ${times?.EndTime}` : "TIME"}
                </div>

                <div style={{ display: "flex" }}>
                  {daysdata.Data?.map((daydata, dayindex) => {
                    return (
                      <div
                        className="TimetableBox-content"
                        key={dayindex}
                        onDoubleClick={() => {
                          console.log(times.id, daydata.id);
                          setPeriodAddData({
                            ...periodAddData,
                            timeslotId: times.id,
                            dayId: daydata.id,
                          });
                          setAddPeriodPopup(true);
                        }}
                      >
                        {!times ? (
                          <div className="day-div">{daydata.day}</div>
                        ) : (
                          <PeriodBox
                            data={
                              viewData
                                .find((d) => d.period_id === times.id)
                                ?.timetable_data.find(
                                  (s) => s.day === daydata.day
                                )?.subject
                            }
                            teacher={
                              viewData
                                .find((d) => d.period_id === times.id)
                                ?.timetable_data.find(
                                  (s) => s.day === daydata.day
                                )?.teacher
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {timetableFilterClass !== "Class" && (
          <div
            className="TimetableBox-content time-div"
            onClick={() => setAddTimeslot(true)}
          >
            <img src={add} height="30px" alt="" />
            {/* <svg viewBox="0 0 24 24" height={'40px'} fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 7V13M11 10H17M14 21C11 21 8 21 5 21C3.89543 21 3.00001 20.1069 3.00001 19.0023C3 16.2888 3 11.5934 3 10M9 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H9C7.89543 3 7 3.89543 7 5V15C7 16.1046 7.89543 17 9 17Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg> */}
          </div>
        )}
      </div>
    </div>
  );
};

const PeriodBox = ({ data, teacher }) => {
  if (data && teacher)
    return (
      <div className="period-container">
        <div className="period-sub">{data}</div>
        <div className="period-teacher">{teacher}</div>
      </div>
    );
  return <label className="doubleclick-lbl">double click to add</label>;
};

export default TimetableBox;
