import React from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./attendance.css";
import { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import AttendanceComponent from "../../components/AttendanceComponent/AttendanceComponent";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import attendreport from "./assets/attendreport.svg";
import ClassDisplayBox from "../../components/ClassDisplayBox/ClassDisplayBox";
import IconButton from "../../components/IconButton/IconButton";
import downloadicon from "./assets/dwnld.svg";
import filtericon from "./assets/filter.svg";
import ListCardAttendance from "../../components/ListCardAttendance/ListCardAttendance";
import { AbsentFullListRoute, AttendanceDateFilterRoute, AttendanceMonthFilterRoute, AttendanceTodayList } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AttendanceFilterPopup from "../../components/AttendanceFilterPopup/AttendanceFilterPopup";
import UnavailablePopup from "../../components/UnavailablePopup/UnavailablePopup";
import AbsentFilterPopup from "../../components/AbsentFilterPopup/AbsentFilterPopup";

function Attendance({ section }) {
  const [attendDwnloadPop, setAttendDwnloadPop] = useState(false);
  const [absentDwnldPopup, setAbsentDwnldPopup] = useState(false)
  const [todayAttendanceData,SettodayAttendanceData] = useState({})
  const [attendFiltPopup, setAttendFiltPopup] = useState(false)
  const [absentFiltPopup, setAbsentFiltPopup] = useState(false)
  const [attendanceDateFilter, setAttendanceDateFilter] = useState({
    date:"",
    class:"",
    division:""
  })
  const [attendanceMonthFilter, setAttendanceMonthFilter] = useState({
    month:"",
    class:"",
    division:""
  })
  const [filterSelectInput, setFilterSelectInput] = useState("")
  const [attendanceFileName, setAttendanceFileName] = useState("")
  const [monthAttendStatus, setMonthAttendStatus] = useState("");
  const [absentDateFilter, setAbsentDateFilter] = useState({
    date:"",
    class:"",
    division:""
  })
  const [sortType, setSortType] = useState("")
  const[attendanceresponse,setAttendanceResponse] = useState("")

const [loading,setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {

  
    return () => {
      setAttendanceResponse("")
    }
  }, [])
  
  const Todayattendance = async()=>{
    setLoading(true)
    const route = AttendanceTodayList()
    const response = await apiCall(route,{},false,"PUT")
    if(response.Status==='success'){
      setAttendanceResponse(response.Status)
      SettodayAttendanceData(response)
      setLoading(false)
    }else if(response.Status === 'no_data'){
      setLoading(false)
      setAttendanceResponse(response.Status)
      SettodayAttendanceData(response)
    }else if(response.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }

  const dateFilterAttendanceSubmit=async()=>{
    setLoading(true)
    const route = AttendanceDateFilterRoute();
    const resp = await apiCall(route,{
    Date: attendanceDateFilter.date?attendanceDateFilter.date:"",
    Class: attendanceDateFilter.class?attendanceDateFilter.class:"",
    Division: attendanceDateFilter.division?attendanceDateFilter.division:""
    },false,"PUT");
    if(resp.Status==='success'){
      setLoading(false)
      setAttendFiltPopup(false);
      SettodayAttendanceData(resp)
      setAttendanceResponse(resp.Status)
    }else if(resp.Status === 'no_data'){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp)
      setAttendFiltPopup(false);
    }
    
    else if(resp.Status ==="no_students"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAttendFiltPopup(false);
    }
    else if(resp.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }

  const dateFilterAttendanceDownload=async()=>{
    setLoading(true)
    const route = AttendanceDateFilterRoute();
    const resp = await apiCall(route,{
    Date: attendanceDateFilter.date?attendanceDateFilter.date:"",
    Class: attendanceDateFilter.class?attendanceDateFilter.class:"",
    Division: attendanceDateFilter.division?attendanceDateFilter.division:"",
    DownloadMethod:"PDF"
    });
    if(resp.Status==='success'){
      setLoading(false)
      setAttendanceFileName(resp.FileName)
      setAttendDwnloadPop(true) 
      setAttendanceResponse(resp.Status)

    }else if(resp.Status === 'no_data'){
      setLoading(false)
      setAttendanceFileName("")
      setAttendanceResponse(resp.Status)
    }
    else if(resp.Status ==="no_students"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAttendFiltPopup(false);
      setAttendanceResponse(resp.Status)

    }
    else if(resp.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }
  

  const monthFilterAttendanceSubmit=async()=>{
    setLoading(true)
    const route = AttendanceMonthFilterRoute();
    const resp = await apiCall(route,{
      month: attendanceMonthFilter.month?attendanceMonthFilter.month:"",
    Class: attendanceMonthFilter.class?attendanceMonthFilter.class:"",
    Division: attendanceMonthFilter.division?attendanceMonthFilter.division:""
    },false,"PUT");
    if(resp.Status==='success'){
      setLoading(false)
      setAttendFiltPopup(false);
      SettodayAttendanceData(resp)
      setAttendanceResponse(resp.Status)

    }else if(resp.Status === 'no_data'){
      setLoading(false)
      SettodayAttendanceData(resp)
      setAttendanceResponse(resp.Status)
      setMonthAttendStatus("No Data !")
    }
    else if(resp.Status ==="no_students"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAttendFiltPopup(false);
    }
    else if(resp.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }

  const monthFilterAttendanceDownload=async()=>{
    setLoading(true)
    const route = AttendanceMonthFilterRoute();
    const resp = await apiCall(route,{
      month: attendanceMonthFilter.month?attendanceMonthFilter.month:"",
    Class: attendanceMonthFilter.class?attendanceMonthFilter.class:"",
    Division: attendanceMonthFilter.division?attendanceMonthFilter.division:"",
    DownloadMethod:"PDF"
    });
    if(resp.Status==='success'){
      setLoading(false)
      setAttendanceFileName(resp.FileName)
      setAttendanceResponse(resp.Status)

      setAttendDwnloadPop(true)    
    }else if(resp.Status === 'no_data'){
      setAttendanceFileName("")
      setLoading(false)
      setAttendanceResponse(resp.Status)
    }
    else if(resp.Status ==="no_students"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAttendFiltPopup(false);
    }
    else if(resp.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }

  const absentListView=async()=>{
    setLoading(true)
    const route = AbsentFullListRoute();
    const resp = await apiCall(route,{
      Date:absentDateFilter.date?absentDateFilter.date:"",
      Class:absentDateFilter.class?absentDateFilter.class:"",
      Division:absentDateFilter.division?absentDateFilter.division:"",
      DownloadMethod:""

    },false,"PUT");
    if(resp.Status==='success'){
      setLoading(false)
      setAbsentFiltPopup(false)
      SettodayAttendanceData(resp);   
      setAttendanceResponse(resp.Status)

    }else if(resp.Status === 'no_students_present'){
      setLoading(false)
      setAbsentFiltPopup(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp);  }
    else if(resp.Status ==="no_absent"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAbsentFiltPopup(false)
    }
    else if(resp.Status ==="no_students"){
      setLoading(false)
      setAttendanceResponse(resp.Status)
      SettodayAttendanceData(resp); 
      setAbsentFiltPopup(false)
    }
    else if(resp.Status === 'token_is_invalid'){
      navigate('/login')
    }
  }

  const attendDownload=()=>{
    monthFilterAttendanceDownload();
    dateFilterAttendanceDownload()
  }

  const showFiltered = filterSelectInput?filterSelectInput:"Today";


  useEffect(() => {
    Todayattendance();
  }, [])

  useEffect(() => {
    setFilterSelectInput("");
  }, [sortType])
  

  return (
    <div className="attendancepagecontainer">
      {attendDwnloadPop ? (
        <div className="nodata-status-div">
          <DeleteWarnPopup type="download" setWarning={setAttendDwnloadPop} dwnldFilename={attendanceFileName} />
        </div>
      ) : (
        ""
      )}
      

      {absentDwnldPopup ? (
        <div className="nodata-status-div">
          <UnavailablePopup setUnavailablePopup={setAbsentDwnldPopup}/>
        </div>
      ) : (
        ""
      )}

{attendFiltPopup ? (
        <div className="nodata-status-div">
         <AttendanceFilterPopup
          setAttendFiltPopup={setAttendFiltPopup}
          dateFilterAttendanceSubmit={dateFilterAttendanceSubmit}
          attendanceDateFilter={attendanceDateFilter}
           setAttendanceDateFilter={setAttendanceDateFilter}
           attendanceMonthFilter={attendanceMonthFilter}
           setAttendanceMonthFilter={setAttendanceMonthFilter}
           monthFilterAttendanceSubmit={monthFilterAttendanceSubmit}
           setFilterSelectInput={setFilterSelectInput}
           monthAttendStatus={monthAttendStatus}
            setMonthAttendStatus={setMonthAttendStatus}
           
         />
        </div>
      ) : (
        ""
      )}

{absentFiltPopup ? (
        <div className="nodata-status-div">
       <AbsentFilterPopup
       setAbsentFiltPopup={setAbsentFiltPopup}
       absentDateFilter={absentDateFilter}
       setAbsentDateFilter={setAbsentDateFilter}
       setFilterSelectInput={setFilterSelectInput}
       absentListView={absentListView}
       />
        </div>
      ) : (
        ""
      )}

      <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

      <div className="header-section-container">
        <HeaderSection section={section} />
      </div>

      <div className="table-stud">
        <AttendanceComponent 
        setAttendFiltPopup={setAttendFiltPopup}
        setAttendDwnloadPop={setAttendDwnloadPop} 
        showFiltered={showFiltered}
        data={todayAttendanceData}
        attendDownload={attendDownload}
        absentListView={absentListView}
        Todayattendance={Todayattendance}
        setAbsentDwnldPopup={setAbsentDwnldPopup}
        setAbsentFiltPopup={setAbsentFiltPopup}
        sortType={sortType}
        setSortType={setSortType}
        loading={loading}
        attendanceresponse={attendanceresponse}
        />


        
      </div>

      <div className="attendanceMobile">
        <div className="attendanceMobile-head">
          <img src={attendreport} alt="" />
          <p>ATTENDANCE REPORT</p>
          <ClassDisplayBox std={showFiltered} />
        </div>

        <div className="attendanceMobile-btns">
          <div onClick={attendDownload}><IconButton img={downloadicon} /></div>
          <div onClick={()=>setAttendFiltPopup(true)}><IconButton img={filtericon} /></div>
        </div>

        <div className="attendanceMobile-bottom">
          {todayAttendanceData?.Data?.map((data,index)=>{
            return(
            <ListCardAttendance data={data} key={index}/> 
            // <div key={index}>hi</div>

            )
          })}
      
        </div>
      </div>
    </div>
  );
}

export default Attendance;
