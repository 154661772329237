
export const BasicPlan={
  title:"Basic Plan",
  price:150,
  description:[
  "Rs. 49 monthly/student",
  "Printed RFID Cards",
"Whatsapp Messaging Service",
"Software",
"Software & Hardware services",
"Parent Web Login",
"Ring Device (Rs 6000 extra/piece without a charger in the box or with charger +500Rs will be included)"
]
};

export const ProPlan={
  title:"Pro Plan",
  price:250,
  description:[
  "Rs. 49 monthly/student",
"Printed RFID Cards",
"WhatsApp Messaging service",
"Custom Notifications",
"Software",
"Software & Hardware services",
"Parent Web Login",
"Tag",
"Holder",
"Ring Device (Rs 6000 extra/piece without a charger in the box or with charger +500Rs will be included)"
]
}
;

export const proPlusPlan={
  title:"Pro+ Plan",
  price:350,
  description:[
  "Rs. 49 monthly/student",
"Printed RFID cards",
"WhatsApp messaging service",
"Custom Notifications",
"Software",
"Software & Hardware services",
"Parent Web Login",
"Printed Tags",
"Premium holders",
"Premium design templates",
"Tags with color options",
"Ring Device (Rs 6000 extra/piece without a charger in the box or with charger +500Rs will be included)"
]
};




export const genderData=["Male","Female"]
export const bloodGroupData=["B+","B-","A+","A-","O+","O-","AB+","AB-"]
export const durationData=["1 month","2 months","3 months","6 months","1 year","2 years","3 years","4 years","5 years"]
export const dayValues = [
  
  {
    "key":"MONDAY",
    "value":false
},
{
    "key":"TUESDAY",
    "value":false
},
{
    "key":"WEDNESDAY",
    "value":false
},
{
    "key":"THURSDAY",
    "value":false
},
{
    "key":"FRIDAY",
    "value":false
},
{
    "key":"SATURDAY",
    "value":false
},
{
    "key":"SUNDAY",
    "value":false
}

];
export const monthValues = ['january', 'february', 'march','april','may','june','july','august','september','october','november','december'];

export const allstud_data=[
  {
    "data": [
      {
        "Address": "sss", 
        "AdmNo": "GAJL10445", 
        "Class": "10", 
        "Division": "B", 
        "Gaurdian": null, 
        "Name": "amal", 
        "ParentPhone": "+918877445667"
      }, 
      {
        "Address": null, 
        "AdmNo": "GAJL10446", 
        "Class": "6", 
        "Division": "A", 
        "Gaurdian": null, 
        "Name": "sanju", 
        "ParentPhone": "+918893389913"
      }, 
      {
        "Address": null, 
        "AdmNo": "GAJL10447", 
        "Class": "10", 
        "Division": "B", 
        "Gaurdian": null, 
        "Name": "sangeetha", 
        "ParentPhone": "+918848594643"
      }, 
      {
        "Address": null, 
        "AdmNo": "GAJL10448", 
        "Class": "10", 
        "Division": "A", 
        "Gaurdian": null, 
        "Name": "anand", 
        "ParentPhone": "+919188277372"
      }
    ], 
    "status": "success"
  }
];

export const  newclassdivision =[
  {
    "course": [
        {
            "class": "10",
            "division": ["B","A"]
        },
        {
            "class": "6",
            "division": ["A"]
        },
        {
          "class": "Bsc Computer Science",
          "division": ["2nd semester"]
      },
      {
        "class": "Msc Bio technology",
        "division": []
    }
  ]
}
]

export const accordiondata=
{
    "datas": [
      {
        "class": "12", 
        "total_students": 240,
        "classwise_data": [
          {
            "Class": "12", 
            "Division": "A",  
            "students": "49", 
            "total_fee":5600,

          }, 
          {
            "Class": "12", 
            "Division": "B",  
            "students": "49", 
            "total_fee":5600,

          }
          
        ]
      }, 
      {
        "class": "5", 
        "total_students": "120",
        "classwise_data": [
            {
                "Class": "5", 
                "Division": "A",  
                "students": "49", 
                "total_fee":5600,

              }, 
              {
                "Class": "5", 
                "Division": "B",  
                "students": "49", 
                "total_fee":5600,

              }
        ]
      },
      {
        "class": "Bsc Computer Science", 
        "total_students": "63",
        "classwise_data": [
          {
            "Class": "Bsc Computer Science", 
            "Division": "2nd year",  
            "students": "23", 
            "total_fee":5600,
            "id": 77
          }, 
          {
            "Class": "Bsc Computer Science", 
            "Division": "1st year",  
            "students": "32", 
            "total_fee":5600,
            "id": 78
          }
          
        ]
      },


    ], 
    "status": "success"
  }

export const tabtimetable=[
  {
    Class:"Bsc computer science",
    Division:"2nd year",
    Timetable:[
      {
        time:"10:00 AM - 11:00 PM",
        subject:"Trends in computing",
        tutor:"maneesh"
      },
      {
        time:"11:00 AM - 12:00 PM",
        subject:"Digital electronics",
        tutor:"maneesh"
      },
      {
        time:"09:00 AM - 10:00 PM",
        subject:"Software engineering",
        tutor:"maneesh"
      },
      {
        time:"10:00 AM - 11:00 PM",
        subject:"Trends in computing",
        tutor:"maneesh"
      },
      
     
    ]
  }
]


export const tabtimetablenew=
[
      {
        time:"09:00 AM - 10:00 PM",
        subject:"Software engineering",
        tutor:"maneesh"
      },
      {
        time:"10:00 AM - 11:00 PM",
        subject:"Trends in computing",
        tutor:"maneesh"
      },
      {
        time:"11:00 AM - 12:00 PM",
        subject:"Digital electronics",
        tutor:"maneesh",
        ongoing:"active"
      },
      {
        time:"09:00 AM - 10:00 PM",
        subject:"Software engineering bsc computer science",
        tutor:"maneesh"
      },
      {
        time:"11:00 AM - 12:00 PM",
        subject:"Digital electronics",
        tutor:"maneesh"
      },
      {
        time:"10:00 AM - 11:00 PM",
        subject:"Trends in computing",
        tutor:"maneesh"
      },
      {
        time:"11:00 AM - 12:00 PM",
        subject:"Digital electronics",
        tutor:"maneesh"
      },
      {
        time:"09:00 AM - 10:00 PM",
        subject:"Software engineering",
        tutor:"maneesh"
      },
      
     
    ];


 export const timeslot_data = 
  {
    "Status": "success",
    "data": [
        {
            "EndTime": "10:00",
            "StartTime": "09:00",
            "id": 11
        },
        {
            "EndTime": "11:00",
            "StartTime": "10:00",
            "id": 12
        },
        {
            "EndTime": "12:00",
            "StartTime": "11:00",
            "id": 13
        },
        {
            "EndTime": "18:00",
            "StartTime": "15:00",
            "id": 18
        }
    ]
}
    





  
export const classdata = [
  {
    class: "I",
    div: "A",
    present: "43",
    total: "45",
    subject: "Maths",
  },

  {
    class: "II",
    present: "40",
    div: "B",
    total: "45",
    subject: "English",
  },

  {
    class: "III",
    present: "20",
    div: "F",
    total: "45",
    subject: "EVS",
  },

  {
    class: "IV",
    present: "30",
    div: "C",
    total: "35",
    subject: "Malayalam",
  },

  {
    class: "V",
    present: "36",
    div: "D",
    total: "42",
    subject: "Hindi",
  },

  {
    class: "VI",
    present: "33",
    div: "N",
    total: "46",
    subject: "English",
  },

  {
    class: "VII",
    present: "37",
    div: "D",
    total: "43",
    subject: "Social Science",
  },

  {
    class: "VIII",
    present: "36",
    div: "E",
    total: "45",
    subject: "Maths",
  },

  {
    class: "IX",
    present: "27",
    div: "J",
    total: "44",
    subject: "History",
  },

  {
    class: "X",
    present: "37",
    div: "A",
    total: "45",
    subject: "Geography",
  },

  {
    class: "XI",
    present: "44",
    div: "G",
    total: "48",
    subject: "Chemistry",
  },

  {
    class: "XII",
    present: "45",
    div: "K",
    total: "52",
    subject: "Biology",
  },
];

export const studentdata = [
  {
    adId: 1001,
    name: "Aparna Js",
    class: "X",
    div: "A",
    phone: "92663762353",
    checkin: "09:50 am",
    checkout: "01:00 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1112,
    name: "Vinod Kumar",
    class: "XII",
    div: "C",
    phone: "8823442342",
    checkin: "10:07 am",
    checkout: "01:30 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1023,
    name: "Vimal J",
    class: "IX",
    div: "E",
    phone: "9258357737",
    checkin: "10:00 am",
    checkout: "01:40 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1341,
    name: "Arun AS",
    class: "VII",
    div: "B",
    phone: "885634243",
    checkin: "10:20 am",
    checkout: "01:25 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1025,
    name: "John Ebraham",
    class: "XI",
    div: "F",
    phone: "92663762353",
    checkin: "10:00 am",
    checkout: "01:15 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1341,
    name: "Sanjay B",
    class: "VI",
    div: "G",
    phone: "992342839",
    checkin: "10:10 am",
    checkout: "01:10 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1201,
    name: "Jinu John",
    class: "VII",
    div: "F",
    phone: "9976352579",
    checkin: "10:05 am",
    checkout: "01:24 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1456,
    name: "Ganga R",
    class: "XII",
    div: "A",
    phone: "97355235465",
    checkin: "10:20 am",
    checkout: "01:22 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1867,
    name: "Vipin M",
    class: "X",
    div: "C",
    phone: "92663762353",
    checkin: "10:14 am",
    checkout: "01:00 pm",
    date: "12-03-2022",
    purpose: "tution fee",
    month: "october",
    amount: "1000",
    expiry: "14-10-2022",
  },
  {
    adId: 1667,
    name: "Vinod M",
    class: "XII",
    div: "R",
    phone: "9264362353",
    checkin: "10:15 am",
    checkout: "01:10 pm",
    date: "13-03-2022",
    purpose: "admission fee",
    month: "february",
    amount: "2000",
    expiry: "18-10-2022",
  },
  {
    adId: 3467,
    name: "Athul J",
    class: "VII",
    div: "B",
    phone: "9231122353",
    checkin: "10:25 am",
    checkout: "01:14 pm",
    date: "15-03-2022",
    purpose: "tution fee",
    month: "march",
    amount: "1000",
    expiry: "22-10-2022",
  },
  {
    adId: 1167,
    name: "Amal Kumar",
    class: "V",
    div: "D",
    phone: "8631122353",
    checkin: "10:00 am",
    checkout: "01:00 pm",
    date: "03-03-2022",
    purpose: "admission fee",
    month: "august",
    amount: "2000",
    expiry: "12-08-2022",
  },
];

export const data = [
  {
    class: "I",
    timetable: [
      {
        subject: "maths",
        tutor: "anu",
        time: "10:00 - 11:00",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
    ],
  },
  {
    class: "II",
    timetable: [
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
    ],
  },

  {
    class: "III",
    timetable: [
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
    ],
  },

  {
    class: "IV",
    timetable: [
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
    ],
  },
  {
    class: "V",
    timetable: [
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
      {
        subject: "maths",
        tutor: "anu",
        time: "10",
      },
    ],
  },
];



export const getFormatedDate = (d) => {
  let date = new Date(d);
  const zeroPadding = (n) => (n < 10 ? "0" + n : n);
  return `${zeroPadding(date.getDate())}/${zeroPadding(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
};

export const getFormatedMonth = (d) => {
  let date = new Date(d);
  const zeroPadding = (n) => (n < 10 ? "0" + n : n);
  return `${zeroPadding(date.getMonth() + 1)}-${date.getFullYear()}`;
};

export const getFormatedMonthSlash = (d) => {
  let date = new Date(d);
  const zeroPadding = (n) => (n < 10 ? "0" + n : n);
  return `${zeroPadding(date.getMonth() + 1)}/${date.getFullYear()}`;
};

export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +"0" + (time[0] % 12) || 12; // Adjust hours
  }
  if (time[0] <= 9) {
    time[0] = "0" + time[0];
    // console.log(time);
    return time.join(""); // return adjusted time or original string
  }else{
    return time.join('')
  }
};

tConvert("18:00:00");





export const days_data = {
  "Status": "success",
  data: [
      {
          day: "Monday",
          "id": 35
      },
      {
          day: "Tuesday",
          "id": 36
      },
      {
          day: "Wednesday",
          "id": 37
      },
      {
          day: "Thursday",
          "id": 38
      },
      {
          day: "Friday",
          "id": 39
      }
  ]
}







export const timetablesubject = [
  {
    id:20,
    period:11,
    subjects:[
      {
        id:1,
        day:'Monday',
        tostudy:'English',
        teacher:"manoj"
      },
      {
        id:2,
        day:'Tuesday',
        tostudy:'chemical science',
        teacher:"Bindhu"
      }
    ]
  },
  {
    id:20,
    period:12,
    subjects:[
      {
        id:1,
        day:'Monday',
        tostudy:'Hindi',
        teacher:"Meera"
      },
      {
        id:2,
        day:'Tuesday',
        tostudy:'Mathematics',
        teacher:"Manjima"
      }
    ]
  }
]


//graph data

export const baroption = {
  responsive: true,

  plugins: {
    title: {
      display: true,
      text: "Student Report Chart",
    },
  },
};

export const bardata = {
  labels:["Jan", "Feb"],
  datasets: [
    {
      label: "Fees Paid",
      data: [ 20, 100, ],
      backgroundColor: "#02E1A8",
    },
    {
      label:'Not Paid',
      data:[15,20],
      backgroundColor:'#A6B8FA'
    },

  ],

};


export const attendanceData = [{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
},{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
},{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
},{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
},{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
},{
  slno:"1",
  adm_no:"2000",
  name:"anandhu",
  class:"sdsadadssds",
  division:"",
  checkin:"",
  checkout:""
}]