import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Fingerprint } from "@mui/icons-material";
import "./style.css";
import MonthYearBtn from "../../components/MonthYearBtn/MonthYearBtn";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
function AttendanceTableStudent({ attendanceData }) {
  return (
    <div className="main">
      <div className="action-sec">
        {/* <div className="ico-sec">
          <Tooltip title="Download">
            <IconButton>
              <CloudDownloadIcon fontSize="large" sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter">
            <IconButton>
              <FilterAltIcon fontSize="large" sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div> */}
      </div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Date</h3>
              </TableCell>
              <TableCell>
                <h3>Check In</h3>
              </TableCell>
              <TableCell>
                <h3>Check Out</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceData?.map((data, index) => {
              return (
                <TableRow
                  sx={{ backgroundColor: index % 2 == 1 ? "#e1e8f2" : "" }}
                >
                  <TableCell>{data?.Date}</TableCell>
                  <TableCell>{data?.Checkin}</TableCell>
                  <TableCell>{data?.Checkout}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AttendanceTableStudent;
