import React from "react";
import "./style.css";

function InputComponent({
  type,
  isRequired,
  onChange,
  placeHolder,
  value,
  pattern,
  name
}) {
  return (
    <>
      <input
      className="inp-style"
      value={value}
        type={type}
        onChange={(e) => onChange(e)}
        placeholder={placeHolder}
        pattern={pattern}
        required={isRequired}
        name={name}
      />
    </>
  );
}

export default InputComponent;
