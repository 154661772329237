import React, { useState } from 'react'
import "./TemporaryidCard.css"
import apiCall from '../../Services/apiCall'
import { AssignTemporaryCard, ViewTemporaryIdList } from '../../Services/apiServices'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

const TemporaryidCard = ({Admissionnumber,setTempidHistoryPopup}) => {
    const navigate = useNavigate()
    const [temporaryidData,setTemporaryIdData] = useState({
        Admissionnumber:'',
        temporaryidnumber:'',
        validupto:''
    })
    const [temporaryIdList,setTemporaryId] = useState([])
    const minDate = new Date().toISOString().slice(0, 10); // get today's date in ISO format

    const TemporaryAssign =async(e)=>{
        e.preventDefault();
        const route = AssignTemporaryCard()
        const response =  await apiCall(route,{
            AdmNo:Admissionnumber?Admissionnumber:'',
            TempIdNo:temporaryidData?temporaryidData.temporaryidnumber:'',
            EndDate:temporaryidData?temporaryidData.validupto:''
        })
        if(response.Status === 'tempid_assigned'){
            toast.success("Temporary ID Assigned Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              })
        }else if(response.Status === "already_assigned"){
            toast.warn("Already Assigned", {
                position: toast.POSITION.TOP_RIGHT,
              })
        }  
         else if(response.Status==='token_is_invalid'){
            navigate('/login')
        
           }
    }

    const clearData =()=>{
        setTemporaryIdData({
            Admissionnumber:'',
            temporaryidnumber:'',
            validupto:''
        })
    }

    const TemporaryIdListView =async()=>{
        const route = ViewTemporaryIdList()
        const response = await apiCall(route,{})
        if(response.Status === 'success'){
            setTemporaryId(response?.Data)
        }else if(response.Status === 'token_is_invalid'){
            navigate('/login')
        }
    }
    useEffect(() => {
        TemporaryIdListView();
    }, [])
    
    
  return (
    <form className='tempCard-container' onSubmit={TemporaryAssign}>
        
        <div className="tempCard-head">
            Temporary ID
        </div>

        <div className="tempCard-middle">
            <div className='tempCard-inputs'>
                <p>ID :</p>
                <select name="" id="" onChange={(e)=>{setTemporaryIdData({...temporaryidData,temporaryidnumber:e.target.value});}} required>
                    <option value="">Select a Temp ID</option>
                   {temporaryIdList?.map((data,index)=>{
                    return(
                        <option value={data?.temp_id_no} key={index}>{data?.temp_id_no}</option>

                    )
                   })}
                   
                </select>
            </div>
            <div className='tempCard-inputs'>
                <p>Valid till : </p>
                <input type="date" min={minDate} onChange={(e)=>{setTemporaryIdData({...temporaryidData,validupto:e.target.value})}} required/>
            </div>
        </div>

        <div className="tempCard-bottom">
            <button type="reset" className='tempCard-cancel-btn btn-outline' onClick={clearData} >Clear</button>
            <button type='submit' className='tempCard-assign-btn btn-outline' >Assign</button>
        </div>

        <div className="tempCard-bottom-show">
          <div className="tempCard-show-btn" onClick={()=>setTempidHistoryPopup(true)}>Show usage history</div>
        </div>

    </form>
  )
}

export default TemporaryidCard