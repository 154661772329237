// import React, { useEffect, useState } from 'react';
// import "./feestyle.css"
// import square from "./square.svg"
// import close from "./close.svg"
// import SaveCancelBtn from '../SaveBtn/SaveBtn';

// const FeeAddComponent = ({setAddclassOption,AddCourseSubmit,feeStructure,setFeeStructure}) => {

  

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     AddCourseSubmit();
//     // Send data to backend
//     // You can make an API call to send the data to the backend here
//   };

//   const handleChange = (index, field, value) => {
//     setFeeStructure(prevData => {
//       const newData = [...prevData];
//       newData[index] = { ...newData[index], [field]: value };
//       return newData;
//     });
//   };

//   const handleRemoveInput = (index) => {
//     const updatedInputBoxes = [...feeStructure];
//     updatedInputBoxes.splice(index, 1);
//     setFeeStructure(updatedInputBoxes);
//   };

 

//   const renderInputs = () => {
 
//     return feeStructure.map((item, index) => (
//       <div key={index} className='AddClass-feeStructure'>
//         <img height="12" width="12" onClick={()=>handleRemoveInput(index)}  className='AddClass-feeClose'src={close} alt="" />
//         <select
//         className='AddClass-categoryInput'
//           type="text"
//           placeholder="Category"
        
//           onChange={e => handleChange(index, 'Key', e.target.value)}
//         >
//           <option   value={"admission_fee" || ''}>Admission Fee</option>
//           <option   value={"monthly_fee" || ''}>Monthly Fee</option>
//           <option   value={"other_fee" || ''}>other</option>

//         </select>
//         <div>
//           :
//         </div>
//         <input
//         className='AddClass-feeInput no-scroll'
//           type="number"
//           placeholder="Fees (₹)"
//           value={item.Value || ''}
//           onChange={e => handleChange(index, 'Value', e.target.value)}
//         />
//       </div>
//     ));
//   };


//   return (
//     <form  onSubmit={handleSubmit}>
      
//       <div className='AddClass-feeScroll-box'>
//         <div className='AddClass-feeaddbox'>

//       {renderInputs()}
//       <img className='AddClass-feeadd' onClick={()=>setFeeStructure([...feeStructure,""])} src={square} alt="" />

//       </div>
//       </div>

  

//       <div className="AddClass-btns">

//           <div onClick={()=>setAddclassOption(0)} >
//             <SaveCancelBtn
//             type="back"
//             />
//             </div>

//             <button type='submit' className='btn-outline'>
//             <SaveCancelBtn
//             type="save"
//             text="Next"
//             />
//             </button>
// </div>
     
//     </form>
//   );
// };

// export default FeeAddComponent;
import React, { useEffect, useState } from 'react';
import "./feestyle.css"
import square from "./square.svg"
import close from "./close.svg"
import SaveCancelBtn from '../SaveBtn/SaveBtn';

const FeeAddComponent = ({ setAddclassOption, AddCourseSubmit, feeStructure, setFeeStructure }) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    AddCourseSubmit();
    // Send data to the backend
    // You can make an API call to send the data to the backend here
  };

  const handleChange = (index, field, value) => {
    setFeeStructure(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
  };

  const handleRemoveInput = (index) => {
    const updatedInputBoxes = [...feeStructure];
    updatedInputBoxes.splice(index, 1);
    setFeeStructure(updatedInputBoxes);
  };

  const renderInputs = () => {
    return feeStructure.map((item, index) => (
      <div key={index} className='AddClass-feeStructure'>
        <img height="12" width="12" onClick={() => handleRemoveInput(index)} className='AddClass-feeClose' src={close} alt="" />
        <select
          className='AddClass-categoryInput'
          type="text"
          placeholder="Category"
          onChange={e => handleChange(index, 'Key', e.target.value)}
        >
          <option value="">Type of Fee</option>
          <option value="admission_fee">Admission Fee</option>
          <option value="TutionFee">Monthly Fee</option>
          <option value="other_fee">Other</option>
        </select>
        <div>:</div>
        <input
          className='AddClass-feeInput no-scroll'
          type="number"
          placeholder="Fees (₹)"
          value={item.Value || ''}
          onChange={e => handleChange(index, 'Value', e.target.value)}
        />
      </div>
    ));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='AddClass-feeScroll-box'>
        <div className='AddClass-feeaddbox'>
          {renderInputs()}
          <img className='AddClass-feeadd' onClick={() => setFeeStructure([...feeStructure, ""])} src={square} alt="" />
        </div>
      </div>
      <div className="AddClass-btns">
        <div onClick={() => setAddclassOption(0)}>
          <SaveCancelBtn
            type="back"
          />
        </div>
        <button type='submit' className='btn-outline'>
          <SaveCancelBtn
            type="save"
            text="Next"
          />
        </button>
      </div>
    </form>
  );
};

export default FeeAddComponent;
