import React from "react";
import "./ProfilePage.css";
import { useState, useEffect } from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import DonutChartComponent from "../../components/DonutChartComponent/DonutChartComponent";
import { DateObject } from "react-multi-date-picker";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import MarkAttendancePopup from "../../components/MarkAttendancePopup/MarkAttendancePopup";
import ViewDocumentPopup from "../../components/ViewDocumentPopup/ViewDocumentPopup";
import ProfileBar from "../../components/ProfileBar/ProfileBar";
import TemporaryidCard from "../../components/TemporaryidCard/TemporaryidCard";
import ProfileAttendanceHistory from "../../components/ProfileAttendanceHistory/ProfileAttendanceHistory";
import ProfilepaymentHistory from "../../components/ProfilepaymentHistory/ProfilepaymentHistory";
import OutStandingFee from "../../components/OutStandingFee/OutStandingFee";
import TabProfileHeader from "../../components/TabProfileHeader/TabProfileHeader";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { AcademyStudentImgUploadRoute, IndividualStudentAttendance, OutstandingFees, StudPaymentsHistoryRoute, StudentProfileViewRoute, StudprofileUpdtRoute, studprofileAttendPercent } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import UploadDocumentPopup from "../../components/UploadDocumentPopup/UploadDocumentPopup";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import PaymentPopup from "../../components/PaymentPopup/PaymentPopup";
import ViewTempidUseHistoryPopup from "../../components/ViewTempidUseHistoryPopup/ViewTempidUseHistoryPopup";
import ImgUploadPopup from "../../components/ImgUploadPopup/ImgUploadPopup";
import { ToastContainer } from "react-toastify";

const ProfilePage = ({ academyData, headerAdminDp, section = "profiles" }) => {

  let { userid } = useParams();

  const navigate = useNavigate()
  let keys = localStorage.getItem("adminKey");
  
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [markAttendPopup, setMarkAttendPopup] = useState(false);
  const [changeStudImgPop, setChangeStudImgPop] = useState(false);
  const [studChangImgFile, setStudChangImgFile] = useState(null); //passing to backend
  const [documentPopup, setDocumentPopup] = useState(false);
  const [selected, setSelected] = useState("profile");
  const [addnewDocPopup, setaddnewDocPopup] = useState(false);
  const [uploadSuccessPopup, setUploadSuccessPopup] = useState(false)

  const [profilePayFilter, setprofilePayFilter] = useState(new DateObject())
  const [profilePayMonthFilt, setprofilePayMonthFilt] = useState("")

  const [profileAttendMonth, setProfileAttendMonth] = useState(new DateObject());
  const [profileAttendMonthfilter, setProfileAttendMonthfilter] = useState("");

 const [studDonutValues, setStudDonutValues] = useState([])
 const [profilePayPopup, setProfilePayPopup] = useState(false)
 const [tempidHistoryPopup, setTempidHistoryPopup] = useState(false)
 const [studProfileData, setStudProfileData] = useState({
        address: "",
        bloodgroup: "",
        class:"",
        division:"",
        email: "",
        father: "",
        guardian: "",
        gender: null,
        image: "",
        mother: "",
        name: "",
        guardianphone: "",
        reference:"",
        studentPhone:"",
        dateofbirth:""
 });
 const [attendHistoryDwnldPopup, setAttendHistoryDwnldPopup] = useState(false);
 const [payHistoryDwnldPopup, setPayHistoryDwnldPopup] = useState(false)
 const [profileHistoryDwnldFilename, setprofileHistoryDwnldFilename] = useState({
  attendance:"",
  payments:""
 })
 const [studprofileupdtSuccess, setStudprofileupdtSuccess] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });

  }, []);

 
 

  const studAttendPercentSubmit=async()=>{
    const route = studprofileAttendPercent();
    const resp = await apiCall(route,{
      AdmissionNo:userid?userid:""
    });
    if(resp.Status==="success"){
      setStudDonutValues(resp)
    }
    else if(resp.Status==="no_data"){
      setStudDonutValues([])
    }
    else if(resp.Status==="token_is_invalid"){
      navigate('/login')
    }
  }

  const studProfileViewSubmit=async()=>{
    const route = StudentProfileViewRoute();
    const resp = await apiCall(route,{
      AdmNo: userid?userid:""
    });
    if(resp.Status==="success"){
      setStudProfileData({
        ...studProfileData,
        admno:resp.Data[0]?.AdmNo,
        address:resp.Data[0]?.Address,
        bloodgroup:resp.Data[0]?.BloodGroup,
        class:resp.Data[0]?.Class,
        division:resp.Data[0]?.Division,
        email:resp.Data[0]?.Email,
        father: resp.Data[0]?.FatherName,
        guardian: resp.Data[0]?.Gaurdian,
        image: resp.Data[0]?.Image,
        mother: resp.Data[0]?.MotherName,
        name: resp.Data[0]?.Name,
        guardianphone: resp.Data[0]?.ParentPhone,
        reference:resp.Data[0]?.RefferedBy,
        studentPhone:resp.Data[0]?.StudentPhone,
        dateofbirth:resp.Data[0]?.DOB

      })
    }
    else if(resp.Status==="no_data"){
      setStudProfileData([])
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }

  }

  

  const studImgChangeSubmit=async()=>{
    const route = AcademyStudentImgUploadRoute();
    const resp = await apiCall(route,{
      Image:studChangImgFile?studChangImgFile:""
    },true);
    if(resp.Status==="success"){    
      setStudProfileData({...studProfileData,image:resp.Image});
      setChangeStudImgPop(false)
    }
    else if(resp.status==='token_is_invalid'){
         navigate('/login')
        }
  }

  const studProfileUpdtSubmit=async()=>{
    const route = StudprofileUpdtRoute();
    const resp = await apiCall(route,{
      Class : studProfileData.class?studProfileData.class:"",
      Division:studProfileData.division?studProfileData.division:"",
    Image : studProfileData.image?studProfileData.image:"",
    ParentPhone : studProfileData.guardianphone?studProfileData.guardianphone:"",
    AdmNo :  userid?userid:"",
   Name:studProfileData.name?studProfileData.name:"",
   Address:studProfileData.address?studProfileData.address:""
    })
    if(resp.Status==="success"){
      setStudprofileupdtSuccess(true)
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/login')
     }
  }

  const AttendanceHistoryDownload =async()=>{
    const route = IndividualStudentAttendance()
    const response = await apiCall(route,{
      AdmissionNo:userid?userid:'',
      Month:profileAttendMonthfilter?profileAttendMonthfilter:""
    })
    if(response.Status==="success"){
      setAttendHistoryDwnldPopup(true);
      setprofileHistoryDwnldFilename({...profileHistoryDwnldFilename,attendance:response?.FileName})
    }
    else if(response.Status==="no_data"){
      setprofileHistoryDwnldFilename({...profileHistoryDwnldFilename,attendance:""})
    }
    else if(response.Status==="token_is_invalid"){
      navigate("/login");
    }
}

const payHistoryDownload =async()=>{
  const route = StudPaymentsHistoryRoute()
  const response = await apiCall(route,{
    AdmNo:userid?userid:"",
    Month:profilePayMonthFilt?profilePayMonthFilt:"",
    DownloadMethod:"PDF"
  })
  if(response.Status==="success"){
    setPayHistoryDwnldPopup(true);
    setprofileHistoryDwnldFilename({...profileHistoryDwnldFilename,payments:response?.FileName})
  }
  else if(response.Status==="no_data"){
    setprofileHistoryDwnldFilename({...profileHistoryDwnldFilename,payments:""})
  }
  else if(response.Status==="token_is_invalid"){
    navigate("/login");
  }
}


  useEffect(() => {
    studAttendPercentSubmit();
    studProfileViewSubmit();
  }, [])
  


  

  return (
    <div className="empprofile-container">
      {changeStudImgPop ? (
        <div className="stud-tempid-pop">
         
           <ImgUploadPopup 
         setImgToBackend={setStudChangImgFile} 
         setChangeImgPop={setChangeStudImgPop}
         updateImgSubmit={studImgChangeSubmit}
         />
        </div>
      ) : (
        ""
      )}

{tempidHistoryPopup ? (
        <div className="docadd-div">
        <ViewTempidUseHistoryPopup
        userid={userid}
         setTempidHistoryPopup={setTempidHistoryPopup}
         />
        </div>
      ) : (
        ""
      )}


{studprofileupdtSuccess ? (
         <div className="docadd-div">
          <SuccessFailPopup
            type="success"
            text="Profile Updated"
            setclosePopup={setStudprofileupdtSuccess}
          />
        </div>
      ) : (
        ""
      )}

{attendHistoryDwnldPopup ? (
        <div className="docadd-div">
        <DeleteWarnPopup type="download" setWarning={setAttendHistoryDwnldPopup} dwnldFilename={profileHistoryDwnldFilename.attendance} />
        </div>
      ) : (
        ""
      )}


{payHistoryDwnldPopup ? (
        <div className="docadd-div">
        <DeleteWarnPopup type="download" setWarning={setPayHistoryDwnldPopup} dwnldFilename={profileHistoryDwnldFilename.payments} />
        </div>
      ) : (
        ""
      )}

{addnewDocPopup ? (
        <div className="docadd-div">
         <UploadDocumentPopup
         userid={userid}
         setUploadSuccessPopup={setUploadSuccessPopup}
          setaddnewDocPopup={setaddnewDocPopup}/>
        </div>
      ) : (
        ""
      )}

{uploadSuccessPopup ? (
        <div className="docadd-div">
         <SuccessFailPopup type="success" text="File Uploaded" setclosePopup={setUploadSuccessPopup} />
        </div>
      ) : (
        ""
      )}
      {profilePayPopup ? (
        <div className="docadd-div">
          <PaymentPopup
          // studPayId={userid.replace(keys,"")}
           setFeepopup={setProfilePayPopup} 
          setPaySuccessPopup={false}/>
        </div>
      ) : (
        ""
      )}

      {deleteUserPopup ? (
        <div className="stud-tempid-pop">
          <DeleteWarnPopup setWarning={setDeleteUserPopup} type="user" />
        </div>
      ) : (
        ""
      )}

      {markAttendPopup ? (
        <div className="stud-tempid-pop">
          <MarkAttendancePopup 
          setMarkAttendPopup={setMarkAttendPopup}
          userid={userid}
          />
        </div>
      ) : (
        ""
      )}

      {documentPopup ? (
        <div className="stud-tempid-pop">
          <ViewDocumentPopup
            userid={userid}
            setaddnewDocPopup={setaddnewDocPopup}
            setDocumentPopup={setDocumentPopup}
          />
        </div>
      ) : (
        ""
      )}

      <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

      <div className="profile-page-header">
        <HeaderSection type="studprofile" section="studentprofile" />
      </div>

      <div className="profile-donut-container">
        <DonutChartComponent presentAbsent={studDonutValues} />
      </div>

      <div className="recent-pay-div">
        <TemporaryidCard 
        setTempidHistoryPopup={setTempidHistoryPopup}
        Admissionnumber = {userid}/>
      </div>

      <>
        <div className="tabprofile-header-div">
          <TabProfileHeader selected={selected} setSelected={setSelected} />
        </div>

        {/* components based on selected values */}

        {selected === "profile" ? (
          <div className="selected-profile-tables">
            <div className="profile-wrap">
              <ProfileBar 
              type="student"
               setDocumentPopup={setDocumentPopup}
               userid = {userid}
               studProfileData={studProfileData}
               studProfileUpdtSubmit={studProfileUpdtSubmit}
               setStudProfileData={setStudProfileData}
               setChangeStudImgPop={setChangeStudImgPop}
                />
            </div>

            <div className="tab-feeTempid-container">
              <OutStandingFee Admissionnumber = {userid}/>
              <TemporaryidCard 
              setTempidHistoryPopup={setTempidHistoryPopup}
              Admissionnumber = {userid}/>
            </div>
          </div>
        ) : (
          ""
        )}

        {selected === "attendance" ? (
          <div className="selected-attendance">
            <ProfileAttendanceHistory
            markAttendPopup={markAttendPopup}
             setMarkAttendPopup={setMarkAttendPopup}
              userid={userid}
              profileAttendMonth={profileAttendMonth}
              setProfileAttendMonth={setProfileAttendMonth}
              profileAttendMonthfilter={profileAttendMonthfilter}
              setProfileAttendMonthfilter={setProfileAttendMonthfilter}
              AttendanceHistoryDownload={AttendanceHistoryDownload}
            />
          </div>
        ) : (
          ""
        )}

        {selected === "payments" ? (
          <div className="selected-attendance">
            <ProfilepaymentHistory
              profilePayFilter={profilePayFilter}
              setprofilePayFilter={setprofilePayFilter}
              profilePayMonthFilt={profilePayMonthFilt}
              setprofilePayMonthFilt={setprofilePayMonthFilt}
              userid={userid}
              setProfilePayPopup={setProfilePayPopup}
              payHistoryDownload={payHistoryDownload}
            />
          </div>
        ) : (
          ""
        )}

        {/* components based on selected values */}

        <div className="profile-tables-container">
          <div className="profile-wrap">
            <ProfileBar
             type="student" 
             userid={userid}
            setDocumentPopup={setDocumentPopup} 
            studProfileData={studProfileData}
            studProfileUpdtSubmit={studProfileUpdtSubmit}
            setStudProfileData={setStudProfileData}
            setChangeStudImgPop={setChangeStudImgPop}
             />
          </div>

          <div className="id-payment-boxes">
            <ProfileAttendanceHistory         
              userid={userid}
              markAttendPopup={markAttendPopup}
              setMarkAttendPopup={setMarkAttendPopup}
              profileAttendMonth={profileAttendMonth}
              setProfileAttendMonth={setProfileAttendMonth}
              profileAttendMonthfilter={profileAttendMonthfilter}
              setProfileAttendMonthfilter={setProfileAttendMonthfilter}
              AttendanceHistoryDownload={AttendanceHistoryDownload}
            />
            <ProfilepaymentHistory
              userid={userid}
              profilePayFilter={profilePayFilter}
              setprofilePayFilter={setprofilePayFilter}
              profilePayMonthFilt={profilePayMonthFilt}
              setprofilePayMonthFilt={setprofilePayMonthFilt}
              setProfilePayPopup={setProfilePayPopup}
              payHistoryDownload={payHistoryDownload}
            />
          </div>

          <div className="tab-feeTempid-container">
            <OutStandingFee Admissionnumber = {userid}/>
            <TemporaryidCard
            setTempidHistoryPopup={setTempidHistoryPopup}
             Admissionnumber = {userid} />
          </div>
        </div>
      </>
      L<ToastContainer/>
    </div>
  );
};

export default ProfilePage;
