import React from "react";
import "./NoticeComponent.css";
import noticesvg from "./assets/noticesvg.svg";
import addnotice from "./assets/addnotice.svg";
import SmallBtn from "../SmallBtn/SmallBtn";
import smallfilter from "./assets/smallfilter.svg";
import SmallFilterbtn from "../SmallFilterbtn/SmallFilterbtn";
import { useState } from "react";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { StudLoginNoticeViewRoute, ViewNoticeBoard } from "../../Services/apiServices";
import apiCall from "../../Services/apiCall";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const NoticeComponent = ({
  type,
  setNoticePopup,
  setNoticeId,
  notifyStatus,
}) => {
  const [noticeFilterClick, setnoticeFilterClick] = useState(false);
  const [noticeFilterClass, setNoticeFilterClass] = useState("Class");
  const [noticeFilterDivision, setNoticeFilterDivision] = useState("Division");
  const [noticeData, setNoticeData] = useState([]);
  const navigate = useNavigate();

  const ViewNoticeBoardApi = async () => {
    const route = ViewNoticeBoard();
    const response = await apiCall(route, {
      Classes: noticeFilterClass === "Class" ? "" : noticeFilterClass,
      Division: noticeFilterDivision === "Division" ? "" : noticeFilterDivision,
    });
    if (response.Status === "success") {
      setNoticeData(response.Data);
    } else if (response.Status === "no_data") {
      setNoticeData([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const studNoticeLists=async()=>{
    const route = StudLoginNoticeViewRoute();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setNoticeData(resp.Data);
    } else if (resp.Status === "no_data") {
      setNoticeData([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/studentLogin");
    }
  }


  useEffect(() => {
    if(type==="student"){
        studNoticeLists();
    }
    else if(type==="academy"){
      ViewNoticeBoardApi();
    }
    
  }, [noticeFilterClass, noticeFilterDivision, notifyStatus,type])
  


  return (
    <div
      className={
        type === "student" ? "stud-notice-container" : "notice-container"
      }
    >
      <div className="notice-head">
        <div className="noticesvg-box">
          <img src={noticesvg} alt="" />
        </div>
        <div className="notice-txt">NOTICE BOARD</div>
        {type !== "student"?
        <div
          className="notice-add"
          onClick={() => setnoticeFilterClick(!noticeFilterClick)}
        >
          <SmallFilterbtn />
        </div>:""}

        {noticeFilterClick ? (
          <div className="notice-filter-drop">
            <FilterDropdown
              cls={noticeFilterClass}
              setCls={setNoticeFilterClass}
              div={noticeFilterDivision}
              setDiv={setNoticeFilterDivision}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={type === "student" ? "stud-notice-btm" : "notice-btm"}>
        {noticeData?.map((data, index) => {
          return (
            <div key={index} className="notice-content">
              <div className="green-circle first-point"></div>
              <div
                className="notice-content-txt"
                onClick={() => {
                  setNoticeId(data.id);
                  setNoticePopup(true);
                }}
              >
                {data.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NoticeComponent;
