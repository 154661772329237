import React from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./PaymentsPage.css";
import { useState,useEffect } from "react";
import PaymentPopup from "../../components/PaymentPopup/PaymentPopup";
import PaymentsComponent from "../../components/PaymentsComponent/PaymentsComponent";
import DeleteWarnPopup from "../../components/DeleteWarnPopup/DeleteWarnPopup";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import MobileTableHeader from "../../components/MobileTableHeader/MobileTableHeader";
import IconButton from "../../components/IconButton/IconButton";
import filtericon from "./assets/filter.svg";
import downloadicon from "./assets/dwnld.svg";
import addicon from "./assets/add.svg";
import ListCardPayments from "../../components/ListCardPayments/ListCardPayments";
import apiCall from "../../Services/apiCall";
import { PendingPayments, RecievedPayments } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import PayFilterPopup from "../../components/PayFilterPopup/PayFilterPopup";
import ReceivedPayFilterPopup from "../../components/ReceivedPayFilterPopup/ReceivedPayFilterPopup";

const PaymentsPage = ({ section, setSection, academyData, headerAdminDp }) => {

  const navigate = useNavigate()

  const [payPopup, setpayPopup] = useState(false);
  const [pendingPayDwnloadPop, setPendingPayDwnloadPop] = useState(false);
  const [receivedPayDwnloadPop, setReceivedPayDwnloadPop] = useState(false);
  const [payhead, setPayhead] = useState("Received");
  const [recievedpayment,setRecievedpayment] = useState({})
  const [pendingPayments, setPendingPayments] = useState([])
  const [notifyWarning, setNotifyWarning] = useState(false)
  const [notifySuccessPopup, setnotifySuccessPopup] = useState(false)
  const [payPageSuccessPopup, setpayPageSuccessPopup] = useState(false)
  const [paymentsDwnldFilename, setPaymentsDwnldFilename] = useState({
    received:"",
    pending:""
  })
  const [pendingFilterPopup, setpendingFilterPopup] = useState(false);
  const [receivedFilterPopup, setReceivedFilterPopup] = useState(false)
  const [pendingPayFilterAdd, setPendingPayFilterAdd] = useState({
    month:"",
    class:"",
    division:"",
});

const [pendingFilterStatus, setPendingFilterStatus] = useState("")

const [receivedFilterInput, setReceivedFilterInput] = useState({
  class:"",
  division:"",
  month:""
})


  const Receivedpaymentlist = async()=>{
    const route = RecievedPayments()
    const response = await apiCall(route,{
      Month : receivedFilterInput.month?receivedFilterInput.month:"",
      Class : receivedFilterInput.class?receivedFilterInput.class:"",
      Division : receivedFilterInput.division?receivedFilterInput.division:""
    },false,"PUT")

    if(response.Status==='success'){
      setRecievedpayment(response);
      setReceivedFilterPopup(false)
    }else if(response.Status==='no_data'){
      setRecievedpayment(response);

    }else if(response.status==='token_is_invalid'){
      navigate('/login')
    }
  }

  const receivedPaymentsDownload=async()=>{
    const route = RecievedPayments();
    const resp = await apiCall(route,{
      Month : receivedFilterInput.month?receivedFilterInput.month:"",
      Class : receivedFilterInput.class?receivedFilterInput.class:"",
      Division :receivedFilterInput.division?receivedFilterInput.division: ""
    });
    if(resp.Status==='success'){
      setPaymentsDwnldFilename({...paymentsDwnldFilename,received:resp?.FileName});
      setReceivedPayDwnloadPop(true)
    }else if(resp.Status==='no_data'){
      setPaymentsDwnldFilename({...paymentsDwnldFilename,received:""})
    }else if(resp.Status==='token_is_invalid'){
      navigate('/login')
    }

  }

  const pendingPaymentList = async()=>{
    const route = PendingPayments()
    const resp = await apiCall(route,{
      Month : pendingPayFilterAdd.month?pendingPayFilterAdd.month:"",
      Class : pendingPayFilterAdd.class?pendingPayFilterAdd.class:"",
      Division : pendingPayFilterAdd.division?pendingPayFilterAdd.division:""
    },false,"PUT")

    if(resp.Status==='success'){
      setPendingPayments(resp.Data);
      setpendingFilterPopup(false);
      setPendingFilterStatus("");
    }else if(resp.Status==='no_data'){
      setPendingPayments([]);
      setPendingFilterStatus("No Data !")


    }else if(resp.Status==='token_is_invalid'){
      navigate('/login')
    }
  }

  const pendingPaymentsNotify=async()=>{
    const route = PendingPayments();
    const resp =await apiCall(route,{
    Month : pendingPayFilterAdd.month?pendingPayFilterAdd.month:"",
    Class: pendingPayFilterAdd.class?pendingPayFilterAdd.class:"",
    Division : pendingPayFilterAdd.division?pendingPayFilterAdd.division:"",
    Action:"notify"
    }); 
    if(resp.Status==="success"){
      setnotifySuccessPopup(true);
      setNotifyWarning(false)
    }
  }


  const pendingPaymentsDownload=async()=>{
    const route = PendingPayments();
    const resp =await apiCall(route,{
    Month : pendingPayFilterAdd.month?pendingPayFilterAdd.month:"",
    Class: pendingPayFilterAdd.class?pendingPayFilterAdd.class:"",
    Division : pendingPayFilterAdd.division?pendingPayFilterAdd.division:"",
    Action:"pdf"
    }); 
    if(resp.Status==="success"){
      setPaymentsDwnldFilename({...paymentsDwnldFilename,pending:resp?.FileName})
      setPendingPayDwnloadPop(true);
    }
  else if(resp.Status==='no_data'){
    setPaymentsDwnldFilename({...paymentsDwnldFilename,pending:""})

  }else if(resp.Status==='token_is_invalid'){
    navigate('/login')
  }
  }




  const notifyAllSubmit=()=>{
    pendingPaymentsNotify();

  }


  useEffect(() => {
    Receivedpaymentlist();
    pendingPaymentList()
  }, [])


  return (
    <div className="Payments-container">
      {pendingPayDwnloadPop ? (
        <div className="warning-div">
          <DeleteWarnPopup type="download" setWarning={setPendingPayDwnloadPop} dwnldFilename={paymentsDwnldFilename.pending} />
        </div>
      ) : (
        ""
      )}
      
      {receivedPayDwnloadPop ? (
        <div className="warning-div">
          <DeleteWarnPopup type="download" setWarning={setReceivedPayDwnloadPop} dwnldFilename={paymentsDwnldFilename.received}/>
        </div>
      ) : (
        ""
      )}
      
      {pendingFilterPopup ? (
        <div className="warning-div">
          <PayFilterPopup 
          setpendingFilterPopup={setpendingFilterPopup}
          pendingPayFilterAdd={pendingPayFilterAdd}
          setPendingPayFilterAdd={setPendingPayFilterAdd}
          pendingPaymentList={pendingPaymentList}
          pendingFilterStatus={pendingFilterStatus}
          setPendingFilterStatus={setPendingFilterStatus}
          />
        </div>
      ) : (
        ""
      )}

{receivedFilterPopup ? (
        <div className="warning-div">
          <ReceivedPayFilterPopup
          setReceivedFilterPopup={setReceivedFilterPopup}
          Receivedpaymentlist={Receivedpaymentlist}
          receivedFilterInput={receivedFilterInput}
           setReceivedFilterInput={setReceivedFilterInput}
          />
        </div>
      ) : (
        ""
      )}

      {payPageSuccessPopup ? (
        <div className="warning-div">
          <SuccessFailPopup setclosePopup={setpayPageSuccessPopup} type="success" text="Payment Added"/>
        </div>
      ) : (
        ""
      )}
      
      {notifySuccessPopup ? (
        <div className="warning-div">
          <SuccessFailPopup setclosePopup={setnotifySuccessPopup} type="success" text="Notification Sent"/>
        </div>
      ) : (
        ""
      )}
       {notifyWarning ? (
        <div className="warning-div">
          <DeleteWarnPopup 
          type="notify_warn" 
          setWarning={setNotifyWarning}
          notifyWarnsubmit={notifyAllSubmit} />
        </div>
      ) : (
        ""
      )}

      {payPopup ? (
        <div className="paypopup-div">
          <PaymentPopup 
          setPaySuccessPopup={setpayPageSuccessPopup}
          setFeepopup={setpayPopup} />
        </div>
      ) : (
        ""
      )}

      <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

      <HeaderSection
        section="payments"
        academyData={academyData}
        headerAdminDp={headerAdminDp}
      />

      <div className="payments-box">
        <PaymentsComponent
          setpayPopup={setpayPopup}
          recievedList = {recievedpayment}
          pendingPayments={pendingPayments}
          setNotifyWarning={setNotifyWarning}
          receivedPaymentsDownload={receivedPaymentsDownload}
          setpendingFilterPopup={setpendingFilterPopup}
          pendingPayFilterAdd={pendingPayFilterAdd}
          pendingPaymentsDownload={pendingPaymentsDownload}
          setReceivedFilterPopup={setReceivedFilterPopup}
        />
      </div>

      <div className="paymentsMobile">
        <div className="paymentsMobile-title">
          <p>PAYMENTS</p>
        </div>

        <div className="paymentsMobile-head">
          <MobileTableHeader
            clicked={payhead}
            setClicked={setPayhead}
            header1="Received"
            header2="Pending"
          />
        </div>

        {payhead === "Received" ?
        <div className="paymentsMobile-btns">
         <div onClick={()=>setpayPopup(true)}><IconButton img={addicon} /> </div> 
         <div onClick={receivedPaymentsDownload}> <IconButton img={downloadicon} /></div> 
         <div onClick={()=>setReceivedFilterPopup(true)}> <IconButton img={filtericon} /></div> 
        </div>
        :
        payhead === "Pending" ? 
        <div className="paymentsMobile-btns">
        <div onClick={()=>setNotifyWarning(true)}><IconButton img={addicon} /> </div> 
        <div onClick={pendingPaymentsDownload}> <IconButton img={downloadicon} /></div> 
        <div onClick={()=>setpendingFilterPopup(true)}> <IconButton img={filtericon} /></div> 
       </div>
       :""}


        <div>
          {payhead === "Received" ? (
            <div className="paymentsMobile-bottom">
              {recievedpayment?.data?.map((data,index)=>{
                  return(
                <ListCardPayments type="received" data={data} key={index}/>
                  )
              })

              }
             
            </div>
          ) : payhead === "Pending" ? (
            <div className="paymentsMobile-bottom">
               {pendingPayments?.map((data,index)=>{
                  return(
                <ListCardPayments
                pendingPayFilterAdd={pendingPayFilterAdd}
                 type="pending" data={data} key={index} />
                  )
              })

              }
              
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;
