import React from "react";
import "./AddStudentForm.css";
import InputComponent from "../InputComponent/InputComponent";
import ImportDocumentBtn from "../ImportDocumentBtn/ImportDocumentBtn";
import { useState } from "react";
import SelectBox from "../SelectBox/SelectBox";
import SaveCancelBtn from "../SaveBtn/SaveBtn";
import UploadImgBtn from "../UploadImgBtn/UploadImgBtn";
import { bloodGroupData, genderData } from "../../Arraydata";
import apiCall from "../../Services/apiCall";
import {
  AcademyStudentImgUploadRoute,
  AddStudent,
  CourseList,
  ViewDivision,
} from "../../Services/apiServices";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import close from "./assets/close.svg";
import UploadImgBox from "../UploadImgBox/UploadImgBox";
import ImgUploadPopup from "../ImgUploadPopup/ImgUploadPopup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AddStudentForm({ setStudentPopup, setAddstudSuccess }) {
  const adminkey = localStorage.getItem("adminKey");

  const navigate = useNavigate();

  const [bubble, setBubble] = useState(1);
  const [imgStud, setImgStud] = useState(null);
  const [addstudImgFile, setAddstudImgFile] = useState(null);
  const [addstudImgUploadPopup, setAddstudImgUploadPopup] = useState(false);
  const [studAddData, setStudAddData] = useState({
    studName: "",
    studEmail: "",
    dateofbirth: "",
    gender: "",
    bloodgroup: "",
    address: "",
    fathername: "",
    mothername: "",
    guardianphone: "",
    guardianname: "",
    studentphone: "",
    admissionno: "",
    reffered: "",
    class: "",
    division: "",
    image: "",
  });
  const [addStudclsdata, setAddStudclsdata] = useState([]);
  const [div,setDiv] = useState([])
  const handleDivision =async()=>{

    const route = ViewDivision()
    const resp = await apiCall(route,{Class:studAddData?.class})
    if(resp.Status ==="success"){
        setDiv(resp?.Division)
    }
  }
  const addstudSelectedClass = addStudclsdata?.filter(
    (data) => data.class === studAddData.class
  );

  const onBubble1 = (e) => {
    e.preventDefault();
    setBubble(2);
  };
  const onBubble2 = (e) => {
    e.preventDefault();
    setBubble(3);
  };
  // const onBubble3=(e)=>{
  //   e.preventDefault();
  //   setStudentPopup(false)
  // }

  const courseSubmit = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setAddStudclsdata(resp.Course);
    }
  };
  useEffect(() => {
    courseSubmit();
  }, []);

  const addteacherImgSubmit = async () => {
    const route = AcademyStudentImgUploadRoute();
    const resp = await apiCall(
      route,
      {
        Image: addstudImgFile ? addstudImgFile : "",
      },
      true
    );
    if (resp.Status === "success") {
      setStudAddData({ ...studAddData, image: resp.Image });
      setAddstudImgUploadPopup(false);
    } else if (resp.status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const addStudSubmit = async (e) => {
    e.preventDefault();
    // setStudentPopup(false)
    const route = AddStudent();
    const resp = await apiCall(route, {
      Name: studAddData.studName ? studAddData.studName : "",
      Class: studAddData.class ? studAddData.class : "",
      Division: studAddData.division ? studAddData.division : "",
      ParentPhone: studAddData.guardianphone
        ? "+91" + studAddData.guardianphone
        : "",
      AdmNo: studAddData.admissionno ? studAddData.admissionno : "",
      Image: studAddData.image ? studAddData.image : "",
      Gender: studAddData.gender ? studAddData.gender : "",
      BloodGroup: studAddData.bloodgroup ? studAddData.bloodgroup : "",
      FatherName: studAddData.fathername ? studAddData.fathername : "",
      MotherName: studAddData.mothername ? studAddData.mothername : "",
      EmailId: studAddData.studEmail ? studAddData.studEmail : "",
      Address: studAddData.address ? studAddData.address : "",
      Reference: studAddData.reffered ? studAddData.reffered : "",
      StudentPhone: studAddData.studentphone
        ? "+91" + studAddData.studentphone
        : "",
      Gaurdian: studAddData.guardianname ? studAddData.guardianname : "",
      DOB: studAddData.dateofbirth ? studAddData.dateofbirth : "",
    });

    if (resp.Status === "success") {
      setStudentPopup(false);
      setAddstudSuccess(true);
    } else if (resp.Status === "admno_already_exist") {
      toast.error("Admission No Already Exist ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  return (
    <div className="addStudForm-wrapper">
      {addstudImgUploadPopup ? (
        <div className="studimgpopup-div">
          <ImgUploadPopup
            setImgToBackend={setAddstudImgFile}
            setChangeImgPop={setAddstudImgUploadPopup}
            updateImgSubmit={addteacherImgSubmit}
          />
        </div>
      ) : (
        ""
      )}
      <div className="addStudForm-container">
        <img
          onClick={() => {
            setStudentPopup(false);
            setStudAddData("");
          }}
          className="addstud-close-svg"
          src={close}
          alt=""
        />

        <div className="addStudForm-title">ADD STUDENT</div>

        <div className="addStudForm-bubble-container">
          <div className="bubbles-box">
            <div
              className={
                bubble === 1 ? "bubble round-active" : "bubble round-inactive"
              } /*onClick={()=>setBubble(1)}*/
            >
              1
            </div>
            <div
              className={
                bubble === 2 ? "bubble round-active" : "bubble round-inactive"
              } /*onClick={()=>setBubble(2)}*/
            >
              2
            </div>
            <div
              className={
                bubble === 3 ? "bubble round-active" : "bubble round-inactive"
              } /*onClick={()=>setBubble(3)}*/
            >
              3
            </div>
          </div>
        </div>

        {bubble === 1 ? (
          <form className="addStudForm-btm-container" onSubmit={onBubble1}>
            <div className="addStudForm-btm-head">
              <p>PERSONAL DETAILS</p>
              {/* <div><ImportDocumentBtn/></div> */}
            </div>

            <InputComponent
              type="text"
              isRequired={true}
              placeHolder="Student name *"
              onChange={(e) =>
                setStudAddData({ ...studAddData, studName: e.target.value })
              }
            />

            <InputComponent
              type="email"
              isRequired={false}
              placeHolder="Email"
              onChange={(e) =>
                setStudAddData({ ...studAddData, studEmail: e.target.value })
              }
            />

            <div className="gender-blood-container">
              <div className="addStudForm-dob">
                <p>D.O.B:</p>
                <InputComponent
                  type="date"
                  isRequired={false}
                  placeHolder="dob"
                  onChange={(e) =>
                    setStudAddData({
                      ...studAddData,
                      dateofbirth: e.target.value,
                    })
                  }
                />
              </div>
              {/* <SelectBox
  defaultValue="Gender"
  data={genderData}
  onChange={(e)=>setStudAddData({...studAddData,gender:e.target.value})}
    isRequired={false}
  /> */}
              <SelectBox
                defaultValue="Blood group"
                data={bloodGroupData}
                onChange={(e) =>
                  setStudAddData({ ...studAddData, bloodgroup: e.target.value })
                }
                isRequired={false}
              />
            </div>

            <InputComponent
              type="text"
              isRequired={false}
              placeHolder="Address"
              onChange={(e) =>
                setStudAddData({ ...studAddData, address: e.target.value })
              }
            />

            <div className="addStudForm-btns-container">
              <div onClick={() => setStudentPopup(false)}>
                <SaveCancelBtn type="cancel" section="addstudent" />
              </div>
              <button className="btn-outline" type="submit">
                <SaveCancelBtn type="save" text="Next" section="addstudent" />
              </button>
            </div>
          </form>
        ) : (
          ""
        )}

        {bubble === 2 ? (
          <form className="addStudForm-btm-container" onSubmit={onBubble2}>
            <div className="addStudForm-btm-head">
              <p>GUARDIAN DETAILS</p>
              <div></div>
            </div>

            <InputComponent
              type="text"
              isRequired={false}
              placeHolder="Father's name"
              onChange={(e) =>
                setStudAddData({ ...studAddData, fathername: e.target.value })
              }
            />

            <InputComponent
              type="text"
              isRequired={false}
              placeHolder="Mother's name"
              onChange={(e) =>
                setStudAddData({ ...studAddData, mothername: e.target.value })
              }
            />

            <InputComponent
              type="text"
              isRequired={false}
              placeHolder="Guardian name"
              onChange={(e) =>
                setStudAddData({ ...studAddData, guardianname: e.target.value })
              }
            />

            <div>
              <InputComponent
                type="number"
                isRequired={true}
                placeHolder="Guardian Phone number *"
                onChange={(e) =>
                  setStudAddData({
                    ...studAddData,
                    guardianphone: e.target.value,
                  })
                }
              />
              <p className="num-warning">
                (This number is used to receive messages)
              </p>
            </div>

            <InputComponent
              type="number"
              isRequired={false}
              placeHolder="Student Phone number"
              onChange={(e) =>
                setStudAddData({ ...studAddData, studentphone: e.target.value })
              }
            />

            <div className="addStudForm-btns-container">
              <div onClick={() => setBubble(1)}>
                <SaveCancelBtn type="back" section="addstudent" />
              </div>
              <button className="btn-outline" type="submit">
                <SaveCancelBtn type="save" text="Next" section="addstudent" />
              </button>
            </div>
          </form>
        ) : (
          ""
        )}

        {bubble === 3 ? (
          <form className="addStudForm-btm-container" onSubmit={addStudSubmit}>
            <div className="addStudForm-btm-head">
              <p>ACADEMIC DETAILS</p>
              <div></div>
            </div>

            <InputComponent
              type="number"
              isRequired={true}
              placeHolder="Admission number *"
              onChange={(e) =>
                setStudAddData({ ...studAddData, admissionno: e.target.value })
              }
            />

            <InputComponent
              type="text"
              isRequired={false}
              placeHolder="Reffered By"
              onChange={(e) =>
                setStudAddData({ ...studAddData, reffered: e.target.value })
              }
            />

            <div className="gender-blood-container">
              <select
                name=""
                id=""
                className="selectbox"
                required
                onChange={(e) =>
                  {setStudAddData({ ...studAddData, class: e.target.value })
                  handleDivision()}
                }
              >
                <option className="default-select" value="" selected>
                  class
                </option>
                {addStudclsdata?.map((option, index) => {
                  return (
                    <option key={index} value={option.class}>
                      {option.class}
                    </option>
                  );
                })}
              </select>

              <select
                name=""
                id=""
                className="selectbox"
                onChange={(e) =>
                  setStudAddData({ ...studAddData, division: e.target.value })
                }
              >
                <option className="default-select" value="" selected>
                  Division/Batch
                </option>
                {div.map((divdata, index) => {
                  return (
                    <option key={index} value={divdata?.division}>
                      {divdata?.division}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div> */}
            {/* <UploadImgBtn
   imgStud={imgStud}
    setImgStud={setImgStud}
   image={image}
    setImageStudent={setImageStudent}
  /> */}
            <div onClick={() => setAddstudImgUploadPopup(true)}>
              {studAddData.image ? (
                <UploadImgBox type="added" />
              ) : (
                <UploadImgBox type="upload" />
              )}
              {/* </div> */}
            </div>

            <div className="addStudForm-btns-container">
              <div onClick={() => setBubble(2)}>
                <SaveCancelBtn type="back" section="addstudent" />
              </div>
              <button className="btn-outline" type="submit">
                <SaveCancelBtn type="save" text="Save" section="addstudent" />
              </button>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddStudentForm;
