import React from 'react'
import "./OutStandingFee.css"
import apiCall from '../../Services/apiCall'
import { OutstandingFeeApi, studwebOutstandingFeesRoute } from '../../Services/apiServices'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

const OutStandingFee = ({Admissionnumber,type}) => {
  const navigate = useNavigate()
  const [outstandingfee ,setOutstandingfee] = useState(null)

  const OutStandingApi = async()=>{
    const route = OutstandingFeeApi()
    const response = await apiCall(route,{
      StudentId:Admissionnumber?Admissionnumber:''
    })
    if(response.Status === 'success'){
      setOutstandingfee(response)
    }
    if(response.Status === 'no_data'){
      setOutstandingfee(null)
    }
    else if(response.Status==="token_is_invalid"){
navigate('/login')
    }
  }



  const studwebOutstandingFeeSubmit=async()=>{
    console.log("hii");
    const route = studwebOutstandingFeesRoute();
    const resp = await apiCall(route,{});
    if(resp.Status==="success"){
      setOutstandingfee(resp)
    }
    else if(resp.Status==="no_data"){
      setOutstandingfee(null)
    }
    else if(resp.Status==='token_is_invalid'){
      navigate('/studentLogin')
     }
  }

  useEffect(()=>{
    if(type==="studentWeb"){
      studwebOutstandingFeeSubmit()
    }else{
      OutStandingApi()
    }
  },[])
  return (
    <div className='OutStandingFee-container'>

        <div className="OutStandingFee-top">
            Outstanding Fees
        </div>

        <div className="OutStandingFee-middle">
            <div className='OutStandingFee-content'>
            <p>Total Fees</p>
            <p>{outstandingfee?"₹":""} {outstandingfee?.TotalFees}</p>
            </div>
            
            <div className='OutStandingFee-content'>
            <p>Discount</p>
            <p>{outstandingfee?"₹":""} {outstandingfee?.Discount}</p>
            </div>

            <div className='OutStandingFee-content'>
            <p>Total Paid</p>
            <p>{outstandingfee?"₹":""} {outstandingfee?.TotalPaid}</p>
            </div>
           
        </div>

        <div className="OutStandingFee-btm">
        <div className='OutStandingFee-content'>
            <p>Pending</p>
            <p>{outstandingfee?"₹":""} {outstandingfee?.PendingFees}</p>
            </div>
        </div>
        
    </div>
  )
}

export default OutStandingFee