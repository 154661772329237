import React, { useState } from 'react'
import "./PaymentsComponent.css"
import TableHeader from '../TableHeader/TableHeader'
import ReceivedPayTable from '../ReceivedPayTable/ReceivedPayTable'
import PendingPayTable from '../PendingPayTable/PendingPayTable'
import IconButton from '../IconButton/IconButton'
import filter from "./assets/filter.svg"
import dwnld from "./assets/dwnld.svg"
import add from "./assets/add.svg"


const PaymentsComponent = ({
    setpayPopup,
    recievedList,
    pendingPayments,
    setNotifyWarning,
    receivedPaymentsDownload,
    setpendingFilterPopup,
    pendingPayFilterAdd,
    pendingPaymentsDownload,
    setReceivedFilterPopup
}) => {

    const [headerType, setheaderType] = useState("RECEIVED")


  return (
    <div className='PaymentsComponent-container'>

        <div className="PaymentsComponent-top">
            <div className='PaymentsComponent-top-left'>
                <div>
    <TableHeader 
    header1="RECEIVED"
    header2="PENDING"
    setheaderType={setheaderType}
    />
    </div>
    {/* <div className="PaymentsComponent-filtShow">
        10 A
    </div> */}
    </div>

   
    

{headerType==="RECEIVED"?
    <div className='PaymentsComponent-top-right'>     
<div onClick={()=>setpayPopup(true)}><IconButton label="Add Fee" img={add}/></div>
<div onClick={receivedPaymentsDownload}><IconButton label="Download" img={dwnld} /></div>
<div onClick={()=>setReceivedFilterPopup(true)}><IconButton  label="Filter" img={filter} /></div>
    </div>
 :
 headerType==="PENDING"?
 <div className='PaymentsComponent-top-right'>   
<div onClick={()=>setNotifyWarning(true)}><IconButton label="Notify All" img={add}/></div>
<div onClick={pendingPaymentsDownload}><IconButton label="Download" img={dwnld} /></div>
<div onClick={()=>setpendingFilterPopup(true)}><IconButton  label="Filter" img={filter} /></div>  

    </div>
    :""
 }   

        </div>

        <div className="PaymentsComponent-bottom">
        {headerType==="RECEIVED"?
<ReceivedPayTable recievedList={recievedList}/>
:
headerType==="PENDING"?
<PendingPayTable 
pendingPayFilterAdd={pendingPayFilterAdd}
pendingPayments={pendingPayments}/>
:
""}
        </div>
        
    </div>
  )
}

export default PaymentsComponent