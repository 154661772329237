import React, { useState } from 'react'
import "./ImgUploadPopup.css"
import close from "./xmark.svg";
import upload from "./upload3.svg";

const ImgUploadPopup = ({
    setChangeImgPop,
    updateImgSubmit,
    setImgToBackend,
    // imgToFrontEnd,
    // setImgToFrontEnd,
}) => {

    // changeImg->imgToFrontEnd
    // ChangeImgData->imgToBackend

    // const [imgToBackend, setImgToBackend] = useState(null);
    const [imgToFrontEnd, setImgToFrontEnd] = useState(null)

    const imgUploadSubmit = () => {
        // setstudImage(changeImgData);
        updateImgSubmit();
      };
    
      function imgChange(e) {
        setImgToFrontEnd(URL.createObjectURL(e.target.files[0])); //to show in frontend
        setImgToBackend(e.target.files[0]); //passing to backend
      }

  return (
    <div className="changeimg-container">
    <form className="change-img-popup" onSubmit={(e) => e.preventDefault()}>
      <div className="changeimg-popup-lbl">Change Profile Picture</div>

      <div className="imgchange-inp-show">
        {!imgToFrontEnd ? (
          <label className="changeon-popup-img">
            <input
              className="change-input-img"
              type="file"
              onChange={imgChange}
              accept="image/png, image/jpeg"
              required
            />
            Select File
            <img 
           src={upload}
            alt="select" />
          </label>
        ) : (
          <div className="chnge-img-box">
            <img className="change-pop-img" src={imgToFrontEnd} alt="" />
            {imgToFrontEnd ? (
              <img
                className="close-pop"
                onClick={() => {
                    setImgToBackend("");
                  setImgToFrontEnd("");
                }}
                src={close}
                alt="X"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <div className="changeimg-btns">
        <button
          onClick={imgUploadSubmit}
          type="submit"
          className="changeimg-save-btn"
        >
          Upload
        </button>
        <button
          className="change-img-cancel"
          onClick={() => {
            setChangeImgPop(false);
            // setImgToBackend("");
            // setImgToFrontEnd("");
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
  )
}

export default ImgUploadPopup