import React from 'react'
import "./AddTimeSlotPopup.css"
import SaveCancelBtn from '../SaveBtn/SaveBtn'
import { AddTimeSlotRoute } from '../../Services/apiServices'
import apiCall from '../../Services/apiCall'
import { useNavigate } from 'react-router-dom'

const AddTimeSlotPopup = ({
  addTimeslot,
  setAddTimeslot,
  setClasstotimeslot,
  classtotimeslot,
  timetableFilterClass,
  timetableFilterDivision
}) => {
const navigate =useNavigate()
  const AddTimeSlotApi = async()=>{
    const route = AddTimeSlotRoute()
    const response = await apiCall(route,{
      StartTime:classtotimeslot.starttime?classtotimeslot.starttime:'',
      EndTime:classtotimeslot.endtime?classtotimeslot.endtime:'',
      Class:timetableFilterClass==="Class"?"":timetableFilterClass,
      Division:timetableFilterDivision==="Division"?"":timetableFilterDivision
    })
    if(response.Status === 'success'){
      console.log("TimeSlot Added");
      setAddTimeslot(false)
    }else if(response.Status==='token_is_invalid')
    {
      navigate('/login')
    }
  }

  return (
    <div className='AddTimeSlot-wrapper'>
        <div className="AddTimeSlot-container">
            <div className='AddTimeSlot-title'> Add Time Slot</div>
            <div className='AddTimeSlot-inputWrap'>
                <p>Starting Time:</p>
                <input type="time" name="" onChange={(e)=>{setClasstotimeslot({...classtotimeslot,starttime:e.target.value})}}/>
            </div>
            <div className='AddTimeSlot-inputWrap'>
                <p>Ending Time:</p>
                <input type="time" name="" onChange={(e)=>{setClasstotimeslot({...classtotimeslot,endtime:e.target.value})}}/>
            </div>
         
            <div className="btn-sec">
               <button className='cancelBtn-notprofile' onClick={()=>setAddTimeslot(false)}>Cancel</button>
               <button className='SaveBtn-container' onClick={AddTimeSlotApi}>Add</button>
            </div>
  
        </div>
        
    </div>
  )
}

export default AddTimeSlotPopup