import React from 'react'
import "./PaySuccessPopup.css"
import verified from "./assets/verified.svg"
import SaveCancelBtn from '../SaveBtn/SaveBtn'

const PaySuccessPopup = ({setPaySuccessPopup}) => {
  return (
    <div className="PaySuccess-wrapper">
    <div className='PaySuccess-container'>

        <div className="PaySuccess-top">
            <img src={verified} alt="" />
            <p>Payment Successful</p>

        </div>

        {/* <div className="PaySuccess-middle">
<p>Do you want to download this receipt?</p>
        </div> */}

        <div className="PaySuccess-bottom" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
{/* <SaveCancelBtn
type="save"
text="Download"
/> */}
<div className='PaySuccess-close' onClick={()=>setPaySuccessPopup(false)}>Close</div>

        </div>
        
    </div>
    </div>
  )
}

export default PaySuccessPopup