import React from "react";
import "./ReceivedPayTable.css";

const ReceivedPayTable = ({ recievedList }) => {
  return (
    <div className="ReceivedPayTable-container">
      <div className="ReceivedPayTable-wrapper">
        <div className="ReceivedPayTable-head">Admn.Id</div>
        <div className="ReceivedPayTable-head">Name</div>
        <div className="ReceivedPayTable-head">Class</div>
        <div className="ReceivedPayTable-head">Receipt No.</div>
        <div className="ReceivedPayTable-head">Date</div>
        <div className="ReceivedPayTable-head">Purpose</div>
        <div className="ReceivedPayTable-head">Amount</div>
      </div>

      <div className="ReceivedPayTable-scroll-container">
        {recievedList.Data?.map((data, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
              }}
              className="ReceivedPayTable-content-wrapper"
            >
              <div className="ReceivedPayTable-content">
                {data?.AdmNo.replace(localStorage.getItem("adminKey"), "")}
              </div>
              <div className="ReceivedPayTable-content">{data?.Name}</div>
              <div className="ReceivedPayTable-content">
                {data?.Class} {data.Division?` - ${data?.Division}`:""}
                {}
              </div>
              <div className="ReceivedPayTable-content">{data?.ReceiptNo}</div>
              <div className="ReceivedPayTable-content">{data?.Date}</div>
              <div className="ReceivedPayTable-content">{data?.FeesType}</div>
              <div className="ReceivedPayTable-content">{data?.Amount}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReceivedPayTable;
