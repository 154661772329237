import React from 'react'
import "./PricingCard.css"
import tick from "../../containers/GradientLandingPage/figma assets/tick.svg"

function PricingCard({data}) {
  return (
    <div className='PricingCard-container'>
        <div className="price-display-box">Rs. {data.price}</div>
        <div className="PricingCard-head">
            <p>{data.title}</p>

        </div>

        <div className="PricingCard-middle">
            {data.description?.map((content,index)=>{
                return(
                    <div className='pricing-content' key={index}>
                <img  src={tick} alt="" />
                <p>{content}</p>
            </div>
                )
            })}
            
        </div>

        <div className="PricingCard-bottom">
            <button className='subscribe-btn'>Subscribe</button>
        </div>
      
    </div>
  )
}

export default PricingCard
