import React from "react";
import "./AttendanceCard.css";
import attendance from "./assets/attendance.svg";
import arrowdown from "./assets/arrowdown.svg";
import ongoingimg from "./assets/ongoing.svg";
import { useState } from "react";
import { classdata } from "../../Arraydata";

const AttendanceCard = ({ title, datas, heading, carddata}) => {
  const [click, setClick] = useState(false);

 

  return (
    <div
      className={
        carddata?.length > 1 && click ? "active-card" : "attendance-card"
      }
    >
      <div className="head-wrapper">
        <div className="attendance-head">
          <div
            className={
              datas === "attendance" ? "attendance-svgbox" : "ongoing-svgbox"
            }
          >
            {datas === "attendance" ? (
              <img
                // src={baseUrl + "/student/svg_image?Image=attendance.svg"}
                src={attendance}
                alt=""
              />
            ) : (
              <img
                // src={baseUrl + "/student/svg_image?Image=ongoing.svg"}
                src={ongoingimg}
                alt=""
              />
            )}
          </div>
          <div className="attendance-text">{title}</div>
        </div>
        <div className="classhead">
          <div className="class-txt">Class</div>
          <div className="strength-txt">{heading}</div>
        </div>
      </div>

      <div
        className={
          carddata?.length > 1 && click
            ? "card-down"
            : "attendance-card attendance-btm"
        }
      >
        {carddata?.map((data, index) => {
          return (
            <div className="classdata" key={index}>
              <div className="classs">
                {data.Class} {data.Division}
              </div>

              {datas === "attendance" ? (
                <div className="strength">
                  {data.Present}/{data.Total}
                </div>
              ) : (
                <div className="subject">{data.subject}</div>
              )}
            </div>
          );
        })}
      </div>

      <div
        className={classdata?.length <= 1 ? "btmclick btmbar" : "btmclick"}
        onClick={() => setClick(!click)}
      >
        {carddata?.length <= 1 ? (
          ""
        ) : (
          <img
            className={click ? "arrow-active" : "arrow-incard"}
            // src={baseUrl + "/student/svg_image?Image=arrowdown.svg"}
            src={arrowdown}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default AttendanceCard;
