import React from 'react'
import "./ListCardPayments.css"
import ClassDisplayBox from '../ClassDisplayBox/ClassDisplayBox'

const ListCardPayments = ({type,data,pendingPayFilterAdd}) => {
  return (
    <div className="ListCardPay-container">
    <div className="ListCardPay-msgicon">
      {/* {type==="pending"?
     <>
     <p>Expired on:</p>
     <p>03/05/2023</p>
     </>
     : */}
     {type==="received"?
     <>
     <p>{data?.Date}</p>
     </>
     :""}
      </div>

    <div className="ListCardPay-top">
      <div>
        <p className="ListCardPay-name">{data?.name}</p>
        <p className="ListCardPay-id">ID :    {data?.AdmNo.replace(localStorage.getItem("adminKey"), "")}</p>
      </div>
      <div>
        <ClassDisplayBox std={data?.Class} division={data?.Division}/>
      </div>
    </div>


    <div className="ListCardPay-btm">
      {type==="received"?
      <div className="ListCardPay-btmContent">
        <p className="ListCardPay-btmLabel">Purpose :</p>
        <p className="ListCardPay-btmDetail">{data?.FeesType}</p>
      </div>
      :
      type==="pending"?
      <div className="ListCardPay-btmContent">
        <p className="ListCardPay-btmLabel">{pendingPayFilterAdd.month?"month":"no.of Months"}  :</p>
        <p className="ListCardPay-btmDetail">{data?.PendingMonths}</p>
      </div>
      :""}

      {/* <div className="ListCardPay-btmContent">
        <p className="ListCardPay-btmLabel">Date :</p>
        <p className="ListCardPay-btmDetail">{data?.Date}</p>
      </div> */}
{type==="received"?
      <div className="ListCardPay-btmContent">
      <p className="ListCardPay-btmLabel">Amount :</p>
      <p className="ListCardPay-btmDetail">
      ₹ {data?.Amount}
      </p>
    </div>
      :
      type==="pending"?
      <div className="ListCardPay-btmContent">
      <p className="ListCardPay-btmLabel">Amount :</p>
      <p className="ListCardPay-btmDetail">
      {data?.PhoneNo}
      </p>
    </div>
      :""}
     
    </div>
  </div>
  )
}

export default ListCardPayments