import React from "react";
import "./SmallFilterbtn.css";
import smallfilter from "./smallfilter.svg";
import { baseUrl } from "../../Services/apiCall";

const SmallFilterbtn = () => {


  return (
    <>
      <div className="SmallFilterbtn">
        <img
        //  src={baseUrl + "/student/svg_image?Image=smallfilter.svg"}
         src={smallfilter}
          alt="" srcset="" />
      </div>
     
    </>
  );
};

export default SmallFilterbtn;
