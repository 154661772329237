import React, { useState } from 'react'
import AttendanceTable from '../AttendanceTable/AttendanceTable'
import ClassListTable from '../ClassListTable/ClassListTable'
import "./TableHeader.css"


const TableHeader = ({header1,header2,setheaderType}) => {
    const [active, setActive] = useState(0)
  return (
    <div className='TableHeader-container'>
      
        <div className='TableHeader-wrapper'>
        {active===0?<div className="firstActivecurve">{header1}</div>:<div onClick={()=>{setActive(0);setheaderType(header1)}} className="firstInActivecurve">{header1}</div>}
        {active===1?<div className="activecurve one">{header2}</div>:<div onClick={()=>{setActive(1);setheaderType(header2)}} className="inactivecurve one">{header2}</div>}
        {/* {active===2?<div className="activecurve two">SUBJECT</div>:<div onClick={()=>setActive(2)} className="inactivecurve two">SUBJECT</div>} */}
        </div>


    </div>
  )
}

export default TableHeader