import React from 'react'
import "./SmallBtn.css"
// import download from './download.svg'

const SmallBtn = ({icon}) => {
  return (
    <div className='SmallBtn-container'> 

        <img src={icon} alt="" />
    </div>
  )
}

export default SmallBtn