import React, { useEffect } from 'react'
import "./UploadDocumentPopup.css"
import choose from "./assets/choose.svg"
import InputComponent from '../InputComponent/InputComponent'
import apiCall, { baseUrl } from '../../Services/apiCall'
import { DocFileOnlyUploadRoute, FileUploadRoute } from '../../Services/apiServices'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UploadDocumentPopup = ({setaddnewDocPopup,userid,setUploadSuccessPopup}) => {

    const navigate = useNavigate()

    const [uploadFileData, setUploadFileData] = useState({
        inputFilename:"",
        file:"", 
        Docfilename:""     
    })

    
    

    const fileOnlySubmit=async()=>{
        const route = DocFileOnlyUploadRoute();
        const resp = await apiCall(route,{
            Image:uploadFileData.file?uploadFileData.file:""
        },true);
        if(resp.Status==="success"){
            setUploadFileData({...uploadFileData,Docfilename:resp.FileName,file:""})
        }
        else if(resp.Status==='token_is_invalid'){
            navigate('/login')
           }
           else{
            setUploadFileData({...uploadFileData,file:""})
           }

    }

    
    const fileuploadSubmit=async(e)=>{
        e.preventDefault();
        const route = FileUploadRoute();
        const resp = await apiCall(route,{
    Name : uploadFileData.inputFilename?uploadFileData.inputFilename:"",
    Image : uploadFileData.Docfilename?uploadFileData.Docfilename:"",
    AdmNo : userid?userid:""
        })
           
        if(resp.Status==="success"){
            setaddnewDocPopup(false)
            setUploadSuccessPopup(true)
        }
        else if(resp.Status==='token_is_invalid'){
            navigate('/login')
           }
    }

    if(uploadFileData.file){
        fileOnlySubmit();
    }

    

    

  return (
    <div className='UploadDoc-wrapper'>

        <form className="UploadDoc-container" onSubmit={fileuploadSubmit}>
            <div className="UploadDoc-head">
                UPLOAD DOCUMENT
            </div>

            <div className="UploadDoc-middle">
            <InputComponent 
       type="text"
       isRequired={true}
       placeHolder="Enter File name (eg: aadhaar, sslc certificate...)"
       onChange={(e)=>setUploadFileData({...uploadFileData,inputFilename:e.target.value})}
      />
      <input type="file"  required
      accept=".jpg, .jpeg, .png, .pdf"
       onChange={(e)=>setUploadFileData({...uploadFileData,file:e.target.files[0]})}/>
            </div>
           
        

            <div className="UploadDoc-btns-container">

                <div className="UploadDoc-cancelBtn" onClick={()=>setaddnewDocPopup(false)}>
                    Cancel
                </div>

                <button type='submit'
               
                  className="UploadDoc-chooseBtn btn-outline">
                    <img src={choose} alt="" />
                    <p>Upload</p>
                </button>

            </div>

        </form>
        
    </div>
  )
}

export default UploadDocumentPopup