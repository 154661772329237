import React from 'react'
import "./ProfilepaymentHistory.css"
import SmallBtn from '../SmallBtn/SmallBtn'
import download from './download.svg'
import MonthYearBtn from '../MonthYearBtn/MonthYearBtn'
import apiCall from '../../Services/apiCall'
import { LoginFunction, ProPayment, StudPaymentsHistoryRoute, studwebPayHistoryRoute } from '../../Services/apiServices'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ProfilepaymentHistory = ({
    profilePayFilter,
    setprofilePayFilter,
    profilePayMonthFilt,
    setprofilePayMonthFilt,
    setProfilePayPopup,
    payHistoryDownload,
    userid,
    type
}) => {
    const navigate = useNavigate()
    const [studPaymentsData, setstudPaymentsData] = useState([])


const studpayHistorySubmit=async()=>{
    const route = ProPayment();
    const resp = await apiCall(route,{
        Id:userid?userid:"",
        Month:profilePayMonthFilt?profilePayMonthFilt:""
    },false);
    if(resp.Status==="success"){
        setstudPaymentsData(resp.Data)
    }
    else if(resp.Status==="no_data"){
        setstudPaymentsData([])
      }
      else if(resp.Status==="token_is_invalid"){
        navigate("/login");
      }
}

const studwebpayHistory=async()=>{
    const route = studwebPayHistoryRoute();
    const resp = await apiCall(route,{
        Month:profilePayMonthFilt?profilePayMonthFilt:""
    });
    if(resp.Status==="success"){
        setstudPaymentsData(resp.Data)
    }
    else if(resp.Status==="no_data"){
        setstudPaymentsData([])
      }
      else if(resp.Status==="token_is_invalid"){
        navigate("/login");
      }
}

useEffect(() => {
    if(type==="studentWeb"){
        studwebpayHistory();
    }else{
        studpayHistorySubmit();
    }
}, [profilePayMonthFilt])


  return (
    <div className=' Profilepayment-container'>

<div>
<div className='Profilepayment-pay'>
{/* <div onClick={()=>setProfilePayPopup(true)} >Add Payment</div> */}
</div>

    <div className=" Profilepayment-top">
<div className=" Profilepayment-left">
Payment History
</div>
<div className="Profilepayment-right">
<div onClick={payHistoryDownload}><SmallBtn icon={download}/></div>
<div><MonthYearBtn
 date={profilePayFilter}
 setDate={setprofilePayFilter}
 setFilterDateatten={setprofilePayMonthFilt}
 filterDateatten={profilePayMonthFilt}
/></div>
</div>
    </div>

    <div className=" Profilepayment-bottom">
    <div className=" Profilepayment-wrapper">
<div className=' Profilepayment-head'  >Date</div>
<div className=' Profilepayment-head'  >Receipt No.</div>
<div className=' Profilepayment-head'  >Purpose</div>
<div className=' Profilepayment-head'  >Amount</div>

</div>

<div className=' Profilepayment-scroll-container'>

{studPaymentsData?.map((data,index)=>{
return(
    <div key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className=' Profilepayment-content-wrapper'>
<div className=' Profilepayment-content'>{data.date}</div>
<div className=' Profilepayment-content'>{data.receipt_no}</div>
<div className=' Profilepayment-content'>{data.purpose}</div>
<div className=' Profilepayment-content'>{data.amount}</div>
</div>
)
})}

</div>
    </div>

   </div>
 
</div>
  )
}

export default ProfilepaymentHistory