import React from 'react'
import "./ViewTempidUseHistoryPopup.css"
import { studentdata } from '../../Arraydata'
import close from "./assets/close.svg"
import apiCall from '../../Services/apiCall'
import { TempidUsageHistoryRoute } from '../../Services/apiServices'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const ViewTempidUseHistoryPopup = ({setTempidHistoryPopup,userid}) => {
  const navigate = useNavigate()

  const [temipUsageData, setTemipUsageData] = useState([])

  const TempidUsageListView=async()=>{
    const route = TempidUsageHistoryRoute();
    const resp = await apiCall(route,{
      AdmNo : userid?userid:""
    },false,"PUT");
    if(resp.Status==="success"){
      setTemipUsageData(resp.Data)
  }
  else if(resp.Status==="no_data"){
    setTemipUsageData([])
  }
  else if(resp.Status==='token_is_invalid'){
   navigate('/login')
  }
  }

  useEffect(() => {
    TempidUsageListView();
  }, [])
  

  return (
    <div className='viewTempid-cover'>
    <div className='viewTempid-container'>
    <img onClick={()=>setTempidHistoryPopup(false)}  className='viewTempid-close-svg' src={close} alt="" />
    <div className="viewTempid-wrapper">
  {/* <div className='viewTempid-head' >Temporary Id</div> */}
  <div className='viewTempid-head' >Date</div>
  <div className='viewTempid-head' >Check In</div>
  <div className='viewTempid-head' >Check Out</div>
  </div>

<div className='viewTempid-scroll-container'>

  {temipUsageData?.map((data,index)=>{
    return(
        <div key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='viewTempid-content-wrapper'>
 {/* <div  className='viewTempid-content'>{data.AdmNo}</div> */}
  <div className='viewTempid-content'>{data.Date}</div>
  <div className='viewTempid-content'>
  {data.Checkin}
  </div>
  <div className='viewTempid-content'>{data.Checkout}</div>

</div>
    )
  })}

</div>

</div>
</div>
  )
}

export default ViewTempidUseHistoryPopup