import React from "react";
import "./AttendanceTable.css";
import { useNavigate } from "react-router-dom";
import { attendanceData } from "../../Arraydata";
import { Skeleton } from "@mui/material";

const AttendanceTable = ({ data, loading ,attendanceresponse}) => {
  console.log("here",attendanceresponse)
  const adminkey = localStorage.getItem("adminKey");
  const toStudProfile = useNavigate();
  return (
    <div className="AttendanceTable-container">
      <div className="AttendanceTable-wrapper">
        <div className="AttendanceTable-head">Sl.no</div>
        <div className="AttendanceTable-head">Date</div>
        <div className="AttendanceTable-head">Admn.Id</div>
        <div className="AttendanceTable-head">Name</div>
        <div className="AttendanceTable-head">Class</div>
        <div className="AttendanceTable-head">Division</div>
        <div className="AttendanceTable-head">Check-in </div>
        <div className="AttendanceTable-head">Check-out</div>
      </div>
      {loading ? (
        <>
          {attendanceData?.map((data, index) => {
            return (
              <div
                className="AttendanceTable-content-wrapper"
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                }}
              >
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />{" "}
                </div>
                <div className="AttendanceTable-content">
                  <Skeleton />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="AttendanceTable-scroll-container">
          {data?.Data?.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                }}
                className="AttendanceTable-content-wrapper"
              >
                <div className="AttendanceTable-content">{index + 1}</div>
                <div className="AttendanceTable-content">{data?.Date}</div>
                <div className="AttendanceTable-content">
                  {data?.AdmNo.replace(adminkey, "")}
                </div>
                <div
                  className="AttendanceTable-content hover-underline"
                  onClick={() => toStudProfile(`/profile/${data?.AdmNo}`)}
                >
                  {data?.Name}
                </div>
                <div className="AttendanceTable-content">
                  {data ? data.Class : ""}
                </div>
                <div className="AttendanceTable-content">{data?.Division}</div>
                <div className="AttendanceTable-content">
                  {data.Checkin ? data.Checkin : "-- : --"}
                </div>
                <div className="AttendanceTable-content">
                  {data.Checkout ? data.Checkout : "-- : --"}
                </div>
              </div>
            );
          })}
          {attendanceresponse==="no_data" ? <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",height:"30vh"}}> <h5 style={{alignItems:"center",fontWeight:500}}>No records to show</h5></div>:""}
          {attendanceresponse==="no_students_present" ? <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",height:"30vh"}}> <h5 style={{alignItems:"center",fontWeight:500}}>No Students Punched</h5></div>:""}
          {attendanceresponse==="no_absent" ? <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",height:"30vh"}}> <h5 style={{alignItems:"center",fontWeight:500}}>All Students are present</h5></div>:""}
          {attendanceresponse==="no_students" ? <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",height:"30vh"}}> <h5 style={{alignItems:"center",fontWeight:500}}>No Students Records Added</h5></div>:""}

        </div>
      )}
    </div>
  );
};

export default AttendanceTable;
