import React, { useEffect, useState } from 'react'
import "./PayFilterPopup.css"
import InputComponent from '../InputComponent/InputComponent'
import { CourseList } from '../../Services/apiServices'
import apiCall from '../../Services/apiCall'
import { useNavigate } from 'react-router-dom'

const PayFilterPopup = ({
    setpendingFilterPopup,
    pendingPayFilterAdd,
    setPendingPayFilterAdd,
    pendingPaymentList,
    pendingFilterStatus,
    setPendingFilterStatus
}) => {
    const navigate = useNavigate()

    const [PayListCourseData, setPayListCourseData] = useState([])
   

    const courseListPayPage=async()=>{
        const route = CourseList();
        const resp = await apiCall(route,{});
        if(resp.Status==="success"){
            setPayListCourseData(resp.Course);
        }
        else if(resp.status==='no_data'){
            setPayListCourseData([])
           }
        else if(resp.status==='token_is_invalid'){
            navigate('/login')
           }
      }

      const FiltSelectedClass =  PayListCourseData?.filter((data)=>data.class===pendingPayFilterAdd.class);

      const handleFilterCancel=()=>{
        setpendingFilterPopup(false);
        setPendingFilterStatus("");
        setPendingPayFilterAdd({...pendingPayFilterAdd,
            month:"",
            class:"",
            division:""
        })

      }

      useEffect(() => {
        courseListPayPage();
      }, [])

  return (
    <div className='PayFilterPopup-wrapper'>

    <div className="PayFilterPopup-container">
        <div className="PayFilterPopup-head">
           Pending Payments Filter
        </div>

        <div className="PayFilterPopup-middle">

<div className='PayFilterPopup-month'>
    <p>Month : </p>
<InputComponent 
   type="month"
   isRequired={false}
   placeHolder="Title"
   value={pendingPayFilterAdd.month}
   onChange={(e)=>setPendingPayFilterAdd({...pendingPayFilterAdd,month:e.target.value})}
  />

</div>
       

<select name="" id="" className='selectbox' 
value={pendingPayFilterAdd.class}
onChange={(e)=>setPendingPayFilterAdd({...pendingPayFilterAdd,class:e.target.value})}
>
<option className='default-select' value="" selected>class</option>
{PayListCourseData?.map((option,index)=>{
        return(
            <option key={index} value={option.class}>{option.class}</option>
     )
    })}
</select>

<select name="" id="" className='selectbox'
value={pendingPayFilterAdd.division}
onChange={(e)=>setPendingPayFilterAdd({...pendingPayFilterAdd,division:e.target.value})}
>
<option className='default-select' value="" >Division</option>
{FiltSelectedClass[0]?.division?.map((divdata,index)=>{
        return(
            <option key={index} value={divdata}>{divdata}</option>
         )
    })} 
</select>
 
        </div>
       
    

        <div >
            <p className='PayFilterPopup-status'>{pendingFilterStatus}</p>

            <div className="PayFilterPopup-btns-container">

            <div className="PayFilterPopup-cancelBtn" onClick={handleFilterCancel}>
                Cancel
            </div>

            <button onClick={pendingPaymentList}
           
              className="PayFilterPopup-chooseBtn btn-outline" >
                Filter
            </button>
            </div>

        </div>

    </div>
    
</div>
  )
}

export default PayFilterPopup