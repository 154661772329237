export const LoginFunction =()=>{ return "/academy/login"}
export const ResetPassword =()=>{return "/academy/reset_password"}
export const TodayFeePayment =()=>{return "/academy/fees/today_feepayment"}
export const DashboardCounts =()=>{return "/academy/student/Students_Count"}
export const OngoingClassCard =()=>{return "/academy/timetable/Ongoing_Class"}
export const ClasswiseattendanceCard =()=>{return "/academy/classwise_attendance"}
export const AttendanceTodayList =()=>{return "/academy/Punching_data_list"}
export const AllStudents =()=>{return "/academy/student/all_stud"}
export const AllTeachers =()=>{return "/academy/profiles/teachers_list"}
export const RecievedPayments =()=>{return "/academy/fees/Received_Payments"}
export const PendingPayments = ()=>{return"/academy/fees/Pending_Payments"}
export const AdmnoStudFilter =()=>{return"/academy/fees/adm_no_fees"}
export const AddFees =()=>{return"/academy/fees/add_fees"}
export const CourseList=()=>{return"/academy/view_course"}
export const AddStudent=()=>{return"/academy/student/Add_Stud"}
export const StudprofileUpdtRoute=()=>{return"/academy/student/Updt_Stud"}
export const StudFilter=()=>{return"/academy/student/student_filter"}
export const TimetableSlotsadd =()=>{return "/academy/timetable/add_workingdays"}
export const ViewDays=()=>{return"/academy/timetable/view_days"}
export const Customnotification=()=>{return"/academy/add_custom_notification"}
export const AssignTemporaryCard =()=>{return"/academy/assign_temp_iD_card"}
export const OutstandingFeeApi =()=>{return"/academy/fees/outstanding_fees"}
export const ViewNoticeBoard =()=>{return"/academy/view_noticeboard"}
export const viewNotice=()=>{return"/academy/view_notice"}
export const IndividualStudentAttendance = ()=>{return"/academy/student_attendance"}
export const AddTimeSlotRoute =()=>{return "/academy/timetable/add_timeslot"}
export const ViewTimeSlot =()=>{return "/academy/timetable/view_timeslots"}
export const ViewTemporaryIdList =()=>{return "/academy/tempid_card_list"}
export const AcademicsClassList =()=>{return"/academy/academics/course_student_list"}
export const AddSubject=()=>{return"/academy/add_subjects"}
export const studprofileAttendPercent=()=>{return"/academy/student/student_attendance_count"}
export const AcademyView=()=>{return"/academy/view_academy"}
export const AcademyViewImage=()=>{return"/academy/get_academy_image"}
export const AcademyUpdate=()=>{return"/academy/update_academy"}
export const AddCourseRoute=()=>{return"/academy/add_course"}
export const SubjectList=()=>{return"/academy/subjects_list"}
export const AddTeacherRoute=()=>{return"/academy/teachers/add_teacher"}
export const AcademyImgUploadRoute=()=>{return"/academy/academy_image_upload"}
export const FileUploadRoute=()=>{return"/academy/student/file_upload"}
export const StudPaymentsHistoryRoute=()=>{return"/academy/Stud_Fees_Data"}
export const TimetableSubjectTeacherView=()=>{return"/academy/timetable/view_subject_teacher_list"}
export const TeacherSubjectsAssignRoute =()=>{return"/academy/assign_course"}
export const AddPeriodRoute=()=>{return"/academy/timetable/add_timetable"}
export const TeacherProfileViewRoute=()=>{return"/academy/teachers/profile_view"}
export const StudentProfileViewRoute=()=>{return"/academy/student/profile_view"}
export const TeacherProfileUpdateRoute=()=>{return"/academy/teachers/profile_update"}
export const StudRegisterRoute=()=>{return"/academy/studentweb/register"}
export const StudRegisterform1=()=>{return"/academy/studentweb/validate"}
export const AcademyListViewRoute=()=>{return"/academy/studentweb/academy_details"}
export const StudentLoginRoute=()=>{return"/academy/studentweb/login"}
export const studLoginProfileRoute=()=>{return"/academy/studentweb/profile"}
export const DocsViewRoute=()=>{return"/academy/student/student_documents"}
export const Docpreview=()=>{return"/academy/student/student_document_view?Image="}
export const DocFileOnlyUploadRoute=()=>{return"/academy/student/stud_file_upload"}
export const AttendanceDateFilterRoute=()=>{return"/academy/punching_data_class_list"}
export const AttendanceMonthFilterRoute=()=>{return"/academy/monthwise_attendance"}
export const StudLoginNoticeViewRoute=()=>{return"/academy/studentweb/view_notice_board"}
export const StudLoginDashAttendanceRoute=()=>{return"/academy/studentweb/today_attendance"}
export const StudLoginOngoingClassRoute=()=>{return"/academy/studentweb/ongoing_class"}
export const AcademyStudentImgUploadRoute=()=>{return"/academy/student/student_image_upload"}
export const TeacherImgUploadRoute=()=>{return"/academy/teachers/teacher_image_upload"}
export const ImageViewRoute=()=>{return"/academy/image_view?Image="}
export const TempidUsageHistoryRoute=()=>{return"/academy/stud_tempid_attendance"}
export const individualNotifyRoute=()=>{return"/academy/student/notify"}
export const markAttendanceRoute=()=>{return"/academy/mark_attendance"}
export const ViewTimetable = ()=>{return "/academy/timetable/Timetable_view"}
export const studwebOutstandingFeesRoute=()=>{return"/academy/studentweb/outstanding_fees"}
export const studwebAttendanceHistoryRoute=()=>{return"/academy/studentweb/attendance_history"}
export const studwebPayHistoryRoute=()=>{return"/academy/studentweb/student_payment_history"}
export const BargraphDatas =()=>{return "/academy/fees/fees_graph"}
export const AbsentFullListRoute=()=>{return"/academy/student/absent_details"}
export const StudwebDocListRoute=()=>{return"/academy/studentweb/student_documents"}
export const StudWebTimetable =()=>{return "/academy/studentweb/view_timetable"}
export const SendMailForVerification =()=>{return "/admin/mail_sending"}
export const SentVerificationCode =()=>{return"/admin/otp_verification"}
export const ForgotChangePassword =()=>{return "/admin/change_password"}
export const ViewDivision =()=>{return "/academy/view_course_division"}
export const ProPayment =()=>{return "/academy/student/student_payment_history"}
export const studwebNoticeContentView=()=>{return"/academy/studentweb/view_notice"}
export const studwebTimeslots=()=>{return"/academy/studentweb/view_stud_timeslots"}
export const studwebViewDays=()=>{return"/academy/studentweb/view_stud_days"}