import React, { useEffect, useState } from "react";
import "./ProfileBar.css";
import dp from "./assets/dp.jpg";
import changeimg from "./assets/changeimg.svg";
import SaveCancelBtn from "../SaveBtn/SaveBtn";
import OutStandingFee from "../OutStandingFee/OutStandingFee";
import doc from "./assets/doc.svg";
import signout from "./assets/signout.svg";
import { useNavigate } from "react-router-dom";
import apiCall, { baseUrl } from "../../Services/apiCall";
import {
  CourseList,
  ImageViewRoute,
  ViewDivision,
} from "../../Services/apiServices";

const ProfileBar = ({
  type,
  setDocumentPopup,
  setLogoutPopup,
  userid,
  setAcademyDetails,
  academyDetails,
  updateAcademySubmit,
  setAcademyImgChangePopup,
  setteacherDetails,
  teacherDetails,
  setTeacherImgChangePopup,
  studProfileData,
  teacherProfileUpdate,
  studProfileUpdtSubmit,
  setStudProfileData,
  studLoginProfileInfo,
  setStudLoginProfileInfo,
  setStudLogoutPopup,
  setChangeStudImgPop,
  setStudwebChangeDpPopup,
  setStudDocpopup,
}) => {
  let navigate = useNavigate();

  const [coursesProfile, setCoursesProfile] = useState([]);

  const courseListProfile = async () => {
    const route = CourseList();
    const resp = await apiCall(route, {});
    if (resp.Status === "success") {
      setCoursesProfile(resp.Course);
    } else if (resp.status === "no_data") {
      setCoursesProfile([]);
    } else if (resp.status === "token_is_invalid") {
      navigate("/login");
    }
  };

  // const ProfileSelectedClass =  coursesProfile?.filter((data)=>data.class===studProfileData.class);
  const [div, setDiv] = useState([]);
  const handleDivision = async (data) => {
    const route = ViewDivision();
    const resp = await apiCall(route, { Class: data ? data : "" });
    if (resp.Status === "success") {
      setDiv(resp?.Division);
      setDivStatus(false)
    }
  };

  useEffect(() => {
    courseListProfile();
  }, []);
  const [divStatus, setDivStatus] = useState(false);

  return (
    <>
      {type === "student" ? (
        <div className="ProfileBar-container">
          <div
            className="ProfileBar-documents"
            onClick={() => setDocumentPopup(true)}
          >
            <img src={doc} alt="" />
            <p>Documents</p>
          </div>

          <div className="ProfileBar-top">
            <div className="ProfileBar-head">
              <p>{studProfileData.name}</p>
              <p className="ProfileBar-id">
                ID :{" "}
                {studProfileData.admno?.replace(
                  localStorage.getItem("adminKey"),
                  ""
                )}
              </p>
            </div>

            <div className="ProfileBar-dp-box">
              <img
                //  src={dp}
                src={baseUrl + ImageViewRoute() + studProfileData.image}
                alt="unavailable"
                className="ProfileBar-dp"
              />

              <p
                className="ProfileBar-changedp"
                onClick={() => setChangeStudImgPop(true)}
              >
                <img src={changeimg} alt="" />
                <p>change profile photo</p>
              </p>
            </div>

            <div className="ProfileBar-title">Student Details</div>
          </div>

          <div className="ProfileBar-middle">
            <div className="ProfileBar-inputs-container">
              <div className="ProfileBar-input">
                <p>Name : </p>
                <input
                  type="text"
                  value={studProfileData.name}
                  onChange={(e) =>
                    setStudProfileData({
                      ...studProfileData,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="ProfileBar-input">
                <p>Class : </p>
                <select
                  name=""
                  id=""
                  value={studProfileData.class}
                  onChange={(e) => {
                    setStudProfileData({
                      ...studProfileData,
                      class: e.target.value,
                    });
                    handleDivision(e.target.value);
                  }}
                >
                  {coursesProfile?.map((option, index) => {
                    return (
                      <option key={index} value={option.class}>
                        {option.class}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="ProfileBar-input">
                <p>Division : </p>
             { !divStatus &&  <input
                  name=""
                  id=""
                  value={studProfileData?.division}
                  onFocus={() => {
               setDivStatus(true);
                  }}
                />}
            { divStatus &&   <select
                  name=""
                  id=""
                  value={studProfileData?.division}
                  onChange={(e) =>
                    setStudProfileData({
                      ...studProfileData,
                      division: e.target.value,
                    })
                  }
                >
                  {div?.map((divdata, index) => {
                    return (
                      <option key={index} value={divdata?.division}>
                        {divdata?.division}
                      </option>
                    );
                  })}
                </select>}
              </div>

              <div className="ProfileBar-input">
                <p>Email : </p>
                <input type="email" value={studProfileData.email} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Guardian Phone : </p>
                <input
                  type="text"
                  value={studProfileData.guardianphone}
                  onChange={(e) =>
                    setStudProfileData({
                      ...studProfileData,
                      guardianphone: e.target.value,
                    })
                  }
                />
              </div>

              <div className="ProfileBar-input">
                <p>Student Phone : </p>
                <input
                  type="text"
                  value={studProfileData.studentPhone}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Date of birth : </p>
                <input
                  type="text"
                  value={studProfileData.dateofbirth}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Blood group : </p>
                <input
                  type="text"
                  value={studProfileData.bloodgroup}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Guardian : </p>
                <input type="text" value={studProfileData.guardian} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Father : </p>
                <input type="text" value={studProfileData.father} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Mother : </p>
                <input type="text" value={studProfileData.mother} readOnly />
              </div>

              <div className="ProfileBar-input-address">
                <p>Address : </p>
                <textarea
                  value={studProfileData.address}
                  name=""
                  id=""
                  onChange={(e) =>
                    setStudProfileData({
                      ...studProfileData,
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              <div className="ProfileBar-input">
                <p>Referred By : </p>
                <input
                  className="pointer-none"
                  type="text"
                  value={studProfileData.reference}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="ProfileBar-bottom">
            <SaveCancelBtn type="cancel" section="profile" />
            <div className="btn-outline" onClick={studProfileUpdtSubmit}>
              {" "}
              <SaveCancelBtn type="save" section="profile" />
            </div>
          </div>

          <div className="outstanding-box">
            <OutStandingFee Admissionnumber={userid} />
          </div>
        </div>
      ) : type === "academy" ? (
        <div className="ProfileBar-container profile-width">
          <div
            className="ProfileBar-documents logout-font"
            onClick={() => setLogoutPopup(true)}
          >
            <img src={signout} alt="" />
            <p>Logout</p>
          </div>

          <div className="ProfileBar-top">
            <div className="ProfileBar-head">
              <p>{academyDetails.academyName}</p>
              {/* <p className="ProfileBar-id">ID : 100234</p> */}
            </div>

            <div className="ProfileBar-dp-box">
              <img
                //  src={dp}
                src={baseUrl + ImageViewRoute() + academyDetails.image}
                alt=""
                className="ProfileBar-dp"
              />
              <p
                className="ProfileBar-changedp"
                onClick={() => setAcademyImgChangePopup(true)}
              >
                <img src={changeimg} alt="" />
                <p>change profile photo</p>
              </p>
            </div>

            <div className="ProfileBar-title">Academy Details</div>
          </div>

          <div className="ProfileBar-middle">
            <div className="ProfileBar-inputs-container">
              <div className="ProfileBar-input">
                <p>Name : </p>
                <input
                  type="text"
                  value={academyDetails.academyName}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Email : </p>
                <input type="email" value={academyDetails.email} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Phone : </p>
                <input
                  type="text"
                  value={academyDetails.phone}
                  onChange={(e) =>
                    setAcademyDetails({
                      ...academyDetails,
                      phone: e.target.value,
                    })
                  }
                />
              </div>

              <div className="ProfileBar-input-address">
                <p>Address : </p>
                <textarea
                  value={academyDetails.address}
                  onChange={(e) =>
                    setAcademyDetails({
                      ...academyDetails,
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="ProfileBar-bottom">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <SaveCancelBtn type="cancel" section="profile" />
            </div>
            <div>
              <button className="btn-outline" onClick={updateAcademySubmit}>
                {" "}
                <SaveCancelBtn type="save" section="profile" />
              </button>
            </div>
          </div>
        </div>
      ) : type === "teacher" ? (
        <form
          onSubmit={teacherProfileUpdate}
          className="ProfileBar-container profile-width"
        >
          {/* <div
            className="ProfileBar-documents logout-font"
            onClick={() => setLogoutPopup(true)}
          >
            <img src={signout} alt="" />
            <p>Logout</p>
          </div> */}

          <div className="ProfileBar-top">
            <div className="ProfileBar-head">
              <p>{teacherDetails.name}</p>
              {/* <p className="ProfileBar-id">ID : 100234</p> */}
            </div>

            <div className="ProfileBar-dp-box">
              <img
                // src={dp}
                src={baseUrl + ImageViewRoute() + teacherDetails.image}
                alt=""
                className="ProfileBar-dp"
              />
              <p
                className="ProfileBar-changedp"
                onClick={() => setTeacherImgChangePopup(true)}
              >
                <img src={changeimg} alt="" />
                <p>change profile photo</p>
              </p>
            </div>

            <div className="ProfileBar-title">Teacher Details</div>
          </div>

          <div className="ProfileBar-middle">
            <div className="ProfileBar-inputs-container">
              <div className="ProfileBar-input">
                <p>Name : </p>
                <input
                  type="text"
                  value={teacherDetails.name}
                  onChange={(e) =>
                    setteacherDetails({
                      ...teacherDetails,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="ProfileBar-input">
                <p>Subject : </p>
                <input type="text" value={teacherDetails.subject} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Email : </p>
                <input type="email" value={teacherDetails.email} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Phone : </p>
                <input
                  type="text"
                  value={teacherDetails.phone}
                  onChange={(e) =>
                    setteacherDetails({
                      ...teacherDetails,
                      phone: e.target.value,
                    })
                  }
                />
              </div>

              <div className="ProfileBar-input-address">
                <p>Address : </p>
                <textarea
                  value={teacherDetails.address}
                  onChange={(e) =>
                    setteacherDetails({
                      ...teacherDetails,
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="ProfileBar-bottom">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <SaveCancelBtn type="cancel" section="profile" />
            </div>
            <div>
              <button type="submit" className="btn-outline">
                {" "}
                <SaveCancelBtn type="save" section="profile" />
              </button>
            </div>
          </div>
        </form>
      ) : type === "studentLogin" ? (
        <div className="ProfileBar-container">
          <div
            className="ProfileBar-documents"
            onClick={() => setStudDocpopup(true)}
          >
            <img src={doc} alt="" />
            <p>Documents</p>
          </div>

          <div className="ProfileBar-top">
            <div className="ProfileBar-head">
              <p>{studLoginProfileInfo?.name}</p>
              <p className="ProfileBar-id">
                ID :{" "}
                {studLoginProfileInfo.admno?.replace(
                  localStorage.getItem("StudKey"),
                  ""
                )}
              </p>
            </div>

            <div className="ProfileBar-dp-box">
              <img
                src={baseUrl + ImageViewRoute() + studLoginProfileInfo?.image}
                alt=""
                className="ProfileBar-dp"
              />
              <p
                className="ProfileBar-changedp"
                onClick={() => setStudwebChangeDpPopup(true)}
              >
                <p>change profile photo</p>
              </p>
            </div>

            <div className="ProfileBar-title studlogin-profilebox">
              <div>Student Details</div>
              <div
                className="studlogin-logout"
                onClick={() => setStudLogoutPopup(true)}
              >
                <img src={signout} alt="logout" />
                <p>Logout</p>
              </div>
            </div>
          </div>

          <div className="ProfileBar-middle">
            <div className="ProfileBar-inputs-container">
              <div className="ProfileBar-input">
                <p>Name : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.name}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Class : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.class}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Division : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.division}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Email : </p>
                <input
                  type="email"
                  value={studLoginProfileInfo?.email}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Guardian Phone : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.parentphone}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Student Phone : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.studentphone}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Date of birth : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.dateofbirth}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Blood group : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.bloodgroup}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Guardian : </p>
                <input
                  type="text"
                  value={studLoginProfileInfo?.guardian}
                  readOnly
                />
              </div>

              <div className="ProfileBar-input">
                <p>Father : </p>
                <input type="text" value={studProfileData?.father} readOnly />
              </div>

              <div className="ProfileBar-input">
                <p>Mother : </p>
                <input type="text" value={studProfileData?.mother} readOnly />
              </div>

              <div className="ProfileBar-input-address">
                <p>Address : </p>
                <textarea
                  value={studLoginProfileInfo?.address}
                  name=""
                  id=""
                  readOnly
                ></textarea>
              </div>
            </div>
          </div>

          {/* <div className="ProfileBar-bottom">
            <SaveCancelBtn type="cancel" section="profile" />
           <div className="btn-outline"
            > <SaveCancelBtn type="save" section="profile" /></div>
          </div> */}

          <div className="outstanding-box">
            <OutStandingFee type="studentWeb" />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileBar;
