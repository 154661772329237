import React from "react";
import "./TodayPaymentsTable.css";

const TodayPaymentsTable = ({ todayFeeResponse }) => {

  let keys = localStorage.getItem("adminKey");

  
  return (
    <div className="todayPayTable-container">
      <div className="todayPayTable-wrapper">
        <div className="todayPayTable-head">Admn. ID</div>
        <div className="todayPayTable-head">Name</div>
        <div className="todayPayTable-head">Class</div>
        <div className="todayPayTable-head">Reciept No.</div>
        <div className="todayPayTable-head">Purpose</div>
        <div className="todayPayTable-head">Amount</div>
      </div>

      <div className="todayPayTable-scroll-container">
        {todayFeeResponse.Data?.map((fees, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
              }}
              className="todayPayTable-content-wrapper"
            >
              <div className="todayPayTable-content">{fees.AdmNo.replace(keys,"")}</div>
              <div className="todayPayTable-content">{fees.Name}</div>
              <div className="todayPayTable-content">
                {fees.Division ? fees.Class + "-" + fees.Division : fees.Class}
              </div>
              <div className="todayPayTable-content">{fees.ReceiptNo}</div>
              <div className="todayPayTable-content">{fees.TypeOffees}</div>
              <div className="todayPayTable-content">₹ {fees.Amount}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TodayPaymentsTable;
