import React from 'react'
import "./ListCardAttendance.css"
import ClassDisplayBox from '../ClassDisplayBox/ClassDisplayBox'

const ListCardAttendance = ({type,data}) => {
  const adminkey = localStorage.getItem("adminKey");

  return (

        <div className="ListCardAttend-container">
            <div className="ListCardAttend-left">
                <div className="ListCardAttend-leftTop">
                    <p className='ListCardAttend-name'>{data?.Name}</p>
                    <p className='ListCardAttend-id'>ID :{data?.AdmNo.replace(adminkey, '')}</p>

                </div>
                <div className="ListCardAttend-leftBtm">
                  <ClassDisplayBox std={data.Class} division={data.Division}/>
                </div>
            </div>



            <div className="ListCardAttend-right">
               
                    <div className='ListCardAttend-time'>
                       <p className='ListCardAttend-timeLabel'>Check-in:</p>
                       <p>{data?.Checkin}</p>
                    </div>
                    <div className='ListCardAttend-time'>
                       <p className='ListCardAttend-timeLabel'>Check-out:</p>
                       <p>{data?.Checkout}</p>
                    </div>
               

            </div>

        </div>
        

  )
}

export default ListCardAttendance