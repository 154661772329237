import React from "react";
import "./DonutChartComponent.css";
import DonutChart from "react-donut-chart";

const DonutChartComponent = ({ presentAbsent }) => {
  return (
    <div className="donut-container">
      <div className="donut-heading">Previous Month Attendance Report</div>

      <div className="chart-legend-container">
        <div className="donut-chart">
          <label className="donut-month" htmlFor=""></label>
          <DonutChart
            colors={["#70B7EB", "#0DFFC2"]}
            innerRadius={0.5}
            height={130}
            width={130}
            legend={false}
            clickToggle={false}
            interactive={false}
            strokeColor="none"
            data={[
              {
                label: "Advent",
                value: presentAbsent?.Present,
                colors: "#70B7EB",
              },
              {
                label: "Holidays",
                value: presentAbsent?.Absent,
              },
            ]}
          />
        </div>

        <div className="donut-legend-container">
          <div className="donut-legend">
            <div className="donut-lcolor"></div>
            <div className="donut-text">Present</div>
            {presentAbsent.Present ? (
              <div className="donut-percent">{presentAbsent?.Present}%</div>
            ) : (
              "0"
            )}
          </div>

          <div className="donut-legend">
            <div className="donut-lcolor2"></div>
            <div className="donut-text">Absent</div>

            {presentAbsent.Absent ? (
              <div className="donut-percent">{presentAbsent?.Absent}%</div>
            ) : (
              "0"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutChartComponent;
