import React from "react";
import HeaderSection from "../../components/HeaderSection/HeaderSection";
import "./DashboardPage.css";
import { useState, useEffect } from "react";
import "./DashboardResponsive.css";
import NoticeComponent from "../../components/NoticeComponent/NoticeComponent";
import PieChartComponent from "../../components/PieChartComponent/PieChartComponent";
import TodayPaymentsComponent from "../../components/TodayPaymentsComponent/TodayPaymentsComponent";
import CustomNotification from "../../components/CustomNotification/CustomNotification";
import PaymentPopup from "../../components/PaymentPopup/PaymentPopup";
import "./DashTabResponsive.css";
import AttendanceCard from "../../components/AttendanceCard/AttendanceCard";
import StudentCard from "../../components/StudentCard/StudentCard";
import TabProfileNavbar from "../../components/TabProfileNavbar/TabProfileNavbar";
import apiCall from "../../Services/apiCall";
import { useNavigate } from "react-router-dom";
import {
  BargraphDatas,
  ClasswiseattendanceCard,
  DashboardCounts,
  OngoingClassCard,
  viewNotice,
} from "../../Services/apiServices";
import PaySuccessPopup from "../../components/PaySuccessPopup/PaySuccessPopup";
import SuccessFailPopup from "../../components/SuccessFailPopup/SuccessFailPopup";
import ViewNoticePopup from "../../components/ViewNoticePopup/ViewNoticePopup";
import { bardata,baroption } from "../../Arraydata";

//chart

import { Bar } from "react-chartjs-2";
import { BarElement,  CategoryScale,Chart as ChartJS,Legend, LinearScale,Title, Tooltip } from "chart.js";


ChartJS.register(CategoryScale, LinearScale, BarElement,Title,Tooltip,Legend);

const DashboardPage = ({ section, setSection }) => {
  const [feepopup, setFeepopup] = useState(false);
  const [todayFeeFilter, settodayFeeFilter] = useState(false);
  const [dashboardcontents, setDashboardcontents] = useState({});
  const [ongoingClassData, setongoingClassData] = useState([]);
  const [classwiseattendance, setClasswiseattendance] = useState([]);
  const [paySuccessPopup, setPaySuccessPopup] = useState(false);
  const [notifyStatus, setnotifyStatus] = useState(false);
  const [noticePopup, setNoticePopup] = useState(false);
  const [noticeId, setNoticeId] = useState("");
  const [noticeData, setNoticeData] = useState([]);
  const navigate = useNavigate();
  const [bargraphData,setBargraphData] = useState([])

  const DashboardContents = async () => {
    const routedashcount = DashboardCounts();
    const response = await apiCall(routedashcount, {});
    if (response.Status === "success") {
      setDashboardcontents(response);
    } else if (response.Status === "No_Data") {
      setDashboardcontents(response);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const OngoingClass = async () => {
    const route = OngoingClassCard();
    const response = await apiCall(route, {});
    if (response.Status === "success") {
      setongoingClassData(response?.Data);
    } else if (response.Status === "No_Data") {
      setongoingClassData([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const ClasswiseAttendance = async () => {
    const route = ClasswiseattendanceCard();
    const response = await apiCall(route, {});
    if (response.Status === "success") {
      setClasswiseattendance(response?.Data);
    } else if (response.Status === "No_Data") {
      setClasswiseattendance([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  };

  const viewNoticeSubmit = async () => {
    const route = viewNotice();
    const resp = await apiCall(route, {
      Id: noticeId ? noticeId : "",
    });
    if (resp.Status === "success") {
      setNoticeData(resp?.Data);
    } else if (resp.Status === "no_data") {
      setNoticeData([]);
    } else if (resp.Status === "token_is_invalid") {
      navigate("/login");
    }
  };


  const BargraphData =async()=>{
    const route = BargraphDatas()
    const response = await apiCall(route,{})
    if(response.Status === 'success'){
      setBargraphData(response)
    }else if (response.Status === "no_data") {
      setNoticeData([]);
    } else if (response.Status === "token_is_invalid") {
      navigate("/login");
    }
  }


   const baroption = {
    responsive: true,
   
  
    plugins: {
      title: {
        display: true,
        text: "Students Fee Report",
        color: '#282F41',

        font: {
          size: 17, // Set the font size of the title text
          family: "montserrat-regular",
        },
      },
      legend: {
        display: false, 
      }
    },

    scales: {
      y: {
        display: false, // To hide the y-axis
      },
      x: {
        grid: {
          display: false, // To hide the horizontal lines
        },
      },
    },
  };
  
   const bardata = {
    labels:["Paid " + Math.round(bargraphData?.   Paid)+' % ', "Not Paid "+ Math.round(bargraphData?.NotPaid)+' % '],
    datasets: [
      {
        label: "Fees Paid",
        data: [ Math.round(bargraphData?.Paid) ],
        backgroundColor: "#02E1A8",
      },
      {
        label:'Not Paid',
        data:[null,Math.round(bargraphData?.NotPaid)],
        backgroundColor:'#A6B8FA'
      },
  
    ],
  
  };
  

  useEffect(() => {
    DashboardContents();
    OngoingClass();
    ClasswiseAttendance();
    BargraphData()
  
  }, []);

  useEffect(() => {
    viewNoticeSubmit();
  }, [noticeId]);

  return (
    <div className="dashboard-container">
      {paySuccessPopup ? (
        <div className="feepopup-div">
          <PaySuccessPopup setPaySuccessPopup={setPaySuccessPopup} />
        </div>
      ) : (
        ""
      )}

      {noticePopup && noticeData ? (
        <div className="feepopup-div">
        <ViewNoticePopup
            noticeData={noticeData}
            setNoticeData={setNoticeData}
            setNoticePopup={setNoticePopup}
          />
        </div>
      ) : (
        ""
      )}

      {notifyStatus ? (
        <div className="feepopup-div">
          <SuccessFailPopup
            type="success"
            text="Notification send"
            setclosePopup={setnotifyStatus}
          />
        </div>
      ) : (
        ""
      )}

      {feepopup ? (
        <div className="feepopup-div">
          <PaymentPopup
            setPaySuccessPopup={setPaySuccessPopup}
            feepopup={feepopup}
            setFeepopup={setFeepopup}
          />
        </div>
      ) : (
        ""
      )}

      <div className="TabProfileNavbar-div">
        <TabProfileNavbar section={section} />
      </div>

      <HeaderSection
        section={section}
        setSection={setSection}
        dashboardcontents={dashboardcontents}
        ongoingclass={ongoingClassData}
        classwiseattendance={classwiseattendance}
      />

      {section === "dashboard" ? (
        <>
          <div className="tab-studCard">
            <StudentCard dashValues={dashboardcontents?.Total} />
          </div>

          <div className="dash-middle">
            <div className="piechart-wrapper">
              <PieChartComponent {...dashboardcontents} />
            </div>
            <div className="bargraph">
            <Bar options={baroption} data={bardata}  height={300} />
            
            </div>
         
            <div className="notice-wrapper">
              <NoticeComponent
                type="academy"
                setNoticeId={setNoticeId}
                notifyStatus={notifyStatus}
                setNoticePopup={setNoticePopup}
              />
            </div>
          </div>

          <div className="tab-middle">
            <div className="drop-cards-container">
              <AttendanceCard
                title="TODAY'S ATTENDANCE"
                datas="attendance"
                heading="Strength"
                carddata={classwiseattendance}
              />
              <AttendanceCard
                title="ONGOING CLASSES"
                datas="ongoing"
                heading="Subject"
                carddata={ongoingClassData}
              />
            </div>

            <div className="tab-pienotify-container">
              <PieChartComponent {...dashboardcontents} />
              <CustomNotification
              setnotifyStatus={setnotifyStatus}
              notifyStatus={notifyStatus} 
              />
            </div>

            <div className="tab-noticeboard">
              <NoticeComponent 
               setNoticeId={setNoticeId}
               notifyStatus={notifyStatus}
               setNoticePopup={setNoticePopup}
              type="academy" />
            </div>
          </div>

          <div className="dash-btm">
            <TodayPaymentsComponent
              setFeepopup={setFeepopup}
              settodayFeeFilter={settodayFeeFilter}
              todayFeeFilter={todayFeeFilter}
              feepopup={feepopup}
            />

            <CustomNotification
              setnotifyStatus={setnotifyStatus}
              notifyStatus={notifyStatus}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardPage;
