import React from "react";
import "./AddSubjectPopup.css";
import SaveCancelBtn from "../SaveBtn/SaveBtn";
import InputComponent from "../InputComponent/InputComponent";
import { useState } from "react";
import apiCall from "../../Services/apiCall";
import { AddSubject } from "../../Services/apiServices";
import { useNavigate } from "react-router-dom";

const AddSubjectPopup = ({setaddSubjectPopup,setSubjectAdded}) => {
  const navigate = useNavigate();

  const [subject, setSubject] = useState("");
  const [subjectAddStatus, setSubjectAddStatus] = useState("");

  const addSubjectSubmit = async (e) => {
    e.preventDefault();
    const route = AddSubject();
    const resp = await apiCall(route, {
      SubjectName: subject ? subject : "",
    });
    if (resp.Status === "success") {
        setaddSubjectPopup(false)
        setSubjectAdded(true)
    } else if (resp.Status === "subject_already_added") {
      setSubjectAddStatus("Subject Already Added !");
    } else if (resp.status === "token_is_invalid") {
      navigate("/login");
    }
  };

  return (
    <div className="AddSubject-wrapper">
      <form className="AddSubjectPopup-container" onSubmit={addSubjectSubmit}>
        <div className="AddSubject-title">Add Subject</div>

        <div>
          <InputComponent
            type="text"
            isRequired={true}
            onChange={(e) => {setSubject(e.target.value);setSubjectAddStatus("")}}
            placeHolder="Enter Subject Name"
            value={subject}
          />
          <p className="AddSubject-status">
            {subjectAddStatus ? subjectAddStatus : ""}
          </p>
        </div>

        <div className="AddSubject-btns">
          <div onClick={()=>setaddSubjectPopup(false)}><SaveCancelBtn type="cancel" text="Cancel" /></div>
          <button type="submit">
            <SaveCancelBtn type="save" text="Add" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSubjectPopup;
