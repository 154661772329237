import React, { useState } from "react";
import "./style.css";
import { Button } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import apiCall from "../../Services/apiCall";
import { SentVerificationCode } from "../../Services/apiServices";
import { Hourglass } from "react-loader-spinner";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function Otppopup(props) {
  const { setPopup, email,setOtpVerify } = props;
  const [code, setCode] = useState("");
  const [respStatus, setRespStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const SentOtp = async () => {
    const route = SentVerificationCode();
    setLoading(true);
    const response = await apiCall(route, {
      OTP: code ? code : "",
      Email: email ? email : "",
    });

    if (response.status === "otp_verified") {
      setLoading(false);
      setOtpVerify(response.status)
      setRespStatus(response.status);
    } else if (response.status === "otp_expired_after_5_minute") {
      setRespStatus(response.status);
    }
  };
  return (
    <div className="PaySuccess-wrapper">
      <div className="outline-otp">
        <h3>Enter the Verification code </h3>
        <input type="text" onChange={(e) => setCode(e.target.value)} />
        <label htmlFor="" style={{ fontSize: "small", color: "red" }}>
          {respStatus==="otp_verified"?"":respStatus}
        </label>
        <div className="btn-array">
          <Button
            variant="contained"
            onClick={() => SentOtp()}
            color={respStatus==="otp_verified"?"success":"primary"}
            sx={{width:'200px'}}
          >
            {loading && (
              <Hourglass
                visible={true}
                height="20"
                width="20"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["white", "#72a1ed"]}
              />
            ) }
            {(!loading && respStatus==="otp_verified")? "Verified":"Sent Otp"}
        
          </Button>
          <Button
            variant="contained"
            startIcon={respStatus!=="otp_verified"?<DoDisturbAltIcon />:<CheckCircleIcon/>}
            onClick={() => setPopup(false)}
          >
            {respStatus==="otp_verified"?"Ok":"Close"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Otppopup;
